import { Card } from "@justworkshr/milo-core";
import RequestChangesAction from "../../RequestChangesAction/RequestChangesAction";
import { useTranslation } from "react-i18next";
import Row from "../../Row/Row";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import {
  GetEoREmployeeProfileQuery,
  useGetAdminCapabilitiesQuery,
} from "types/generated/operations";
import {
  convertFromCents,
  formatDate,
} from "pages/employer-of-record/employee-profile/utils";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useContext } from "react";
import RoleFragment from "../../EditCompensation/RoleFragment";
import styles from "./ContractDetails.module.css";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { EOR_PROFILE_PATH } from "pages/employer-of-record/routes";
import { useParams, useNavigate } from "react-router-dom";
import CardAction from "../../CardAction/CardAction";

const CONTRACT_DETAILS_LABELS: Record<string, string> = {
  startDate: "Start Date",
  contractTerm: "Contract term",
  title: "Title",
  office: "Office",
  workCountry: "Work country",
  pay: "Pay",
  mxChristmasBonusDays: "Christmas bonus (aguinaldo) days",
  mxHasInfonavitLoan: "Do you have an infonavit loan?",
  mxHasFonacotLoan: "Do you have a fonacot loan?",
  exemptionStatus: "Exemption status",
  memberType: "Justworks service",
};

const HIDDEN_VALUES = ["pay"];

function editableContractDetails(
  {
    employment,
    contract,
    role,
    jobInformation,
  }: NonNullable<GetEoREmployeeProfileQuery["eorEmployeeProfile"]>,
  supportedCountriesContext: React.ContextType<typeof SupportedCountriesContext>
): Record<string, string | undefined> {
  const countryCode = employment?.workCountry;
  const startDate = contract?.startDate;
  const formattedStartDate = formatDate(startDate);
  const pay = convertFromCents(role?.payRate);

  return {
    startDate: formattedStartDate,
    contractTerm: contract?.term || "",
    title: role?.title || undefined,
    office: jobInformation?.office || "",
    workCountry: supportedCountriesContext.getCountryNameByCode(countryCode),
    pay: `${supportedCountriesContext.getCurrencySymbol(
      countryCode
    )}${pay?.toLocaleString()} ${role?.currency?.toUpperCase()} ${
      role?.payBasis === "salary" ? "per year" : "per hour"
    }`,
  };
}

function allContractDetailsValues(
  employeeProfile: NonNullable<
    GetEoREmployeeProfileQuery["eorEmployeeProfile"]
  >,
  supportedCountriesContext: React.ContextType<typeof SupportedCountriesContext>
): Record<string, string | undefined> {
  const countryCode = employeeProfile.employment?.workCountry;

  switch (countryCode) {
    case "MX":
      return {
        ...editableContractDetails(employeeProfile, supportedCountriesContext),
        ...mxContractDetails(employeeProfile),
        ...staticContractDetails,
      };
    default:
      return {
        ...editableContractDetails(employeeProfile, supportedCountriesContext),
        ...staticContractDetails,
      };
  }
}

function mxContractDetails({
  role,
  personalInfo,
}: NonNullable<GetEoREmployeeProfileQuery["eorEmployeeProfile"]>) {
  return {
    mxChristmasBonusDays: role?.mxChristmasBonusDays?.toString(),
    mxHasInfonavitLoan: personalInfo?.mxHasInfonavitLoan ? "Yes" : "No",
    mxHasFonacotLoan: personalInfo?.mxHasFonacotLoan ? "Yes" : "No",
  };
}

const staticContractDetails = {
  exemptionStatus: "Exempt",
  memberType: "Employer of Record (EOR)",
};

function EditCompensationAction({ isAdmin }: { isAdmin?: boolean }) {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getFeatureFlag } = useGetFeatureFlag();

  const isEditingEnabled = getFeatureFlag("release-change-eor-ee-compensation");

  function navigateToEditPage() {
    navigate(`/employer-of-record/${EOR_PROFILE_PATH}/${id}/compensation`);
  }

  if (typeof isEditingEnabled === "boolean" && isEditingEnabled && isAdmin) {
    return (
      <CardAction name={t("Edit")} icon="edit" onClick={navigateToEditPage} />
    );
  } else {
    return <RequestChangesAction />;
  }
}

export default function ContractDetails({
  editInPlace,
}: {
  editInPlace?: boolean;
}) {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const { data: capabilities } = useGetAdminCapabilitiesQuery();
  const supportedCountriesContext = useContext(SupportedCountriesContext);
  const hideFields = editInPlace ? ["pay", "title"] : [];

  const employeeProfile = profileInfo?.eorEmployeeProfile;

  if (!employeeProfile) throw new Error("Profile should never be undefined");

  const subTag: Record<string, string> = {
    pay: `Paid ${profileInfo?.eorEmployeeProfile.role?.payFrequency}`,
  };

  const data = allContractDetailsValues(
    employeeProfile,
    supportedCountriesContext
  );

  const isAdmin =
    capabilities?.authenticatedMember.capabilities.hasAccessToAdminProfileView;

  if (!isAdmin) {
    // Only show these fields to admins.
    delete data.exemptionStatus;
    delete data.memberType;
  }

  const rows = Object.entries(data)
    .filter(([key]) => (hideFields.length ? !hideFields.includes(key) : true))
    .flatMap(([key, value]) => {
      return [
        <Row
          key={key}
          label={CONTRACT_DETAILS_LABELS[key]}
          value={value}
          showHideButton={HIDDEN_VALUES.includes(key)}
          subtag={subTag[key] ?? null}
          capitalizeValue={!HIDDEN_VALUES.includes(key)}
        />,
        key === "workCountry" && editInPlace ? (
          <div className={styles.editableFragmentWrapper}>
            <RoleFragment />
          </div>
        ) : undefined,
      ];
    });

  return (
    <Card
      title={t("Contract Details")}
      actions={
        editInPlace ? undefined : [<EditCompensationAction isAdmin={isAdmin} />]
      }
    >
      {rows}
    </Card>
  );
}
