export type MessageWithLinkType = {
  text: string;
  linkText?: string;
  link?: string;
};

export enum DecoratedMessageWithLinkDisplayType {
  DEFAULT,
  INFOALERT,
}

export type DecoratedMessageWithLinkType = MessageWithLinkType & {
  displayType?: DecoratedMessageWithLinkDisplayType;
};
