import {
  DecoratedMessageWithLinkType,
  DecoratedMessageWithLinkDisplayType,
} from "pages/employer-of-record/components/MessageWithLink/types";
import { FieldConfig } from "../../../SetupFormGenerator";
import { COMPONENTLIST } from "../../../SetupFormGenerator/constants";

export enum ReimbursementType {
  CELLPHONE_AND_INTERNET = "Cell phone & internet",
  PARKING = "Parking",
  TRAVEL = "Travel",
  MEALS_AND_ENTERTAINMENT = "Meals & entertainment",
  MILEAGE = "Mileage",
  REMOTE = "Remote work allowance",
  UNIFORMS = "Uniforms",
  ACCOMMODATIONS = "Accommodations",
  PROFESSIONAL_DEVELOPMENT = "Professional development",
  TOOLS_AND_EQUIPMENT = "Tools and equipment",
}

export const EXPENSETYPES = Object.values(ReimbursementType);
export const ReimbursementTypeMessage: {
  [key: string]: DecoratedMessageWithLinkType;
} = {
  [""]: {
    text: "To learn more about expense reimbursements in Australia you can refer to the Australian Taxation office.",
    link: "https://www.ato.gov.au/individuals-and-families/income-deductions-offsets-and-records/in-detail/occupation-and-industry-specific-guides/r-z/travel-agent-employees-income-and-work-related-deductions/income-and-allowances",
    linkText: "Australian Taxation office.",
    displayType: DecoratedMessageWithLinkDisplayType.DEFAULT,
  },
} as const;

export const EXPENSE_DETAILS_FIELDS: FieldConfig[] = [
  {
    label: "Transaction date",
    name: "transactionDate",
    type: COMPONENTLIST.DATE,
    required: true,
  },
  {
    label: "Distance",
    name: "distance",
    type: COMPONENTLIST.DISTANCEINPUT,
    required: true,
  },
  {
    label: "Receipt",
    name: "receipts",
    type: COMPONENTLIST.FILE,
    required: true,
    message: {
      text: "Include as many files as necessary. We can accept JPEG, PNG, or PDF up to 10 MB each.",
    },
  },
  {
    label: "Additional information",
    name: "additionalInformation",
    type: COMPONENTLIST.TEXTAREA,
    required: true,
    message: {
      text: "Describe the business purpose of the transaction. For meals, list everyone who attended the meal.",
    },
  },
];

export const PAYMENT_SETTINGS_FIELDS: FieldConfig[] = [
  {
    label: "Pay date",
    name: "payPeriodId",
    type: COMPONENTLIST.SELECT,
    options: [{ value: "", label: "" }],
    required: true,
  },
  {
    label: "Internal Notes",
    name: "notes",
    type: COMPONENTLIST.TEXTAREA,
    required: false,
    message: { text: "Only visible to admins" },
  },
];
