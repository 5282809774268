import { ReactElement, useState } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import {
  CF_ADD_CONTRACTOR_LINK,
  CF_ADD_EMPLOYEES_LINK,
} from "pages/international-solutions/constants";
import { useNavigate } from "react-router-dom";
import {
  CONTRACTORS_PATH,
  EMPLOYEES_EOR_PATH,
} from "pages/international-solutions/routes";
import ThankYouDialog from "../Dialog/ThankYouDialog";
import {
  useGetCompanyDisplayQuery,
  useGetIsEmployerOfRecordEnabledQuery,
} from "types/generated/operations";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import {
  sendCustomerIOEvent,
  CUSTOMER_IO_CTA_CLICK_EVENT,
} from "app/customer-io";

const OverviewPriceCard = (): ReactElement => {
  const { divWrapper, smHead, hr, mdHead, mdFont, mdGap } = styles;
  const navigate = useNavigate();
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const { data: eorEnabledData } = useGetIsEmployerOfRecordEnabledQuery();
  const isEmployerOfRecordEnabled =
    eorEnabledData?.authenticatedMember.company.companySetting?.value ===
      "true" && flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD);

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  const handleThankYouDialog = () => {
    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_CTA_CLICK_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: {
          current_url: window.location.href,
          cta_name: "eor_overview_im_interested_button",
          next_url: "thank-you-modal",
        },
      },
      accessToken
    );
    setShowThankYouDialog((prev) => !prev);
  };

  const handleAddContractors = () => {
    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_CTA_CLICK_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: {
          current_url: window.location.href,
          cta_name: "eor_overview_add_contractor_button",
          next_url: CONTRACTORS_PATH,
        },
      },
      accessToken
    );

    navigate(`..${CONTRACTORS_PATH}`);
  };

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="xl"
    >
      <div className={`${divWrapper} ${mdGap}`}>
        <div className={mdHead}>Employer of record (EOR)</div>
        <div className={smHead}>Scale Your Team Internationally</div>
        <div className={divWrapper}>
          {isEmployerOfRecordEnabled ? (
            <Button
              id="eor-add-employees-button"
              color="brand"
              type="button"
              size="md"
              as="a"
              href={CF_ADD_EMPLOYEES_LINK}
            >
              Add employees
            </Button>
          ) : (
            <Button
              data-heap-id="no-eor-im-interested-button"
              color="brand"
              type="button"
              size="md"
              onClick={() => {
                handleThankYouDialog();
              }}
            >
              I'm interested
            </Button>
          )}
          <Button
            color="brand"
            size="md"
            type="button"
            variant="ghost"
            onClick={() => {
              navigate(`..${EMPLOYEES_EOR_PATH}`);
            }}
          >
            Learn More
          </Button>
        </div>

        <hr className={hr}></hr>

        <div className={mdHead}>International Contractors</div>
        <div>
          <div className={smHead}>Cost for International Contractors</div>{" "}
          <div className={mdFont}>$39/paid contractor/month</div>
        </div>
        <div className={divWrapper}>
          <Button
            data-heap-id="add-contractor-button"
            color="brand"
            type="button"
            as="a"
            size="md"
            href={CF_ADD_CONTRACTOR_LINK}
          >
            Add contractors
          </Button>
          <Button
            color="brand"
            size="md"
            type="button"
            variant="ghost"
            onClick={() => {
              handleAddContractors();
            }}
          >
            Learn More
          </Button>
        </div>

        {showThankYouDialog && (
          <ThankYouDialog
            isOpen={showThankYouDialog}
            onClose={handleThankYouDialog}
          />
        )}
      </div>
    </Box>
  );
};

export default OverviewPriceCard;
