import { ReactElement, useContext, useState } from "react";

import styles from "./Bonus.module.css";
import { FieldConfig, SetupFormGenerator } from "../../SetupFormGenerator";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { BonusPayloadType } from "../types";
import useCountryConfigFetcher from "../hooks/useCountryConfigFetcher";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";

export const Bonus = (): ReactElement => {
  const additionalPayContext = useContext(AdditionalPayContext);

  const [paymentSettingsFields, setPaymentSettingsFields] = useState<
    FieldConfig<BonusPayloadType>[]
  >([]);
  const [taxFormFields, setTaxFormFields] = useState<
    FieldConfig<BonusPayloadType>[]
  >([]);

  useCountryConfigFetcher<BonusPayloadType>(
    EmployeePayType.BONUS,
    additionalPayContext,
    setPaymentSettingsFields,
    setTaxFormFields
  );

  return (
    <>
      <div className={styles.card}>
        <SetupFormGenerator<BonusPayloadType>
          title={`${EmployeePayType.BONUS} details`}
          formikFieldName="taxes"
          fields={taxFormFields}
        />
      </div>
      <div className={styles.card}>
        <SetupFormGenerator<BonusPayloadType>
          title="Payment settings"
          fields={paymentSettingsFields}
          formikFieldName="paymentSettings"
        />
      </div>
    </>
  );
};
