import React, { useContext } from "react";
import { Button } from "@justworkshr/milo-core";
import { ConfirmationMessages } from "./ConfirmationMessages";
import styles from "./FormConfirmation.module.css";
import { Form8973Context } from "pages/form-8973/contexts";
import { buildClockworkWebPath } from "lib/resource-finder/resource-finder";

interface FormConfirmationProps {
  deadline?: string;
}

const FormConfirmation: React.FC<FormConfirmationProps> = ({ deadline }) => {
  const { step } = useContext(Form8973Context);

  const message =
    ConfirmationMessages[step as keyof typeof ConfirmationMessages];
  const { formConfirmationContent, title, description, icon } = styles;

  return (
    <div className={formConfirmationContent}>
      <div className={icon}>{message.icon}</div>
      <h1 className={title}>{message.title}</h1>
      <p className={description}>{message.description(deadline)}</p>
      <Button as="a" href={buildClockworkWebPath("/admin/documents")}>
        {message.buttonLabel}
      </Button>
    </div>
  );
};

export default FormConfirmation;
