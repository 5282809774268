import { ReactElement } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useGetAdminCapabilitiesQuery } from "types/generated/operations";
import Documents from "./components/Documents/Documents";
import JobInformation from "./components/JobInformation/JobInformation";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import TimeOff from "./components/TimeOff/TimeOff";
import { SUBPATHS } from "./constants";
import EditProfilePage from "./pages/EditProfilePage";
import { ProfileInfoProvider } from "./contexts/ProfileInfoContext";
import Finances from "./components/Finances/Finances";
import EditStartDatePage from "./pages/EditStartDatePage";
import EditCompensationPage from "./pages/EditCompensationPage";
import styles from "./pages/Profile.module.css";
import { Spinner } from "@justworkshr/milo-core";
import Profile from "./pages/Profile";
import { Page404 } from "pages/page-404";

function ProfileInfoOutlet() {
  return (
    <ProfileInfoProvider>
      <Outlet />
    </ProfileInfoProvider>
  );
}

export default function EmployeeProfileInfo(): ReactElement {
  const { data, loading } = useGetAdminCapabilitiesQuery();
  const capabilities = data?.authenticatedMember?.capabilities;

  if (loading) {
    return (
      <div className={styles.spinner}>
        <Spinner aria-label="loading" />
      </div>
    );
  }

  if (!loading && !capabilities?.hasAccessToAdminProfileView) {
    return <Page404 />;
  }

  return (
    <Routes>
      {capabilities?.hasAccessToAdminProfileView && (
        <Route path="/:id" element={<ProfileInfoOutlet />}>
          {/* The personal info, job info, documents, and finances section share a common layout */}
          <Route path="" element={<Profile />}>
            <Route
              path={`/:id/${SUBPATHS.PERSONAL_INFO}`}
              element={<PersonalInfo editable />}
            />
            <Route
              path={`/:id/${SUBPATHS.JOB_INFORMATION}`}
              element={<JobInformation editable />}
            />
            {capabilities?.hasPermissionToUpdateTimeOff && (
              <Route path={`/:id/${SUBPATHS.TIME_OFF}`} element={<TimeOff />} />
            )}
            <Route
              path={`/:id/${SUBPATHS.DOCUMENTS}`}
              element={<Documents />}
            />
            <Route path={`/:id/${SUBPATHS.FINANCES}`} element={<Finances />} />
            <Route
              path=""
              element={<Navigate to={SUBPATHS.PERSONAL_INFO} replace />}
            />
          </Route>

          <Route path={`/:id/${SUBPATHS.EDIT}`} element={<EditProfilePage />} />
          <Route path="/:id/start-date" element={<EditStartDatePage />} />
          <Route path="/:id/compensation" element={<EditCompensationPage />} />

          {/* If we remove a profile subsection we don't want to 404, we'll redirect back to the most general part of the profile */}
          <Route
            path="/:id/*"
            element={<Navigate to={SUBPATHS.PERSONAL_INFO} replace />}
          />
        </Route>
      )}
    </Routes>
  );
}
