import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import heroImage from "../../images/hero-images/brex-hero.svg";
import logo from "../../images/integration-partner-logos/brex-logo.svg";
import horizontalLogo from "../../images/horizontal-logos/brex-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  justworksPermissionsCopy,
  partnerPermissionsCopy,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import ExternalLinkButton from "../../partner-description-page/components/InstanceDetailsPanel/ExternalLinkButton/ExternalLinkButton";
import React from "react";

export const brexConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: logo,
      integrationName: "Brex",
      integrationDescription:
        "Automatically sync employee information from Justworks to Brex",
      integrationCategory: "Expense management",
      requiredPermissions: HR_PERMISSIONS,
      slug: "merge__brex_v1",
      pdpUrl: "/cf/integrations/merge__brex_v1",
    },

    pdp: {
      headerDescription:
        "Brex offers the world’s smartest corporate cards, modern banking and treasury, and intuitive software for travel and expenses – all on one AI-powered platform.",
      heroImageUrl: heroImage,
      horizontalLogoUrl: horizontalLogo,
      instanceDetailsPanelConfig: {
        hideLastActive: true,
        customDisconnectButton: (
          <ExternalLinkButton
            url="https://dashboard.brex.com/p/team/integrations"
            copy="Disconnect Integration"
            variant="outlined"
          />
        ),
        customSetupButton: (
          <ExternalLinkButton
            url="https://dashboard.brex.com/p/team/integrations"
            copy="Get Started"
          />
        ),
      },
      resourceCard: {
        links: [
          {
            title: "Brex in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/29604183797787-Brex-Integration",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about Brex",
            href: "https://www.brex.com/ ",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "Brex + Justworks",
          subheader:
            "This integration seamlessly connects Justworks and Brex to automate account provisioning, deprovisioning, and employee data management in Brex.",
          list: [
            {
              summary: "Automated account management",
              detail:
                "Automatically create Brex accounts when employees are added to Justworks, and remove Brex accounts when employees are removed from Justworks.",
            },
            {
              summary: "Daily data syncs",
              detail:
                "Employee data from Justworks syncs to Brex daily, so you can effortlessly configure spend in Brex.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What member data will we share with Brex?",
            subheader:
              "While the integration is connected, Brex will automatically pull in the following data from Justworks for your US-based team members:",
            sharedDataGroups: [
              {
                sharedData: [
                  "Email",
                  "First Name",
                  "Last Name",
                  "Title",
                  "Member Type",
                  "Manager",
                  "Employment Status (e.g. Active, Inactive)",
                  "Department",
                  "Location",
                  "Legal Entity",
                ],
              },
            ],
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In Brex",
                detail: partnerPermissionsCopy("Brex", [
                  "Card admin or Account admin or User management admin",
                ]),
              },
            ],
          },
        },
      },
      disconnectModalContent: {
        title: "Disconnect from Brex",
        body: <span></span>,
        disconnectButtonText: "Disconnect",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/sections/360001292171-Account-Administration",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
