import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../../Row/Row";
import RequestChangesAction from "../../../../RequestChangesAction/RequestChangesAction";
import { Card } from "@justworkshr/milo-core";
import BadgeTitle from "../../BadgeTitle";
import { useTranslation } from "react-i18next";

export default function NationalIdentifierCard(): ReactElement {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();

  const personalInfo = profileInfo?.eorEmployeeProfile.personalInfo;
  const idNumber = personalInfo?.brPersonalId;
  const stateOfIssue = personalInfo?.brRgStateOfIssue;
  const issuingBody = personalInfo?.brRgIssuingBody;

  return (
    <Card
      title={
        (
          <BadgeTitle
            title={t("Carteira de identidade Nacional")}
            badgeText={t("Brazil")}
          />
        ) as unknown as string
      }
      actions={[<RequestChangesAction />]}
    >
      <Row label={t("ID Number (RG)")} value={idNumber} />
      <Row label={t("RG state of issue")} value={stateOfIssue} />
      <Row label={t("RG issuing body")} value={issuingBody} />
    </Card>
  );
}
