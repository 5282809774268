import { useContext } from "react";
import { Card } from "@justworkshr/milo-core";
import { FormField, Label, TextInput } from "@justworkshr/milo-form";
import { ContactInfoFormType } from "./types";
import { useFormikContext } from "formik";
import styles from "./ContactInfo.module.css";
import { useTranslation } from "react-i18next";
import { EoRMemberContext } from "../../contexts/eorMemberContext";
import PhoneNumbersFragment from "./PhoneNumbersFragment";

type Props = {
  handleBlur: (event: React.FocusEvent) => void;
  handleChange: (event: React.ChangeEvent) => void;
};

const PhonesAndEmailCard = ({ handleBlur, handleChange }: Props) => {
  const { t } = useTranslation();
  const formikProps = useFormikContext<ContactInfoFormType>();
  const eorMemberContext = useContext(EoRMemberContext);

  const { errors, touched, values } = formikProps;

  return (
    <Card title={t("Phone and email")}>
      <Label>{t("Phone Number")}</Label>
      <PhoneNumbersFragment workCountry={eorMemberContext?.workCountry} />

      <FormField
        name="contactInfo.homeEmail"
        label={t("Home Email")}
        required
        error={
          touched?.contactInfo?.homeEmail ? errors?.contactInfo?.homeEmail : ""
        }
      >
        <div className={styles.formFieldWidth}>
          <TextInput
            name="contactInfo.homeEmail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contactInfo.homeEmail}
          />
        </div>
      </FormField>
    </Card>
  );
};

export default PhonesAndEmailCard;
