import { ReactElement, useState } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import { CF_ADD_EMPLOYEES_LINK } from "pages/international-solutions/constants";
import ThankYouDialog from "../Dialog/ThankYouDialog";
import {
  useGetIsEmployerOfRecordEnabledQuery,
  useGetCompanyDisplayQuery,
} from "types/generated/operations";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import {
  sendCustomerIOEvent,
  CUSTOMER_IO_CTA_CLICK_EVENT,
} from "app/customer-io";

const EORPriceCard = (): ReactElement => {
  const { divWrapper, mdGap, mdHead, mdPadding } = styles;
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const { data: eorEnabledData } = useGetIsEmployerOfRecordEnabledQuery();
  const isEmployerOfRecordEnabled =
    eorEnabledData?.authenticatedMember.company.companySetting?.value ===
      "true" && flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD);

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  const handleThankYouDialog = () => {
    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_CTA_CLICK_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: {
          current_url: window.location.href,
          cta_name: "eor_eor_im_interested_button",
          next_url: "thank-you-modal",
        },
      },
      accessToken
    );

    setShowThankYouDialog((prev) => !prev);
  };

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="md"
    >
      <div className={`${divWrapper} ${mdGap}`}>
        <div className={`${divWrapper} ${mdPadding}`}>
          <div className={mdHead}>Availability </div>
          <div>
            Top Talent Markets include the UK, Canada, Mexico, Portugal,
            Netherlands, and many more.
          </div>
        </div>
        {isEmployerOfRecordEnabled ? (
          <Button
            data-heap-id="eor-add-employees-button"
            color="brand"
            type="button"
            size="md"
            as="a"
            href={CF_ADD_EMPLOYEES_LINK}
          >
            Add employees
          </Button>
        ) : (
          <Button
            data-heap-id="no-eor-im-interested-button"
            color="brand"
            type="button"
            size="md"
            onClick={() => {
              handleThankYouDialog();
            }}
          >
            I'm interested
          </Button>
        )}
      </div>

      {showThankYouDialog && (
        <ThankYouDialog
          isOpen={showThankYouDialog}
          onClose={handleThankYouDialog}
        />
      )}
    </Box>
  );
};

export default EORPriceCard;
