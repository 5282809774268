import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Divider from "pages/life-and-leave/enrollment/components/Divider/Divider";
import ContactPhoneNumbers from "./components/PhoneNumbers";
import EmergencyContact from "./components/EmergencyContact";
import Row from "../../../Row/Row";
import EditAction from "../../../EditAction/EditAction";
import { Card } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";

type Props = {
  editable: boolean;
};

export default function ContactInformation(props: Props): ReactElement {
  const { editable } = props;
  const { profileInfo } = useProfileContextData();
  const { t } = useTranslation();
  const workEmail = profileInfo?.eorEmployeeProfile.employment?.workEmail;
  const personalEmail = profileInfo?.eorEmployeeProfile.personalInfo?.email;

  return (
    <>
      <Card
        title={t("Contact")}
        actions={[<EditAction target="contact" enabled={editable} />]}
      >
        <ContactPhoneNumbers />
        <Divider size="lg" />
        <Row label="Email" capitalizeValue={false}>
          <div>
            <Row
              value={personalEmail}
              required
              tag="personal"
              capitalizeValue={false}
            />
            <Row
              value={workEmail}
              required
              tag="work"
              capitalizeValue={false}
            />
          </div>
        </Row>
        <Divider size="lg" />
        <EmergencyContact />
      </Card>
    </>
  );
}
