import styles from "./CancelEditModal.module.css";
import { Button, Dialog, DialogProps } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";

type CancelEditModalProps = Pick<DialogProps, "isOpen" | "onClose"> & {
  navigateBack: () => void; // New prop for handling navigation
};

export default function CancelEditModal({
  isOpen,
  onClose,
  navigateBack,
}: CancelEditModalProps) {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton
      title={t("Cancel this edit?")}
      size="xl"
      primaryButton={
        <Button onClick={navigateBack} variant="filled" type="submit">
          {t("Cancel edit")}
        </Button>
      }
      secondaryButton={
        <Button variant="ghost" onClick={onClose}>
          {t("Continue editing")}
        </Button>
      }
    >
      <p className={styles.description}>
        {t("Any changes you've made will be discarded.")}
      </p>
    </Dialog>
  );
}
