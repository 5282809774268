import {
  name as originalName,
  localName as originalLocalName,
  abbreviation as originalAbbreviation,
} from "stdnum/src/br/cpf";
import { ValidateReturn } from "stdnum/lib/cjs/types";
import * as exceptions from "stdnum/src/exceptions";
import { strings } from "stdnum/src/util";

function clean(input: string): ReturnType<typeof strings.cleanUnicode> {
  return strings.cleanUnicode(input, " -.");
}

function strip(number: string | number): string {
  const numStr = number.toString();
  return numStr.replace(/[^\d]/g, "");
}

function computeDigit(input: string): number {
  const mlen = input.length + 1;

  const value = input
    .split("")
    .map((v, idx) => parseInt(v, 10) * (mlen - idx))
    .reduce((acc, v) => (acc + v) % 11);

  return value < 2 ? 0 : 11 - value;
}

const brTaxIdValidator = {
  name: originalName,
  localName: originalLocalName,
  abbreviation: originalAbbreviation,

  compact(input: string): string {
    const [value, err] = clean(input);

    if (err) {
      throw err;
    }

    return value;
  },

  format(input: string): string {
    const [value] = clean(input);
    const strippedNumber = strip(value);
    return strippedNumber.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
  },

  validate(input: string): ValidateReturn {
    const [value, error] = clean(input);

    if (error) {
      return { isValid: false, error };
    }
    if (value.length !== 11) {
      return { isValid: false, error: new exceptions.InvalidLength() };
    }
    if (!strings.isdigits(value)) {
      return { isValid: false, error: new exceptions.InvalidFormat() };
    }

    const [front, c1, c2] = strings.splitAt(value, 9, 10);

    const d1 = String(computeDigit(front));
    const d2 = String(computeDigit(value.substr(0, 10)));

    if (d1 !== c1 || d2 !== c2) {
      return { isValid: false, error: new exceptions.InvalidChecksum() };
    }

    return {
      isValid: true,
      compact: value,
      isIndividual: true,
      isCompany: false,
    };
  },
};

export { brTaxIdValidator };
