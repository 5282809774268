import { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./callout.module.css";
import NavBadge from "../nav-badge";
import { NavBadgeTypeEnum } from "types";
import { buildWebPath, buildStaticAssetPath } from "lib/resource-finder";
import chevronImageNavy from "./icons/arrow-right-thick-rounded-navy.svg";
import chevronImageWhite from "./icons/arrow-right-white.svg";
import { INTERNATIONAL_SOLUTIONS_PATH } from "pages/international-solutions";

const {
  callout,
  arrow,
  image,
  noImage,
  highlighted,
  calloutBox,
  largeText,
  smallText,
} = styles;

interface CalloutProps {
  icon?: string;
  children: string;
  path: string;
  outstandingTaskCount?: null | number;
  isBoxCallout: boolean;
  secondaryText: null | string;
}

export default function Callout({
  icon,
  path,
  children,
  outstandingTaskCount,
  isBoxCallout,
  secondaryText,
}: CalloutProps): ReactElement {
  const location = useLocation();
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    const basePath = path.startsWith("/cf/")
      ? path.replace(/^\/cf\//, "")
      : path;
    setIsHighlighted(location.pathname.includes(basePath.replace(/_/g, "-"))); // the path needs to be converted to the format used in clockface before matching against the current location
  }, [location.pathname, path]);

  const isInternationalSolutionsPath = path.includes(
    INTERNATIONAL_SOLUTIONS_PATH
  );
  const clickIndicator = outstandingTaskCount ? (
    <NavBadge
      badgeText={outstandingTaskCount}
      badgeType={NavBadgeTypeEnum.Callout}
    />
  ) : (
    <img
      className={arrow}
      src={isBoxCallout ? chevronImageWhite : chevronImageNavy}
      alt={"arrow right"}
    />
  );

  return (
    <a
      className={`${isHighlighted ? highlighted : ""} ${
        isBoxCallout ? calloutBox : callout
      }`}
      href={buildWebPath(path)}
      data-heap-id={
        isInternationalSolutionsPath
          ? "international-nav-bar-banner"
          : undefined
      }
    >
      {icon ? (
        <img src={buildStaticAssetPath(icon)} alt="" className={image} />
      ) : (
        <></>
      )}
      <h3 className={icon ? "" : noImage}>
        {isBoxCallout ? (
          <>
            <span className={largeText}>{children}</span>
            <br />
            <span className={smallText}>{secondaryText}</span>
          </>
        ) : (
          children
        )}
      </h3>
      {clickIndicator}
    </a>
  );
}
