import styles from "./EditCompensation.module.css";
import { Alert, PageHeader, Step, Stepper } from "@justworkshr/milo-core";
import { Trans, useTranslation } from "react-i18next";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import { useState } from "react";
import {
  CompensationChange,
  CompensationChangeContext,
} from "../../components/EditCompensation/CompensationChangeContext";
import EditStep from "../../components/EditCompensation/EditStep";
import ReviewStep from "../../components/EditCompensation/ReviewStep";
import { getEmployeeDisplayName } from "../../utils";
import BackToProfile from "../EditableProfile/components/BackToProfile";

const STEPS = ["Edit", "Review"];
const STEP_COMPONENTS = {
  1: EditStep,
  2: ReviewStep,
};

function status(currentStep: number, i: number) {
  if (i === currentStep) return "current" as const;
  if (i > currentStep) return "pending" as const;
  if (i < currentStep) return "complete" as const;
}

type ChangeRequest = Partial<{
  title: string;
  payRate: string;
  responsibilities: string;
  effectiveDate: string;
}>;

export type CompensationChangeRequest = {
  memberId: string;
  employmentId: string;
  changeRequest: ChangeRequest;
};

type Props = {
  onSubmit: (params: CompensationChangeRequest) => Promise<void>;
};

export default function EditCompensation(props: Props) {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [values, setValues] = useState<Partial<CompensationChange>>({});

  const memberId = profileInfo?.eorEmployeeProfile.employment?.memberId;
  const employmentId = profileInfo?.eorEmployeeProfile.employment?.id;
  const displayName = getEmployeeDisplayName(
    profileInfo?.eorEmployeeProfile.personalInfo
  );

  function onBack() {
    if (currentStep === 2) {
      setCurrentStep(1);
      window.scrollTo(0, 0);
    }
  }

  function onNext() {
    if (currentStep === 1) {
      setCurrentStep(2);
      window.scrollTo(0, 0);
    } else {
      onSubmit();
    }
  }

  function onCancel() {
    // TODO(damon-g-b): Show the cancellation modal
  }

  async function onSubmit() {
    setErrorMessage(undefined);

    if (!memberId) throw new Error("No member id!!");
    if (!employmentId) throw new Error("No employment id!!");

    const changeRequest: ChangeRequest = Object.fromEntries(
      Object.entries({
        payRate: values.pay?.toString(),
        title: values.jobTitle,
        responsibilities: values.roleResponsibilities,
        effectiveDate: values.effectiveDate,
      }).filter(([, v]) => v)
    );

    if (Object.values(changeRequest).length === 0) {
      throw new Error("Empty change request");
    }

    try {
      await props.onSubmit({
        memberId,
        employmentId,
        changeRequest,
      });
    } catch (e) {
      console.error(e);
      setErrorMessage(t("An unknown error occurred. Please try again later."));
    }
  }

  const StepComponent = STEP_COMPONENTS[currentStep];

  if (!StepComponent) throw new Error("Step index out of bounds");

  const link = (
    <a href="mailto:international-support@justworks.com">
      international-support@justworks.com
    </a>
  );

  return (
    <CompensationChangeContext.Provider
      value={{ currentStep, values, setValues, onBack, onNext, onCancel }}
    >
      <div className={styles.container}>
        {errorMessage && <Alert color="destructive">{errorMessage}</Alert>}
        <PageHeader
          title={`${t("Edit")} ${displayName}'s ${t("contract details")}`}
          linkPrevious={<BackToProfile />}
        >
          <div className={styles.headerChildren}>
            <span>
              {t(
                "This edit needs to be reviewed by our team. Submit a request and we’ll take it from there."
              )}
            </span>

            <div className={styles.stepper}>
              <Stepper orientation="horizontal">
                {STEPS.map((step, i) => (
                  <Step
                    status={status(currentStep, i + 1)}
                    key={step}
                    stepNumber={i + 1}
                    title={
                      (
                        <div className={styles.step}>{step}</div>
                      ) as unknown as string
                    }
                  />
                ))}
              </Stepper>
            </div>

            <div className={styles.titleSubtitle}>
              {currentStep === 1 && (
                <>
                  <div className={styles.title}>
                    {t("What changes do you need to make?")}
                  </div>
                  <div className={styles.subtitle}>
                    <Trans>
                      Only some information can be edited. To make additional
                      changes, please contact us at {link} and include the
                      employee's full name in your message.
                    </Trans>
                  </div>
                </>
              )}
            </div>
          </div>
        </PageHeader>
        <StepComponent />
      </div>
    </CompensationChangeContext.Provider>
  );
}
