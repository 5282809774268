import { permissionGroupDetails } from "pages/advisor/common/constants";
import { PermissionGroup } from "./components";
import styles from "./PermissionGroups.module.css";

const { section } = styles;

const PermissionGroups = () => {
  return (
    <>
      {Object.values(permissionGroupDetails).map((permissionGroupConfig) => {
        return (
          <div key={permissionGroupConfig.id} className={section}>
            <PermissionGroup
              permissions={Object.values(permissionGroupConfig.permissions)}
              name={permissionGroupConfig.name}
            />
          </div>
        );
      })}
    </>
  );
};

export default PermissionGroups;
