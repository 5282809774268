import style from "./ThirdPartyAdminTable.module.css";
import { ThirdPartyTable } from "pages/advisor/common/components";
import { ThirdPartyAdmin } from "types";

const { tableRow } = style;
interface ThirdPartyAdminTableProps {
  members: ThirdPartyAdmin[];
}

const ThirdPartyAdminTable = ({ members }: ThirdPartyAdminTableProps) => {
  return (
    <ThirdPartyTable
      tableKey="third-party-admins"
      tableTitle="Other Third-party admins"
      dataCountLabel={`${members.length} members`}
    >
      <>
        <thead>
          <tr className={tableRow}>
            <td>Name</td>
            <td>Title</td>
            <td>Email</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => (
            <tr key={member.uuid} className={tableRow}>
              <td
                data-heap-redact-text={true}
              >{`${member.lastName}, ${member.firstName}`}</td>
              <td>{member.deskTitle}</td>
              <td data-heap-redact-text={true}>{member.emailWork}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </>
    </ThirdPartyTable>
  );
};

export default ThirdPartyAdminTable;
