import styles from "./Footer.module.css";
import { useContext } from "react";
import { Form8973Context } from "../../contexts";
import { FormBackButton } from "../FormBackButton/FormBackButton";
import { FormNextButton } from "../FormNextButton/FormNextButton";
import { WORKFLOW_STEPS } from "../../constants";
import { getStepNumber } from "../../utils";
const { footer, stepCount } = styles;

export const Footer: React.FC = () => {
  const { step } = useContext(Form8973Context);

  return (
    <footer className={footer} data-testid={footer}>
      <div className={stepCount}>Step {getStepNumber(step)} of 3</div>
      {step !== WORKFLOW_STEPS.STEP_ONE && <FormBackButton />}
      {step !== WORKFLOW_STEPS.SIGN_DOCUMENT && <FormNextButton />}
    </footer>
  );
};
