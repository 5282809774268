import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import heroImage from "../../images/hero-images/vanta-hero.svg";
import logo from "../../images/integration-partner-logos/vanta-logo.svg";
import horizontalLogo from "../../images/horizontal-logos/vanta-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  justworksPermissionsCopy,
  partnerPermissionsCopy,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import ExternalLinkButton from "../../partner-description-page/components/InstanceDetailsPanel/ExternalLinkButton/ExternalLinkButton";
import React from "react";

export const vantaConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: logo,
      integrationName: "Vanta",
      integrationDescription:
        "Automatically sync employee information from Justworks to Vanta",
      integrationCategory: "Compliance",
      requiredPermissions: HR_PERMISSIONS,
      slug: "merge__vanta_v1",
      pdpUrl: "/cf/integrations/merge__vanta_v1",
    },

    pdp: {
      headerDescription:
        "Vanta helps thousands of fast-growing companies simplify and centralize compliance and security workflows so they can build trust.",
      heroImageUrl: heroImage,
      horizontalLogoUrl: horizontalLogo,
      instanceDetailsPanelConfig: {
        hideLastActive: true,
        customDisconnectButton: (
          <ExternalLinkButton
            url="https://app.vanta.com/integrations"
            copy="Disconnect Integration"
            variant="outlined"
          />
        ),
        customSetupButton: (
          <ExternalLinkButton
            url="https://app.vanta.com/integrations"
            copy="Get Started"
          />
        ),
      },
      resourceCard: {
        links: [
          {
            title: "Vanta in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/29603919028891-Vanta-Integration",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about Vanta",
            href: "https://www.vanta.com/",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "Vanta + Justworks",
          subheader:
            "Integrating Justworks and Vanta streamlines HR and compliance management by combining Justworks' comprehensive HR solutions with Vanta's automated security monitoring. This powerful synergy simplifies audits, ensures regulatory compliance, and enhances organizational security, reducing administrative overhead and mitigating compliance risks for businesses.",
          list: [
            {
              summary: "Automated account management",
              detail:
                "Manage employee information in Justworks, and let this integration seamlessly provision and deprovision team members to their Vanta accounts.",
            },
            {
              summary: "Efficient data-syncing",
              detail:
                "Eliminate manual work, saving time, reducing errors, and boosting overall efficiency. Data syncs from Justworks to Vanta every 24 hours, ensuring accurate, up-to-date information.",
            },
            {
              summary: "Efficient onboarding and offboarding",
              detail:
                "This integration enables automated checks and evidence collection.",
            },
            {
              summary: "Compliance monitoring",
              detail: "Automate compliance training assignments.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What member data will we share with Vanta?",
            subheader:
              "While the integration is connected, Vanta will automatically pull in the following data from Justworks for your US-based team members:",
            sharedDataGroups: [
              {
                sharedData: [
                  "First Name",
                  "Last Name",
                  "Preferred Name",
                  "Work Email",
                  "Personal Email",
                  "Hire Date",
                  "Start Date",
                  "End Date",
                  "Employee Number",
                  "Employment Status",
                  "Groups",
                  "Mobile Number",
                  "Manager",
                ],
              },
            ],
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In Vanta",
                detail: partnerPermissionsCopy("Vanta", ["Administrator"]),
              },
            ],
          },
        },
      },
      disconnectModalContent: {
        title: "Disconnect from Vanta",
        body: <span></span>,
        disconnectButtonText: "Disconnect",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/sections/360001292171-Account-Administration",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
