import Row from "../../../Row/Row";
import RequestChangesAction from "../../../RequestChangesAction/RequestChangesAction";
import { Card } from "@justworkshr/milo-core";
import BadgeTitle from "../BadgeTitle";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";

function educationalQualificationKeyFromWorkCountry(workCountry?: string) {
  switch (workCountry) {
    case "ES":
      return "esEducationalQualification";
    case "BR":
      return "brEducationalQualification";
  }
}

export default function EducationInformation() {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const { getCountryNameByCode } = useContext(SupportedCountriesContext);

  const personalInfo = profileInfo?.eorEmployeeProfile.personalInfo;
  const workCountry = profileInfo?.eorEmployeeProfile.employment?.workCountry;

  if (!personalInfo) throw new Error("No personal info");

  const educationalQualificationKey =
    educationalQualificationKeyFromWorkCountry(workCountry ?? undefined);

  if (!educationalQualificationKey) return null;

  const educationalQualification = personalInfo[educationalQualificationKey];

  return (
    <Card
      title={
        (
          <BadgeTitle
            title={t("Education")}
            badgeText={getCountryNameByCode(workCountry)}
          />
        ) as unknown as string
      }
      actions={[<RequestChangesAction />]}
    >
      <Row
        label={t("Educational qualification")}
        value={educationalQualification}
      />
    </Card>
  );
}
