import { Select, SelectOption } from "@justworkshr/milo-form";
import { ChangeEvent, ReactElement, useContext } from "react";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import {
  SupportedCountriesContext,
  SupportedCountriesDataType,
} from "pages/employer-of-record/contexts/supportedCountriesContext";

import { CountryCode } from "pages/employer-of-record/onboarding/constants";
import { AdditionalPayFormData, FormPayloadType } from "../../types";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { getFormInitialValues } from "pages/employer-of-record/payments/utils";
import { getEnvironmentName } from "lib/environment";
import { getSortedCountries } from "./util";

function CountrySelect(): ReactElement {
  const additionalPayContext = useContext(AdditionalPayContext);
  const formikContext =
    useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { handleChange, setValues, setTouched } = formikContext;

  const { formType } = useParams();
  const formInitialValues = getFormInitialValues(formType || "");

  const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);
    additionalPayContext?.setSelectedCountry(e.target.value);
    formInitialValues.setUp.workCountry = e.target.value as CountryCode;
    setValues(() => formInitialValues);
    setTouched({});
  };

  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const memberByCountryCount = additionalPayContext?.memberCountryCount || {};

  // Filter supported countries based on productionCountries array if in production mode
  const isProduction = getEnvironmentName() === "production";
  const productionCountries = [
    CountryCode.CA,
    CountryCode.GB,
    CountryCode.MX,
    CountryCode.ES,
    CountryCode.NL,
    CountryCode.PT,
    CountryCode.CO,
    CountryCode.IE,
    CountryCode.IN,
    CountryCode.AU,
    CountryCode.BR,
  ];
  let filteredCountries = isProduction
    ? Object.values(supportedCountriesData).filter((country) =>
        productionCountries.includes(country.code as CountryCode)
      )
    : Object.values(supportedCountriesData);

  filteredCountries = getSortedCountries(
    filteredCountries,
    memberByCountryCount
  );

  return (
    <Select
      name="workCountry"
      placeholder="Select"
      value={formikContext.values.setUp.workCountry}
      onChange={onChangeHandler}
    >
      {Object.values(filteredCountries).map(
        (country: SupportedCountriesDataType) => {
          const count = memberByCountryCount[country.code] || 0;
          const option = `${country.commonName} (${count} members)`;
          return (
            <SelectOption
              value={country.code}
              key={country.code}
              disabled={count === 0}
            >
              {option}
            </SelectOption>
          );
        }
      )}
    </Select>
  );
}

export default CountrySelect;
