import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../Row/Row";
import RequestChangesAction from "../../../RequestChangesAction/RequestChangesAction";
import { Card } from "@justworkshr/milo-core";
import BadgeTitle from "../BadgeTitle";

export default function BenefitsCard(): ReactElement {
  const { profileInfo } = useProfileContextData();

  const personalInfo = profileInfo?.eorEmployeeProfile.personalInfo;

  const inEsic = personalInfo?.inEsic;
  const inPfUan = personalInfo?.inPfUan;

  return (
    <>
      <Card
        title={
          (
            <BadgeTitle title="Benefits" badgeText="India" />
          ) as unknown as string
        }
        actions={[<RequestChangesAction />]}
      >
        <Row label="PF UAN" value={inPfUan} required={false} />
        <Row label="ESIC Number" value={inEsic} required={false} />
      </Card>
    </>
  );
}
