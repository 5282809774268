import { AccountSettings } from "./account-settings";
import { EditProfile } from "./account-settings/edit-profile";
import { CompanySettings } from "./company-settings";
import {
  EmployerOfRecord,
  EmployerOfRecordFrameless,
} from "./employer-of-record";
import { MoreServices } from "./more-services";
import { Page404 } from "./page-404";
import { INVITATION_CENTER_PATH, InvitationCenter } from "./invitation-center";
import {
  WCDocumentRequest,
  ClaimsHome as WCClaimsHome,
  WC_CLAIMS_REQUEST_PATH,
  WC_DOCUMENT_REQUEST_PATH,
} from "./workers-compensation";
import { Invitation } from "./invitation";
import {
  INTEGRATION_MARKETPLACE_PATH,
  IntegrationMarketplace,
} from "./pmt/integrations/integration-marketplace";
import {
  PARTNER_DESCRIPTION_PAGE_PATH,
  PartnerDescriptionPage,
} from "./pmt/integrations/partner-description-page";
import {
  PARTNER_SETUP_PAGE_PATH,
  PartnerSetupPage,
} from "./pmt/integrations/partner-setup-page";
import { BASE_EXPENSES_PATH, ExpenseManagementApp } from "./expenses";
import { PEOPLE_ANALYTICS_PATH, PeopleAnalytics } from "./people-analytics";
import { FIRMS_PATH, FirmsApp } from "./advisor/firms";
import {
  LIFE_AND_LEAVE_ENROLLMENT_PATH,
  LifeAndLeaveEnrollment,
} from "./life-and-leave/enrollment";
import {
  BASE_TEAM_MANAGEMENT_PATH,
  TeamManagementApp,
} from "./team-management";
import {
  INTERNATIONAL_CONTRACTOR_MEMBER_DETAIL_PATH,
  InternationalContractorMemberDetail,
} from "./international-contractor-profile-page";
import { BENEFITS_PATH, BenefitsApp } from "./benefits";
import { PayTransactions } from "./pay-transactions";
import RollbarPage from "./rollbar-test-page";
import { LoginCallbackPage } from "./login-callback";
import {
  NSF_RESOLUTION_INSTRUCTIONS_PATH,
  NsfResolutionInstructions,
} from "./nsf-resolution-instructions";
import { FORBIDDEN_ERROR_PATH, Page403 } from "./page-403";
import { UNKNOWN_ERROR_PATH, Page520 } from "./page-520";
import {
  INTERNATIONAL_SOLUTIONS_PATH,
  InternationalSolutionsPage,
} from "./international-solutions";
import { BASE_FORM_8973_PATH, Form8973 } from "./form-8973";
import {
  TIME_TRACKING_LANDING_PATH,
  TimeTrackingLanding,
} from "./time-tracking-landing";
import {
  FIRM_INVITATION_PATH,
  FirmInvitationApp,
} from "./advisor/firm-invitation";

// TODO: Consider whether these should be in an env file, or its own constants file
// Resource Routes/gql queries will be stored separately from path routes.
// To use a dynamic route, include the path as you would in a match option, and use
// https://reactrouter.com/web/api/generatePath

// This is where real routes begin
const ACCOUNT_SETTINGS_PATH = "/account-settings";
const COMPANY_SETTINGS_PATH = "/company-settings";
const EMPLOYER_OF_RECORD_PATH = "/employer-of-record";
const MORE_SERVICES_PATH = "/more-services";
const PAY_TRANSACTIONS_PATH = "/pay-transactions";

const ROLLBAR_PAGE_PATH = "/rollbar-test-page";

const INVITATION_PATH = "/invitation";
const ADDITIONAL_PAY_PATH = "/masspay";

const VOUCHERS_PATH = "/vouchers";

export {
  // This is where real routes begin
  ACCOUNT_SETTINGS_PATH,
  AccountSettings,
  BENEFITS_PATH,
  BenefitsApp,
  COMPANY_SETTINGS_PATH,
  CompanySettings,
  EditProfile,
  EMPLOYER_OF_RECORD_PATH,
  EmployerOfRecord,
  EmployerOfRecordFrameless,
  INTERNATIONAL_SOLUTIONS_PATH,
  InternationalSolutionsPage,
  MoreServices,
  MORE_SERVICES_PATH,
  WC_DOCUMENT_REQUEST_PATH,
  WCDocumentRequest,
  WC_CLAIMS_REQUEST_PATH,
  WCClaimsHome,
  Page404,
  InvitationCenter,
  INVITATION_CENTER_PATH,
  IntegrationMarketplace,
  INTEGRATION_MARKETPLACE_PATH,
  InternationalContractorMemberDetail,
  INTERNATIONAL_CONTRACTOR_MEMBER_DETAIL_PATH,
  Invitation,
  INVITATION_PATH,
  PartnerDescriptionPage,
  PARTNER_DESCRIPTION_PAGE_PATH,
  BASE_EXPENSES_PATH,
  ExpenseManagementApp,
  PartnerSetupPage,
  PARTNER_SETUP_PAGE_PATH,
  PeopleAnalytics,
  PEOPLE_ANALYTICS_PATH,
  FIRMS_PATH,
  FirmsApp,
  LIFE_AND_LEAVE_ENROLLMENT_PATH,
  LifeAndLeaveEnrollment,
  BASE_TEAM_MANAGEMENT_PATH,
  TeamManagementApp,
  PAY_TRANSACTIONS_PATH,
  PayTransactions,
  ADDITIONAL_PAY_PATH,
  ROLLBAR_PAGE_PATH,
  RollbarPage,
  LoginCallbackPage,
  NSF_RESOLUTION_INSTRUCTIONS_PATH,
  NsfResolutionInstructions,
  BASE_FORM_8973_PATH,
  Form8973,
  TIME_TRACKING_LANDING_PATH,
  TimeTrackingLanding,
  FIRM_INVITATION_PATH,
  FirmInvitationApp,
  FORBIDDEN_ERROR_PATH,
  Page403,
  UNKNOWN_ERROR_PATH,
  Page520,
  VOUCHERS_PATH,
};
