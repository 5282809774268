import styles from "./FormSignDocument.module.css";
import { Formik, Form } from "formik";
import { Form8973Context } from "../../contexts";
import { useContext, useEffect, useRef } from "react";
import { Footer } from "../Footer/Footer";
import { WORKFLOW_STEPS } from "../../constants";
import FormHeader from "../FormHeader/FormHeader";
import HelloSign from "hellosign-embedded";
import { VARIABLE_NAMES, getEnvVariable } from "lib/environment";

const { formStepContentContainer, formStepContent, reviewInfo, helloSign } =
  styles;

const FormSignDocument: React.FC = () => {
  const { formValues, embeddedSignUrl, setStep } = useContext(Form8973Context);
  const helloSignFrameRef = useRef<HTMLDivElement>(null);
  const clientId = getEnvVariable(VARIABLE_NAMES.DROPBOX_SIGN_CLIENT_ID);
  const isProduction = import.meta.env.NODE_ENV === "production";

  useEffect(() => {
    if (!embeddedSignUrl || !helloSignFrameRef.current) return;

    const client = new HelloSign();
    client.open(embeddedSignUrl, {
      clientId: clientId,
      container: helloSignFrameRef.current,
      allowCancel: false,
      skipDomainVerification: true,
      testMode: !isProduction,
    });

    client.on("sign", () => {
      setStep(WORKFLOW_STEPS.CONFIRMATION_SUCCESS);
    });

    return () => {
      client.close();
    };
  }, [embeddedSignUrl, setStep, clientId, isProduction]);

  return (
    <Formik initialValues={formValues} onSubmit={() => {}}>
      {() => (
        <Form>
          <FormHeader />
          <div className={formStepContentContainer}>
            <div className={formStepContent}>
              <h2 className={reviewInfo}>
                Next, double check the form is correct. Go Back to make any
                edits.
              </h2>
              <div className={helloSign} ref={helloSignFrameRef} />
            </div>
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormSignDocument;
