import { useMemo } from "react";
import { integrationMarketplaceDataHookResponse } from "./useIntegrationMarketplaceData";
import { CardData } from "../integration-marketplace";
import {
  getAccountingIntegrationsData,
  getGreenhouseIntegrationData,
  getPmtIntegrationsData,
  sortCardsAlphabetically,
} from "../utils";

const useIntegrationMarketplaceCards = (
  integrationMarketplaceDataHookResponse: integrationMarketplaceDataHookResponse
): CardData[] => {
  const {
    hasIntegrationsData,
    pmtIntegrationsEnabled,
    integrationSolutionDataQueryResult,
    pmtIntegrationFrameworkDataResult,
  } = integrationMarketplaceDataHookResponse;
  const { data: legacyIntegrationsData } = integrationSolutionDataQueryResult;
  const { data: pmtIntegrationsData } = pmtIntegrationFrameworkDataResult;

  return useMemo(() => {
    if (hasIntegrationsData) {
      const accountingIntegrations: (CardData | null)[] | undefined =
        getAccountingIntegrationsData(
          legacyIntegrationsData?.accountingIntegrations?.integrationsInfo
        );
      const greenhouseIntegration: CardData[] = getGreenhouseIntegrationData(
        legacyIntegrationsData?.authenticatedMember?.company
          ?.greenhouseCandidateHiredIntegration
      );

      const pmtIntegrations: (CardData | null)[] = pmtIntegrationsEnabled
        ? getPmtIntegrationsData(
            pmtIntegrationsData?.pmtIntegrationsFramework?.solutions?.data,
            pmtIntegrationsData?.pmtIntegrationsFramework?.integrations?.data,
            legacyIntegrationsData?.authenticatedMember.company.uuid
          )
        : [];
      const formattedCards = [
        accountingIntegrations,
        pmtIntegrations,
        greenhouseIntegration,
      ]
        .flat()
        .filter((integration) => integration) as CardData[];
      sortCardsAlphabetically(formattedCards);
      return formattedCards;
    }
    return [];
  }, [
    hasIntegrationsData,
    pmtIntegrationsEnabled,
    legacyIntegrationsData,
    pmtIntegrationsData,
  ]);
};

export default useIntegrationMarketplaceCards;
