import { ADDITIONAL_PAY_PATH, EMPLOYER_OF_RECORD_PATH } from "pages";
import type {
  AdditionalPaySteps,
  EorMembersType,
  ExchangeRateType,
} from "../types";
import {
  BENEFITS_AND_ALLOWANCES_FORM_INITIAL_VALUES,
  EXPENSE_REIMBURSEMENT_FORM_INITIAL_VALUES,
  PAY_FORM_INITIAL_VALUES,
} from "../components/Setup/constants";
import {
  CountryConfigKeyType,
  EmployeePayPayload,
} from "../components/Setup/components/EmployeePay/types";
import {
  ADDITIONAL_PAY_FORM_ROUTES,
  AdditionalPayStepStructure,
  BenefitsAndAllowancesType,
  EmployeePayType,
  FormPayType,
  ReimbursementPayType,
  ESTIMATION_BASE_CURRENCY,
} from "../constants";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
  SetupStepData,
} from "../components/Setup/types";
import {
  benefitsAndAllowancesFormValidationSchema,
  expenseFormValidationSchema,
  payFormValidationSchema,
} from "../validationSchemas";
import { GetEoRMembersByCompanyUuidQuery } from "types/generated/operations";
import {
  FieldConfig,
  Option,
} from "../components/Setup/components/SetupFormGenerator";
import { BonusType } from "../components/Setup/components/EmployeePay/BonusPay/constants";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import { toCurrencyFormat } from "pages/employer-of-record/utils";
import { ReimbursementPayloadType } from "../components/Setup/components/Reimbursement";
import { CurrencyType } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { loadFormInitialValues } from "../components/Setup/components/EmployeePay/initialValueLoader";
import { formatDatePickerDate } from "pages/employer-of-record/components";
import {
  DistanceDisplayUnit,
  ImperialSystemCountries,
} from "pages/employer-of-record/onboarding/constants";

const includeWorkPeriodFor = [BonusType.NONDISCRETIONARY];

export const assertUnreachable = (x: string): never => {
  console.error("Unexpected error, codepath should be unreachable: " + x);
  throw new Error("Unexpected error: " + x);
};

export const calculateStepNumber = (state_key?: AdditionalPaySteps) => {
  if (!state_key) return -1;
  return (
    AdditionalPayStepStructure.findIndex((step) => step.key === state_key) + 1
  );
};

export const getMassPayNavRoute = (formType: string, path: string) => {
  let formTypeRoute = ADDITIONAL_PAY_FORM_ROUTES.EMPLOYEE_PAY;

  if (
    formType === ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT ||
    formType === ADDITIONAL_PAY_FORM_ROUTES.TAXABLEBENEFITSANDALLOWANCES
  ) {
    formTypeRoute = formType;
  }

  return `${EMPLOYER_OF_RECORD_PATH}${ADDITIONAL_PAY_PATH}/${formTypeRoute}/${path}`;
};

export const getInitialEmployeePayload = (
  payType: EmployeePayType,
  countryCode: string
): EmployeePayPayload | null => {
  return loadFormInitialValues(payType, countryCode as CountryConfigKeyType);
};

export const getEmployeeName = (info?: EorMembersType) => {
  if (!info) return "";

  const preferredName = info.preferredFirstName || "";
  const firstName = preferredName ? info.preferredFirstName : info.firstName;
  const middleName = info.middleName ? info.middleName : "";
  const fullName = `${firstName} ${middleName} ${info.lastName}`;

  return fullName;
};

export function mergeMembersAndPayments(
  members: GetEoRMembersByCompanyUuidQuery["eorMembers"],
  payments: PaymentDetailsType[]
) {
  return members.map((member) => {
    const payment = payments.find((p) => p.memberId === member.id);
    return {
      memberId: member.id,
      memberUuid: member.memberUuid,
      fullName: getEmployeeName(member),
      grossAmount: payment ? payment.grossAmount : "",
      workPeriodStart: payment ? payment.workPeriodStart : "",
      workPeriodEnd: payment ? payment.workPeriodEnd : "",
    };
  });
}

export const getTotalGrossAmount = (payDetails: PaymentDetailsType[]) => {
  const totalAmount = payDetails.reduce((total, paymentDetail) => {
    const grossAmount = parseFloat(paymentDetail.grossAmount || "0");
    return total + grossAmount;
  }, 0);

  return (totalAmount / 100).toFixed(2);
};

export function recurseSubFieldsForNames<
  T extends FormPayloadType = FormPayloadType
>(field: FieldConfig<T>): string[] {
  if (field.subFields) {
    return field.subFields.reduce(
      (acc, subField) => [...acc, ...recurseSubFieldsForNames(subField)],
      [field.name]
    );
  }
  return [field.name];
}

export const getTotalNumberOfPayees = (payDetails: PaymentDetailsType[]) => {
  return payDetails.filter((obj) => Number(obj.grossAmount) > 0).length;
};

export const payTypeWorkPeriod = (formData: SetupStepData<FormPayloadType>) => {
  const payType = formData.type;
  const bonusType = getBonusType(formData.payload);

  return (
    (bonusType &&
      includeWorkPeriodFor.includes(bonusType) &&
      payType === EmployeePayType.BONUS) ||
    payType !== EmployeePayType.BONUS
  );
};

function getBonusType(payload: FormPayloadType): BonusType | undefined {
  if ("taxes" in payload && payload.taxes && "bonusType" in payload.taxes) {
    return payload.taxes.bonusType;
  }
  return undefined;
}

export const hasWorkPeriod = (
  formData: SetupStepData<FormPayloadType>
): boolean => {
  const employeePayTypes: FormPayType[] = Object.values(EmployeePayType);

  if (employeePayTypes.includes(formData.type)) {
    return payTypeWorkPeriod(formData);
  } else {
    return false;
  }
};

export const capitalize = (key: string) => {
  return key.charAt(0).toUpperCase() + key.slice(1);
};

export const formatKeyForDisplay = (key: string) => {
  key = key
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/ [A-Z]/g, (match) => match.toLowerCase());
  return capitalize(key);
};

export const isPayTypeSetup = (
  data: SetupStepData<FormPayloadType>
): data is SetupStepData<EmployeePayPayload> => {
  return "employeePay" in data;
};

export const isExpenseTypeSetup = (
  data: SetupStepData<FormPayloadType>
): data is SetupStepData<ReimbursementPayloadType> => {
  return "reimbursement" in data;
};

export const getPayTypeOrExpenseType = (
  data: SetupStepData<FormPayloadType>
): string => {
  if (isPayTypeSetup(data)) {
    return data.type;
  } else if (isExpenseTypeSetup(data)) {
    return "Reimbursement";
  } else {
    throw new Error("Invalid SetupStepData type");
  }
};

export const getFormInitialValues = (
  formType: string
): AdditionalPayFormData<FormPayloadType> => {
  switch (formType) {
    case ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT:
      return EXPENSE_REIMBURSEMENT_FORM_INITIAL_VALUES;
    case ADDITIONAL_PAY_FORM_ROUTES.TAXABLEBENEFITSANDALLOWANCES:
      return BENEFITS_AND_ALLOWANCES_FORM_INITIAL_VALUES;
    default:
      return PAY_FORM_INITIAL_VALUES;
  }
};

export const getForValidationSchema = (formType: string) => {
  switch (formType) {
    case ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT:
      return expenseFormValidationSchema;
    case ADDITIONAL_PAY_FORM_ROUTES.TAXABLEBENEFITSANDALLOWANCES:
      return benefitsAndAllowancesFormValidationSchema;
    default:
      return payFormValidationSchema;
  }
};

export const addDisbursementDate = (
  data: FieldConfig[],
  payPeriodOptions: Option[]
) => {
  return data.map((field) => {
    if (field.name === "payPeriodId") {
      return {
        ...field,
        options: payPeriodOptions,
      };
    }
    return field;
  });
};

export const formatCentsValue = (
  value: string | undefined,
  language: string
): string | undefined => {
  const numVal = Number(value);
  if (!value || isNaN(numVal)) return undefined;

  //values are stored in cents
  const numericValue = Number(value) / 100;
  return new Intl.NumberFormat(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericValue);
};

export const amountInCents = (amount: number) => {
  const value = Math.round(amount * 100);
  return value.toString();
};

export const isFutureDate = (date: string) => {
  const currentDate = new Date();
  const selectedDate = new Date(date);

  return selectedDate > currentDate;
};

export const getTypeDisplayValue = (value: string) => {
  switch (value) {
    case EmployeePayType.BONUS:
      return EmployeePayType.BONUS;
    case EmployeePayType.COMISSION:
      return EmployeePayType.COMISSION;
    case EmployeePayType.RETROACTIVE:
      return EmployeePayType.RETROACTIVE;
    case EmployeePayType.OFF_CYCLE:
      return "Missed payroll";
    case ReimbursementPayType.REIMBURSEMENT:
      return "Expense reimbursement";
    case BenefitsAndAllowancesType.BENEFITSANDALLOWANCES:
      return "Taxable benefits & allowances";
    default:
      return EmployeePayType.BONUS;
  }
};

export const extendFormData = (
  setup: SetupStepData<FormPayloadType>,
  options: { [key: string]: Option }
) => {
  if (options[setup.payload.paymentSettings.payPeriodId]) {
    setup.payload.paymentSettings.disbursementDate = formatDate(
      options[setup.payload.paymentSettings.payPeriodId].label,
      "YYYY-MM-DD"
    );
  }
  return setup;
};

export type Base64File = {
  data: string | ArrayBuffer | null;
  name: string;
  type: string;
};

export async function encodeFile(file: File): Promise<Base64File> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve({
        data: reader.result,
        name: file.name,
        type: file.type,
      });
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

//
// This is intended to process payloads and convert any File objects to base64 encoded strings.
//
export async function recursivelyConvertFilesToBase64Objects(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>
) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value instanceof File) {
        obj[key] = await encodeFile(value);
      } else if (
        Array.isArray(value) &&
        value.some((item) => item instanceof File)
      ) {
        obj[key] = await Promise.all(
          value.map((item) => (item instanceof File ? encodeFile(item) : item))
        );
      } else if (typeof value === "object" && value !== null) {
        await recursivelyConvertFilesToBase64Objects(value); // Recurse into objects
      }
    }
  }
}

export const removeEntriesWithNoGrossAmount = (
  entries: PaymentDetailsType[]
) => {
  return entries.filter(
    (entry) => entry.grossAmount && entry.grossAmount.trim() !== ""
  );
};

// Returns an exchange rate rounded up to 6 decimals; returns undefined on missing data
export const getExchangeRate = (
  currency: string | undefined,
  exchangeRates: ExchangeRateType[] | undefined
) => {
  if (currency && exchangeRates) {
    const filteredRates = exchangeRates.filter((rate) => {
      return rate.target === currency;
    });
    if (filteredRates.length > 0) {
      return Math.ceil((1 / filteredRates[0].rate) * 1e6) / 1e6;
    }
  }
};

// Returns the estimated value in the base currency; returns undefined on missing/malformed data
export const getEstimatedValue = (
  amount: string | undefined,
  exchangeRate: number | undefined
) => {
  if (amount && exchangeRate) {
    const convertedAmount = parseInt(amount) * exchangeRate;
    if (!isNaN(convertedAmount)) {
      return `~ ${toCurrencyFormat(
        convertedAmount,
        ESTIMATION_BASE_CURRENCY,
        true
      )}`;
    }
  }
};

export const getActiveCurrency = (
  currencies: CurrencyType[] | undefined
): CurrencyType | undefined => {
  return currencies?.[0];
};

export const isWithinEmploymentDates = (
  currentDate: string,
  contractStart: string | null | undefined,
  contractEnd: string | null | undefined
) => {
  if (!contractStart) return false;

  const formattedDate = formatDatePickerDate(currentDate);
  const dateObj = new Date(formattedDate).getTime();
  const validStart = new Date(contractStart).getTime();
  const validEnd = new Date(contractEnd || "").getTime();

  if (dateObj < validStart || dateObj > validEnd) {
    return false;
  }
  return true;
};

export const getMonthsAgoISODate = (months: number) => {
  const today = new Date();
  today.setMonth(today.getMonth() - months);
  today.setSeconds(0, 0); // This sets seconds and milliseconds to 0
  return today.toISOString();
};

export const getDistanceDisplayUnit = (
  countryCode: string | null | undefined
) => {
  if (!countryCode) return "";
  return ImperialSystemCountries.some((country) => country === countryCode)
    ? DistanceDisplayUnit.Imperial
    : DistanceDisplayUnit.Metric;
};

export const validateOffCycleRanges = (
  workStartDate: string,
  workPeriodEnd: string
) => {
  const contractStart = new Date(workStartDate).getTime();
  const payPeriodEnd = new Date(workPeriodEnd).getTime();

  return contractStart <= payPeriodEnd;
};
