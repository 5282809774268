import { ReactElement, useEffect, useMemo, useState } from "react";
import { useProfileContextData } from "../../../../contexts/ProfileInfoContext";
import {
  EorEmployeeAddress,
  EorEmployeeContactDetails,
} from "types/employer-of-record";
import { deepEqualWithSkip } from "pages/employer-of-record/employee-profile/utils";
import Row from "../../../Row/Row";
import { useAddressFormatter } from "../../../../../hooks/useAddressFormatter";
import { useTranslation } from "react-i18next";
import EditAction from "../../../EditAction/EditAction";
import { Card } from "@justworkshr/milo-core";

type Props = {
  editable: boolean;
};

export default function AddressInformation(props: Props): ReactElement {
  const { editable } = props;
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const contactDetails: EorEmployeeContactDetails =
    profileInfo?.eorEmployeeProfile.contactDetails;

  const homeAddress: EorEmployeeAddress = contactDetails?.homeAddress ?? {};
  const mailingAddress: EorEmployeeAddress =
    contactDetails?.mailingAddress ?? {};
  const isEqual = deepEqualWithSkip(homeAddress, mailingAddress, ["type"]);

  const AddressFormat = (label: string, address: EorEmployeeAddress) => {
    const { formatter } = useAddressFormatter(address.countryCode ?? "");
    const [addressFormat, setaddressFormat] = useState<string[] | null>(null);

    const addressFields = useMemo(() => {
      return {
        address1: address.address1 ?? "",
        address2: address.address2 ?? "",
        city: address.city ?? "",
        province: address.zoneCode ?? "",
        zip: address.postalCode ?? "",
        country: address.countryCode ?? "",
      };
    }, [address]);

    useEffect(() => {
      if (!formatter) return;
      const renderaddressFormat = async () => {
        try {
          const addressFieldsFormat = await formatter.format(addressFields);
          if (!addressFieldsFormat) return;
          setaddressFormat(addressFieldsFormat);
        } catch (error) {
          console.error("Error fetching country address fields format:", error);
          setaddressFormat(null);
        }
      };
      renderaddressFormat();
    }, [formatter, addressFields]);

    return (
      <>
        <Row label={label} required>
          {addressFormat &&
            addressFormat.map((field: string, index: number) => (
              <div key={index}>{field}</div>
            ))}
        </Row>
      </>
    );
  };

  const renderAddresses = () => {
    if (isEqual) {
      return AddressFormat("Address", homeAddress);
    }
    return (
      <>
        {AddressFormat("Home address", homeAddress)}
        {AddressFormat("Mailing address", mailingAddress)}
      </>
    );
  };

  return (
    <>
      <Card
        title={t("Address")}
        actions={[<EditAction target="address" enabled={editable} />]}
      >
        {renderAddresses()}
      </Card>
    </>
  );
}
