import { Button } from "@justworkshr/milo-core";
import { WORKFLOW_PREVIOUS_STEP } from "../../constants";
import { Form8973Context } from "../../contexts";
import { useCallback, useContext } from "react";
import styles from "./FormBackButton.module.css";

const { backButton } = styles;

export const FormBackButton = () => {
  const { step, setStep } = useContext(Form8973Context);

  const handleBack = useCallback(() => {
    setStep(WORKFLOW_PREVIOUS_STEP[step]);
  }, [setStep, step]);

  return (
    <div className={backButton}>
      <Button variant="outlined" onClick={handleBack}>
        Back
      </Button>
    </div>
  );
};
