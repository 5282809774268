import { ReimbursementPayloadType } from "./types";

export const REIMBURSEMENT_INITIAL_VALUES: ReimbursementPayloadType = {
  expenseDetails: {
    transactionDate: "",
    distance: undefined,
    receipts: undefined,
    additionalInformation: "",
  },
  paymentSettings: {
    payPeriodId: "",
    disbursementDate: "",
    notes: "",
  },
};

export const MAX_REIMBURSEMENT_FILE_SIZE = 10 * 1024 * 1024; //10MB
export const REIUMBURSEMENT_SUPPORTED_FILE_FORMATS = [
  "image/jpeg",
  "image/png",
  "application/pdf",
];
