import { useNavigate } from "react-router-dom";
import { Button, Dialog, Alert } from "@justworkshr/milo-core";
import styles from "./RemovePendingFirmModal.module.css";
import {
  GetAllClientInvitesQuery,
  useRevokeInviteToFirmMutation,
} from "types/generated/operations";
import { datadogRum } from "@datadog/browser-rum";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { ApolloQueryResult } from "@apollo/client/core";

type FirmInvite = NonNullable<GetAllClientInvitesQuery["allClientInvites"]>[0];

const { removeFirmDialog, modalContent } = styles;

interface RemoveFirmModalProps {
  firmInvite: FirmInvite;
  refetchData: () => Promise<ApolloQueryResult<GetAllClientInvitesQuery>>;
}

const RemovePendingFirmModal = ({
  firmInvite,
  refetchData,
}: RemoveFirmModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [status, setStatus] = useState<string | null>(null);
  const handleDismiss = () => {
    setStatus(null);
  };

  const [removeFirm, removeFirmResult] = useRevokeInviteToFirmMutation();
  const handleFirmRemove = async () => {
    try {
      await removeFirm({
        variables: {
          inviteId: firmInvite.id,
        },
        update: (cache) => {
          cache.evict({ id: `Firm:${firmInvite.id}` });
          cache.gc();
        },
      });
      await refetchData();
      navigate("/firms");
    } catch (err) {
      datadogRum.addError(err);
      setStatus("There was an error removing the firm, try again.");
    }
  };

  return (
    <div
      className={removeFirmDialog}
      data-testid={`remove-firm-modal-${firmInvite.id}`}
    >
      <Dialog
        data-testid="removeFirm-dialog"
        isOpen={true}
        title={"Remove firm"}
        size={"sm"}
        onClose={() => navigate("/firms")}
        secondaryButton={
          <Button
            variant="ghost"
            as={Link}
            state={{ preserveScrollPosition: true }}
            to="/firms"
          >
            {t("Cancel")}
          </Button>
        }
        primaryButton={
          <Button
            color="destructive"
            loading={removeFirmResult?.loading}
            onClick={handleFirmRemove}
            data-testid="remove-firm-button"
          >
            {t("Remove")}
          </Button>
        }
      >
        {status && (
          <Alert onDismiss={handleDismiss} color="destructive" dismissible>
            {status}
          </Alert>
        )}
        <div className={modalContent}>
          Members of <b>{firmInvite.invitedFirmName}</b> won’t be able to access
          your company information anymore. You’ll have to re-invite the firm to
          grant them access again.
        </div>
      </Dialog>
    </div>
  );
};

export default RemovePendingFirmModal;
