import { Button } from "@justworkshr/milo-core";
import styles from "./FormNextButton.module.css";
import { useFormikContext } from "formik";

const { nextButton } = styles;

export const FormNextButton = () => {
  const { submitForm } = useFormikContext();

  const handleNext = async () => {
    await submitForm();
  };

  return (
    <div className={nextButton}>
      <Button onClick={handleNext}>Next</Button>
    </div>
  );
};
