import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import heroImage from "../../images/hero-images/15five-hero.svg";
import logo from "../../images/integration-partner-logos/15five-logo.svg";
import horizontalLogo from "../../images/horizontal-logos/15five-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  justworksPermissionsCopy,
  partnerPermissionsCopy,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import ExternalLinkButton from "../../partner-description-page/components/InstanceDetailsPanel/ExternalLinkButton/ExternalLinkButton";
import React from "react";

export const fifteenfiveConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: logo,
      integrationName: "15Five",
      integrationDescription:
        "Automatically sync employee information from Justworks to 15Five",
      integrationCategory: "Performance management",
      requiredPermissions: HR_PERMISSIONS,
      slug: "merge__fifteenfive_v1",
      pdpUrl: "/cf/integrations/merge__fifteenfive_v1",
    },

    pdp: {
      headerDescription:
        "15Five is the performance management platform that creates effective managers, highly engaged employees, and top-performing organizations.",
      heroImageUrl: heroImage,
      horizontalLogoUrl: horizontalLogo,
      instanceDetailsPanelConfig: {
        hideLastActive: true,
        customDisconnectButton: (
          <ExternalLinkButton
            url="https://my.15five.com/integrations/"
            copy="Disconnect Integration"
            variant="outlined"
          />
        ),
        customSetupButton: (
          <ExternalLinkButton
            url="https://my.15five.com/integrations/"
            copy="Get Started"
          />
        ),
      },
      resourceCard: {
        links: [
          {
            title: "15Five in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/29604237018523-15Five-Integration",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about 15Five",
            href: "https://www.15five.com/",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "15Five + Justworks",
          subheader:
            "Unlock the power of seamless data connectivity with the Justworks 15Five integration. Your Justworks employee data will be automatically synced, setting you up for success right away.",
          list: [
            {
              summary: "Automated account management",
              detail:
                "Manage employee information in Justworks, and let this integration seamlessly provision and deprovision team members to their 15Five accounts.",
            },
            {
              summary: "Connected data",
              detail:
                "Combining your Justworks data with the power of 15Five gives you everything you need to make an impact on engagement, retention, and performance.",
            },
            {
              summary: "Efficient data-syncing",
              detail:
                "Eliminate manual work, saving time, reducing errors, and boosting overall efficiency. Data syncs from Justworks to 15Five every 24 hours, ensuring accurate, up-to-date information.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What member data will we share with 15Five?",
            subheader:
              "While the integration is connected, 15Five will automatically pull in the following data from Justworks for your US-based team members:",
            sharedDataGroups: [
              {
                header: "Standard information",
                sharedData: [
                  "First Name",
                  "Preferred Name",
                  "Last Name",
                  "Email",
                  "Job Title",
                  "Location",
                  "Employee ID",
                  "Status (Is active Y/N)",
                  "Hire Date",
                  "Start Date",
                  "Manager/Reviewer",
                  "Termination Date",
                ],
              },
              {
                header: "Demographic data (if enabled)",
                sharedData: ["Salary", "Gender", "Ethnicity", "Birth Date"],
              },
              {
                header: "Group Types / Groups",
                sharedData: ["Departments"],
              },
            ],
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In 15Five",
                detail: partnerPermissionsCopy("15Five", ["Account admin"]),
              },
            ],
          },
        },
      },
      disconnectModalContent: {
        title: "Disconnect from 15Five",
        body: <span></span>,
        disconnectButtonText: "Disconnect",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/sections/360001292171-Account-Administration",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
