import { useFormikContext } from "formik";
import type {
  ContactInfoFormType,
  AddressFormType,
  FormattedLabelsType,
} from "../../onboarding/contact-info/types";
import React, { useContext } from "react";
import AddressFormField from "./AddressFormField";
import { useAddressFormatter } from "../../hooks/useAddressFormatter";
import { FieldName } from "@shopify/address";
import styles from "./AddressForm.module.css";
import {
  SupportedCountriesContext,
  SupportedCountriesData,
} from "../../contexts/supportedCountriesContext";
import { getCustomOrderedFields } from "./customOrderedFields";
import pick from "lodash/pick";

type Props = {
  handleBlur: (event: React.FocusEvent) => void;
  handleChange: (event: React.ChangeEvent) => void;
  formPath: string;
  supportedCountries?: Array<keyof SupportedCountriesData>;
};

const addressFields: AddressFormType = {
  country: "country",
  address1: "address1",
  address2: "address2",
  city: "city",
  province: "zone",
  zip: "postalCode",
};

const AddressForm = ({
  handleChange,
  handleBlur,
  formPath,
  supportedCountries,
}: Props) => {
  const { values } = useFormikContext<ContactInfoFormType>();
  const {
    supportedCountriesData: rawSupportedCountriesData,
    getSupportedProvinces,
  } = useContext(SupportedCountriesContext);

  const supportedCountriesData = supportedCountries
    ? pick(rawSupportedCountriesData, supportedCountries)
    : rawSupportedCountriesData;
  const { countryCode } = values.contactInfo[formPath];
  const { country, orderedFields: defaultOrderedFields } =
    useAddressFormatter(countryCode);
  const formattedLabels: FormattedLabelsType | undefined = country?.labels;
  const zones = getSupportedProvinces(country?.code);
  const orderedFields =
    getCustomOrderedFields(country?.code) || defaultOrderedFields;

  const renderAddressFormFields = (field: FieldName[]) => {
    return field.map((fieldName: string) => {
      fieldName = addressFields[fieldName];
      return Object.values(addressFields).includes(fieldName) ? (
        <div className={`${styles.formFieldPadding}`} key={fieldName}>
          <AddressFormField
            fieldName={fieldName}
            label={(formattedLabels && formattedLabels[fieldName]) ?? fieldName}
            handleBlur={handleBlur}
            handleChange={handleChange}
            zones={zones}
            formPath={formPath}
            key={fieldName}
            supportedCountriesData={supportedCountriesData}
          />
        </div>
      ) : null;
    });
  };

  return (
    <>
      {orderedFields
        ? orderedFields.map((field: FieldName[], index: number) => (
            <div key={index}>{renderAddressFormFields(field)}</div>
          ))
        : null}
    </>
  );
};

export default AddressForm;
