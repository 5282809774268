import { createContext, useState, ReactNode } from "react";
import {
  Form8973ContextType,
  FormikFormValuesType,
  CompanyValuesType,
} from "../types";
import { WORKFLOW_STEPS } from "../constants";
import { defaultCompanyValues, defaultFormValues } from "../constants";

const initialValues: Form8973ContextType = {
  embeddedSignUrl: undefined,
  setEmbeddedSignUrl: () => {},
  step: WORKFLOW_STEPS.STEP_ONE,
  setStep: () => {},
  companyValues: defaultCompanyValues,
  setCompanyValues: () => {},
  formValues: defaultFormValues,
  setFormValues: () => {},
};

export const Form8973Context =
  createContext<Form8973ContextType>(initialValues);

interface Form8973ContextProviderProps {
  children: ReactNode;
}

export const Form8973ContextProvider = ({
  children,
}: Form8973ContextProviderProps) => {
  const [step, setStep] = useState<string>(WORKFLOW_STEPS.STEP_ONE);
  const [companyValues, setCompanyValues] =
    useState<CompanyValuesType>(defaultCompanyValues);
  const [formValues, setFormValues] =
    useState<FormikFormValuesType>(defaultFormValues);
  const [embeddedSignUrl, setEmbeddedSignUrl] = useState<string | undefined>(
    undefined
  );

  return (
    <Form8973Context.Provider
      value={{
        step,
        setStep,
        companyValues,
        setCompanyValues,
        formValues,
        setFormValues,
        embeddedSignUrl,
        setEmbeddedSignUrl,
      }}
    >
      {children}
    </Form8973Context.Provider>
  );
};
