import { ReactElement, useEffect } from "react";
import OverviewImage from "../../images/overview-banner.webp";
import styles from "../../InternationalSolutionsPage.module.css";
import OverviewPriceCard from "pages/international-solutions/components/PriceCard/OverviewPriceCard";
import {
  CUSTOMER_IO_PAGEVIEW_EVENT,
  sendCustomerIOEvent,
} from "app/customer-io";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import { useGetCompanyDisplayQuery } from "types/generated/operations";

const Overview = (): ReactElement => {
  const {
    container,
    column1,
    column2,
    divWrapper,
    lgHead,
    listWrapper,
    xsHead,
    lgGap,
    mdFont,
  } = styles;

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  useEffect(() => {
    // Wait memberData to be available to avoid redundant calls
    if (!memberData?.authenticatedMember) return;

    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_PAGEVIEW_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: { page_url: window.location.href },
      },
      accessToken
    );
  }, [accessToken, memberData]);

  return (
    <div className={container}>
      <div className={column1}>
        <img src={OverviewImage} alt="" />
        <div className={`${divWrapper} ${lgGap}`}>
          <div className={lgHead}>
            Manage your international and domestic teams all in one platform
          </div>
          <div className={mdFont}>
            Easily navigate hiring international employees with a robust HR
            infrastructure built for small businesses that saves time, money,
            and effort. Onboard and manage international employees, all within
            the same platform you’ve come to love.
          </div>
          <div>
            <ul className={listWrapper}>
              <li>
                <div className={xsHead}>
                  Hire international employees with our employer of record (EOR)
                  services
                </div>{" "}
                Get flexibility to hire people from around the world with the
                skills you need to level up your business.
              </li>
              <li>
                <div className={xsHead}>
                  Pay your whole team from one seamless system
                </div>{" "}
                Manage payments for employees and contractors, near and far, all
                in one place.
              </li>
              <li>
                <div className={xsHead}>
                  Get reliable support from our team of international hiring
                  experts
                </div>{" "}
                Count on us to stay on top of local HR regulations so you can
                focus on growing your business.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`${column2}`}>
        <OverviewPriceCard />
      </div>
    </div>
  );
};

export default Overview;
