import FormStepOne from "../FormStepOne/FormStepOne";
import FormStepTwo from "../FormStepTwo/FormStepTwo";
import FormSignDocument from "../FormSignDocument/FormSignDocument";
import FormReviewPage from "../FormReviewPage/FormReviewPage";
import FormConfirmationWrapper from "../FormConfirmation/FormConfirmationWrapper";
import { WORKFLOW_STEPS } from "../../constants";
import { useContext } from "react";
import { Form8973Context } from "../../contexts";

const stepComponents: { [key: string]: React.FC } = {
  [WORKFLOW_STEPS.STEP_ONE]: FormStepOne,
  [WORKFLOW_STEPS.STEP_TWO]: FormStepTwo,
  [WORKFLOW_STEPS.REVIEW_PAGE]: FormReviewPage,
  [WORKFLOW_STEPS.SIGN_DOCUMENT]: FormSignDocument,
  [WORKFLOW_STEPS.CONFIRMATION_SUCCESS]: FormConfirmationWrapper,
  [WORKFLOW_STEPS.CONFIRMATION_RECEIVED]: FormConfirmationWrapper,
  [WORKFLOW_STEPS.CONFIRMATION_PENDING]: FormConfirmationWrapper,
  // Add more mappings for additional steps as needed
};

const FormWorkflowStepper = () => {
  const { step } = useContext(Form8973Context);
  const StepComponent = stepComponents[step];
  return StepComponent && <StepComponent />;
};

export default FormWorkflowStepper;
