import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../Row/Row";
import { useTranslation } from "react-i18next";

export default function GeneralInformationBR() {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();

  const personalInfo = profileInfo?.eorEmployeeProfile.personalInfo;

  const stateOfBirth = personalInfo?.brStateOfBirth;
  const cityOfBirth = personalInfo?.brCityOfBirth;
  const nationality = personalInfo?.nationality;
  const ethnicity = personalInfo?.brEthnicity;
  const workPermitStatus = personalInfo?.brWorkPermitStatus;
  const maritalStatus = personalInfo?.brMaritalStatus;
  const cpf = personalInfo?.identifierNumber;

  return (
    <>
      <Row label={t("State of birth")} value={stateOfBirth} />
      <Row label={t("City of birth")} value={cityOfBirth} />
      <Row label={t("Nationality")} value={nationality} />
      <Row label={t("Ethnicity")} value={ethnicity} />
      <Row label={t("Work permit status")} value={workPermitStatus} />
      <Row label={t("Marital status")} value={maritalStatus} />
      <Row label={t("Cadastro de Pessoas Físicas (CPF)")} value={cpf} />
    </>
  );
}
