import {
  CheckboxInput,
  FormField,
  SingleDatePicker,
  TextInput,
} from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import styles from "../../ProfileInfo.module.css";
import moment from "moment";

export const initialValues = {
  firstName: "",
  lastName: "",
  preferredFirstName: "",
  middleName: "",
  pronouns: "",
  dateOfBirth: "",
  settings: {
    showDateOfBirth: false,
  },
};

const { checkboxField, inputField } = styles;

export type TBaseFormFragmentSchema = Yup.InferType<ReturnType<typeof schema>>;

export const schema = (t: TFunction) =>
  Yup.object({
    firstName: Yup.string().required(
      t("This field is required. Please enter your legal given name.")
    ),
    lastName: Yup.string().required(
      t("This field is required. Please enter your surname.")
    ),
    middleName: Yup.string().optional(),
    preferredFirstName: Yup.string().optional(),
    pronouns: Yup.string().optional(),
    dateOfBirth: Yup.string().required(
      "This field is required. Please enter your Date of birth."
    ),
    settings: Yup.object().shape({
      showDateOfBirth: Yup.boolean(),
    }),
  });

export default function BaseFormFragment() {
  const { t } = useTranslation();
  const { touched, errors, handleBlur, handleChange, values, setFieldValue } =
    useFormikContext<TBaseFormFragmentSchema>();

  const handleDOBChange = (date: Date) => {
    setFieldValue("dateOfBirth", moment(date).format("YYYY-MM-DD"));
  };

  const maxDOB = moment().subtract(18, "years").toDate();
  const minDOB = moment("1920-01-01").toDate();

  return (
    <>
      <div className={inputField}>
        <FormField
          error={touched?.firstName ? errors?.firstName : ""}
          label={t("Legal given name")}
          message={t(
            "This name was pulled from your employment contract with Justworks."
          )}
          required
        >
          <TextInput
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstName}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          error={touched?.lastName ? errors?.lastName : ""}
          label={t("Surname")}
          message={t(
            "This name was pulled from your employment contract with Justworks."
          )}
          required
        >
          <TextInput
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastName}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          label={t("Preferred name")}
          message={t(
            "This is the name you'll see in your company directory and in emails from us."
          )}
        >
          <TextInput
            name="preferredFirstName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.preferredFirstName}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField label={t("Middle name")}>
          <TextInput
            name="middleName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.middleName}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          label={t("Pronouns")}
          message={t(
            "This will be displayed on your public profile page and in your company org chart."
          )}
        >
          <TextInput
            name="pronouns"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={t("e.g. He/him, She/her, They/them")}
            value={values.pronouns}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          error={touched?.dateOfBirth ? errors?.dateOfBirth : ""}
          label={t("Date of birth")}
          message={t(
            "This will be displayed on your public profile page and in your company org chart."
          )}
          required
        >
          <SingleDatePicker
            id="dateOfBirth"
            name="dateOfBirth"
            onBlur={handleBlur}
            onChange={handleDOBChange}
            value={values.dateOfBirth}
            minDate={minDOB}
            maxDate={maxDOB}
          />
        </FormField>

        <div className={checkboxField}>
          <CheckboxInput
            checked={values.settings.showDateOfBirth}
            label="Don't show my birthday on my profile"
            name="settings.showDateOfBirth"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.settings.showDateOfBirth ? "true" : "false"}
          />
        </div>
      </div>
    </>
  );
}
