import { datadogRum } from "@datadog/browser-rum";
import { buildClockworkWebPath } from "lib/resource-finder";

type CustomerIOEventJSON = {
  event_type: string;
  company_uuids: string[];
  member_uuids: string[];
  data: PAGEVIEW_EVENT_DATA | CTA_CLICK_EVENT_DATA;
};

type PAGEVIEW_EVENT_DATA = { page_url: string };
type CTA_CLICK_EVENT_DATA = {
  current_url: string;
  cta_name: string;
  next_url: string;
};

// Send Data to customer IO route based on what application step the user is on
export const sendCustomerIOEvent = async (
  eventData: CustomerIOEventJSON,
  accessToken?: string
) => {
  // We don't care about a response, just send and forget
  const cioUrl = buildClockworkWebPath("/api/v1/customerio/event");
  await fetch(cioUrl, {
    method: "POST",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(eventData),
  })
    .then((response) => {
      if (!response.ok) {
        datadogRum.addError(
          new Error(
            `Failed to send CustomerIO event with status: ${response.status}`
          )
        );
      }
    })
    .catch((error) => {
      datadogRum.addError(error);
    });
};
