import { Badge, IconButton } from "@justworkshr/milo-core";
import invoiceStyles from "../../CombinedInvoice.module.css";
import styles from "./InvoiceSummary.module.css";
import { FC, useContext } from "react";
import { SummaryCategory } from "./components/SummaryCategory";
import { toCurrencyFormat } from "../../../utils";
import { useTranslation } from "react-i18next";
import { CombinedInvoiceContext } from "../../contexts/CombinedInvoiceContext";
import { InvoiceData } from "../../types";
import { toPayPeriodRangeFormat } from "../../CombinedInvoice.utils";

const { padlessGrid, desktopVisible } = invoiceStyles;
const {
  invoiceSummaryContainer,
  invoiceSummaryHeaderRow,
  invoiceSummaryHeader,
  invoiceSummaryTotalContainer,
  invoiceSummaryTotal,
  invoiceSummaryCurrencyBadge,
  breakdownSectionButtonContainer,
} = styles;

interface InvoiceSummaryProps {
  invoice: InvoiceData;
  summaryIndex: number;
}

export const InvoiceSummary: FC<InvoiceSummaryProps> = ({
  invoice,
  summaryIndex,
}) => {
  const { t } = useTranslation();
  const { isBreakdownSectionExpanded, setIsBreakdownSectionExpanded } =
    useContext(CombinedInvoiceContext);
  const breakdownButtonHandler = () => {
    setIsBreakdownSectionExpanded((data) => ({
      ...data,
      [summaryIndex]: !data[summaryIndex],
    }));
  };

  const payPeriodRangeText = toPayPeriodRangeFormat(
    invoice.payPeriodRange.startDate,
    invoice.payPeriodRange.endDate
  );

  return (
    <div className={invoiceSummaryContainer}>
      <div className={padlessGrid} id={`invoiceSummary_${summaryIndex}`}>
        <div className={invoiceSummaryHeaderRow}>
          <div className={breakdownSectionButtonContainer}>
            <IconButton
              size="md"
              color="neutral"
              type="button"
              variant="ghost"
              onClick={breakdownButtonHandler}
              title={
                isBreakdownSectionExpanded[summaryIndex] ? "Collapse" : "Expand"
              }
              iconName={
                isBreakdownSectionExpanded[summaryIndex]
                  ? "chevron-down"
                  : "chevron-right"
              }
            />
          </div>
          <span className={invoiceSummaryHeader}>
            {invoice.currency.description}
          </span>
          <span className={desktopVisible}>
            <Badge role="positive" className={invoiceSummaryCurrencyBadge}>
              {invoice.currency.code}
            </Badge>
          </span>
        </div>
        <div className={invoiceSummaryTotalContainer}>
          <span className={invoiceSummaryHeader}>
            {t("Total")}
            <span className={invoiceSummaryTotal}>
              {toCurrencyFormat(
                Number(invoice.localCurrencyTotal),
                invoice.currency.code,
                true
              )}
            </span>
          </span>
        </div>
      </div>
      {isBreakdownSectionExpanded[summaryIndex] &&
        invoice.categories.map((category) => (
          <SummaryCategory
            category={category}
            currency={invoice.currency.code}
            payPeriodRangeText={payPeriodRangeText}
            key={category.type}
          />
        ))}
    </div>
  );
};
