import { Permission } from "./types";

interface PermissionGroupDetails {
  [key: string]: {
    id: string;
    name: string;
    permissions: {
      [key: string]: Permission;
    };
  };
}

export const permissionGroupDetails: PermissionGroupDetails = {
  settings: {
    id: "settings",
    name: "Settings",
    permissions: {
      grant_permissions: {
        id: "grant_permissions",
        label: "Edit employee permissions",
        groupKey: "settings",
        grantedBy: [],
        capabilities: [
          "View and edit permissions settings for all employees, including your permissions",
          "Grant or remove admin status to any employee",
        ],
      },
      change_company_settings: {
        id: "change_company_settings",
        label: "Edit company settings",
        groupKey: "settings",
        grantedBy: [],
        capabilities: [
          "View and edit all company settings - offices, departments, company bank account and address",
        ],
      },
    },
  },
  payments: {
    id: "payments",
    name: "Payments",
    permissions: {
      expenses: {
        id: "expenses",
        label: "Manage expenses",
        groupKey: "payments",
        grantedBy: [],
        capabilities: [
          "Make final approval for reimbursement requests.",
          "Return or edit all employee reimbursement requests.",
          "Be notified of reimbursement requests.",
          "View existing and past reimbursement requests for all employees.",
          "View and edit expense settings.",
        ],
      },
      make_payments: {
        id: "make_payments",
        label: "Make payments",
        groupKey: "payments",
        grantedBy: ["expenses"],
        capabilities: [
          "Make payments of any kind, including bonuses and vendor payments",
          "View and edit all payments",
          "Cancel any and all payments",
          "Pay hourly employees",
          "View invoices and reporting, including sensitive information such as SSNs",
          "Invite new vendors and contractors to Justworks",
          "View basic employee information, including salary",
        ],
      },
      timecards: {
        id: "timecards",
        label: "Manage all timecards",
        groupKey: "payments",
        grantedBy: ["make_payments", "expenses"],
        capabilities: [
          "Complete, submit, and approve timecards for all employees using timecards.",
          "Enable and disable employee timecard entry.",
          "Import hours for all employees who are paid through timecards in bulk.",
        ],
      },
      pay_contractors_vendors: {
        id: "pay_contractors_vendors",
        label: "Pay contractors & vendors",
        groupKey: "payments",
        grantedBy: ["make_payments", "expenses"],
        capabilities: [
          "Make and edit payments for contractors (1099) and vendors.",
        ],
      },
      pay_metrics: {
        id: "pay_metrics",
        label: "View invoices & reporting",
        groupKey: "payments",
        grantedBy: ["make_payments", "expenses"],
        capabilities: [
          "View upcoming payments and past invoices",
          "Access reporting features, such as the payroll report and spending by department",
          "View sensitive information like SSNs which may appear on certain reports",
          "View calendar with company-wide payments",
          "View and access reporting within Expenses",
        ],
      },
    },
  },
  company_hr: {
    id: "company_hr",
    name: "Company HR",
    permissions: {
      change_employees: {
        id: "change_employees",
        label: "Manage employees",
        groupKey: "company_hr",
        grantedBy: [],
        capabilities: [
          "Invite new users to Justworks, including vendors and contractors.",
          "Complete I-9s for all employees.",
          "View all employee information",
          "Assign and manage trainings",
          "Set up HR Integrations",
        ],
      },
      employee_info_full: {
        id: "employee_info_full",
        label: "View all employee information",
        groupKey: "company_hr",
        grantedBy: ["change_employees"],
        capabilities: [
          "View all employees’, contractors’ and vendors’ sensitive info, such as pay rate and bank account information",
          "View basic employee information",
          "Assign and manage trainings",
        ],
      },
      employee_info_limited: {
        id: "employee_info_limited",
        label: "View basic employee information",
        groupKey: "company_hr",
        grantedBy: [
          "change_employees",
          "employee_info_full",
          "make_payments",
          "expenses",
        ],
        capabilities: [
          "View all employees’, contractors’, and vendors’ non-sensitive info such as PTO requests and emergency contact info.",
          "Assign and manage trainings",
        ],
      },
      admin_pto: {
        id: "admin_pto",
        label: "Manage all paid time off",
        groupKey: "company_hr",
        grantedBy: [],
        capabilities: [
          "View, edit and/or remove all existing paid time off policies",
          "Create new paid time off policies",
          "Create paid time off adjustments",
          "Approve or deny all paid time off requests",
          "Setup and edit holiday calendar",
        ],
      },
      doc_center_admin: {
        id: "doc_center_admin",
        label: "Upload & view all documents",
        groupKey: "company_hr",
        grantedBy: [],
        capabilities: [
          "View all documents for all employees and across the company.",
          "Upload new documents for any employee or for the company.",
          "Edit and delete documents for an inidual.",
          "Complete I-9s.",
        ],
      },
    },
  },
  benefits: {
    id: "benefits",
    name: "Benefits",
    permissions: {
      benefits: {
        id: "benefits",
        label: "Manage company benefits",
        groupKey: "benefits",
        grantedBy: [],
        capabilities: [
          "View all company-wide benefits",
          "View all employees' benefits information.",
          "Enroll the company in new benefits. ",
        ],
      },
    },
  },
};
