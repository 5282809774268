import styles from "./FormReviewPage.module.css";
import { Formik, Form } from "formik";
import { Form8973Context } from "../../contexts";
import { useContext, useState, useEffect } from "react";
import { Footer } from "../Footer/Footer";
import FormHeader from "../FormHeader/FormHeader";
import { WORKFLOW_STEPS } from "../../constants";
import ReviewComparison from "../ReviewComparison/ReviewComparison";
import { useProcessForm8973AnswersMutation } from "types/generated/operations";
import { formatFileData } from "../../utils";
import { Spinner, Alert } from "@justworkshr/milo-core";

const {
  formStepContentContainer,
  formStepContent,
  reviewInfo,
  hiddenContent,
  spinner,
} = styles;

const ErrorAlert = ({ error }: { error: string | null }) => {
  return <Alert color="destructive">{error}</Alert>;
};

const LoadingSpinner = () => {
  return (
    <div className={spinner}>
      <Spinner />
    </div>
  );
};

const FormReviewPageContent = () => (
  <>
    <FormHeader />
    <div className={formStepContentContainer}>
      <div className={formStepContent}>
        <h2 className={reviewInfo}>Review the changes</h2>
        <ReviewComparison />
      </div>
    </div>
  </>
);

const FormReviewPage: React.FC = () => {
  const { companyValues, formValues, setStep, setEmbeddedSignUrl } =
    useContext(Form8973Context);
  const [error, setError] = useState<string | null>(null);
  const [processAnswers, { loading, error: mutationError }] =
    useProcessForm8973AnswersMutation();

  useEffect(() => {
    if (mutationError) {
      setError(mutationError.message);
    }
  }, [mutationError]);

  const handleFormSubmit = async () => {
    setError(null);
    try {
      const formattedEinSupportingDocument = await formatFileData(
        formValues.einSupportingDocument
      );
      const formattedLegalEntityNameSupportingDocument = await formatFileData(
        formValues.legalEntityNameSupportingDocument
      );

      const response = await processAnswers({
        variables: {
          companyUuid: companyValues.uuid,
          form8973Answers: {
            ...formValues,
            einSupportingDocument: formattedEinSupportingDocument,
            legalEntityNameSupportingDocument:
              formattedLegalEntityNameSupportingDocument,
          },
        },
      });
      const processData = response?.data?.processForm8973Answers;
      if (processData?.success && processData.embeddedSignUrl) {
        setEmbeddedSignUrl(processData.embeddedSignUrl);
        setStep(WORKFLOW_STEPS.SIGN_DOCUMENT);
      } else if (processData?.error) {
        setError(processData.error.join(", "));
      }
    } catch {
      setError("There was an error processing the form. Please try again.");
    }
  };

  return (
    <Formik initialValues={formValues} onSubmit={handleFormSubmit}>
      {() => (
        <Form>
          {loading && <LoadingSpinner />}
          {error && <ErrorAlert error={error} />}
          <div className={loading || error ? hiddenContent : ""}>
            <FormReviewPageContent />
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormReviewPage;
