import { useContext } from "react";
import { Formik, Form } from "formik";
import { Form8973Context } from "../../contexts";
import {
  WORKFLOW_STEPS,
  GENERIC_RADIO_OPTIONS,
  EIN_CHANGE_REASON_OPTIONS,
  LEGAL_ENTITY_NAME_CHANGE_REASON_OPTIONS,
  ERM_14_FORM_LINK,
} from "../../constants";
import { stepOneValidationSchema as getValidationSchema } from "../validationSchema";
import { filterFormValues } from "../../utils";
import { Footer } from "../Footer/Footer";
import FormHeader from "../FormHeader/FormHeader";
import RenderInput from "../RenderInput/RenderInput";
import RenderAlert from "../RenderAlert/RenderAlert";
import { FormikFormValuesType } from "../../types";
import styles from "./FormStepOne.module.css";

const { formStepContent, reviewInfo, formStepContentContainer } = styles;

const getFieldsToSave = (
  values: FormikFormValuesType
): FormikFormValuesType => {
  const updatedValues = { ...filterFormValues(values) };

  // Clear EIN fields
  if (values.einValid === "true") {
    updatedValues.einChangeReason = "";
    updatedValues.correctedEin = "";
    updatedValues.einSupportingDocument = null;
  }

  if (values.einChangeReason === "einChanged") {
    updatedValues.correctedEin = "";
    updatedValues.einSupportingDocument = null;
  }

  // Clear Legal Entity Name fields
  if (values.legalEntityNameValid === "true") {
    updatedValues.legalEntityNameChangeReason = "";
    updatedValues.correctedLegalName = "";
    updatedValues.legalEntityNameSupportingDocument = null;
  }

  if (values.legalEntityNameChangeReason === "legalEntityNameChanged") {
    updatedValues.correctedLegalName = "";
    updatedValues.legalEntityNameSupportingDocument = null;
  }

  return updatedValues;
};

const FormStepOne: React.FC = () => {
  const { setStep, formValues, setFormValues, companyValues } =
    useContext(Form8973Context);

  return (
    <Formik
      initialValues={formValues}
      validationSchema={getValidationSchema}
      onSubmit={(values) => {
        const updatedValues = getFieldsToSave(values);

        // Update form vales and move to the next step
        setFormValues(updatedValues);
        setStep(WORKFLOW_STEPS.STEP_TWO);
      }}
    >
      {({ values }) => (
        <Form>
          <FormHeader />
          <div className={formStepContentContainer}>
            <div className={formStepContent}>
              <h2 className={reviewInfo}>
                Let’s review the info we have on file for your company:
              </h2>
              <RenderInput
                name="einValid"
                label={`Is your company EIN ${companyValues.ein}?`}
                type="radio"
                options={GENERIC_RADIO_OPTIONS}
                required
              />
              {values.einValid === "false" && (
                <>
                  <RenderInput
                    name="einChangeReason"
                    label="Why do you need to make a change?"
                    type="select"
                    options={EIN_CHANGE_REASON_OPTIONS}
                    placeholder="Select..."
                    required
                  />
                  {values.einChangeReason === "einClericalError" && (
                    <>
                      <RenderInput
                        name="correctedEin"
                        label="Enter the correct EIN"
                        type="text"
                        placeholder="123456789"
                        required
                      />
                      <RenderInput
                        name="einSupportingDocument"
                        textAbove="Upload supporting documents"
                        type="file"
                        label="Upload one of the following documents: Form SS-4 Application for Employer Identification Number or any IRS document that shows the correct EIN."
                        textBelow="We can accept JPEG, PNG, or PDF up to 10 MB each."
                        required
                      />
                    </>
                  )}
                  {values.einChangeReason === "einChanged" && (
                    <RenderAlert
                      headline="Additional documentation needed"
                      message={
                        <>
                          To make these updates,{" "}
                          <a
                            href={ERM_14_FORM_LINK}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            download the ERM-14 form
                          </a>{" "}
                          and then contact your onboarding manager.
                        </>
                      }
                    />
                  )}
                </>
              )}
              <RenderInput
                name="legalEntityNameValid"
                label={`Is your company's legal entity name ${companyValues.legalEntityName}?`}
                type="radio"
                options={GENERIC_RADIO_OPTIONS}
                required
              />
              {values.legalEntityNameValid === "false" && (
                <>
                  <RenderInput
                    name="legalEntityNameChangeReason"
                    label="Why do you need to make a change?"
                    type="select"
                    options={LEGAL_ENTITY_NAME_CHANGE_REASON_OPTIONS}
                    placeholder="Select..."
                    required
                  />
                  {values.legalEntityNameChangeReason ===
                    "legalEntityNameClericalError" && (
                    <>
                      <RenderInput
                        name="correctedLegalName"
                        label="Enter the correct legal entity name"
                        type="text"
                        required
                      />
                      <RenderInput
                        name="legalEntityNameSupportingDocument"
                        textAbove="Upload supporting documents"
                        label="Upload one of the following documents: an EIN assignment letter or any IRS document that shows the correct legal entity name."
                        textBelow="We can accept JPEG, PNG, or PDF up to 10 MB each."
                        type="file"
                        required
                      />
                    </>
                  )}
                  {values.legalEntityNameChangeReason ===
                    "legalEntityNameChanged" && (
                    <RenderAlert
                      headline="Contact your onboarding manager"
                      message={
                        <>
                          You can use the same{" "}
                          <a
                            href={ERM_14_FORM_LINK}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            ERM-14 form
                          </a>{" "}
                          to update your company EIN and legal entity name at
                          the same time.
                        </>
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormStepOne;
