import { ReactElement, useState } from "react";
import { ActionFooter } from "@justworkshr/milo-form";
import { Button } from "@justworkshr/milo-core";
import { Formik, FormikProps } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import { microdepositVerifyFormSchema } from "pages/company-settings/utils/microdepositVerifyFormSchema";
import styles from "pages/company-settings/components/EditBankAccountForm.module.css";
import DecoratedTextInput from "pages/company-settings/components/DecoratedTextInput";
import { useVerifyMicrodeposit } from "pages/company-settings/hooks/useVerifyMicrodeposit";
import { Link } from "react-router-dom";

const { form, formFieldWrapper, errorNotice, errorNoticeDetails, footer } =
  styles;

type ProblemDetailsErrorBody = {
  detail: string;
  errors?: [string];
  type: string;
  status: number;
  title: string;
  is_blocking?: boolean;
};

interface ProblemDetailsError extends Error {
  status?: number;
  body: ProblemDetailsErrorBody;
}

interface MicrodepositVerifyFormProps {
  onError: (error: ProblemDetailsError | null) => void;
}

interface MicrodepositVerifyFormValues {
  deposit1: string;
  deposit2: string;
}

const microdepositVerifyFormFormInitialValues = {
  deposit1: "",
  deposit2: "",
};

export default function MicrodepositVerifyForm({
  onError,
}: MicrodepositVerifyFormProps): ReactElement {
  const { mutate, isPending } = useVerifyMicrodeposit();

  const [formError, setFormError] = useState<ProblemDetailsError | null>(null);

  const hasBlockingError = formError ? formError.body.is_blocking : false;

  const onSubmit = (values: MicrodepositVerifyFormValues) => {
    mutate(values, {
      onError: (err: Error) => {
        const problemDetailsError = err as ProblemDetailsError;
        if (
          problemDetailsError.body.status >= 400 &&
          problemDetailsError.body.status < 500
        ) {
          setFormError(problemDetailsError);
        } else {
          onError(problemDetailsError);
        }
      },
    });
  };

  if (formError && formError.body.type === "bank_account_not_found_error") {
    return (
      <div className={errorNotice}>
        <p className={errorNoticeDetails}>
          The penny test has expired. Please{" "}
          <Link to="/company-settings/edit-bank-account">
            add your bank account
          </Link>
          .
        </p>
      </div>
    );
  }

  const getErrorMessage = (formError: ProblemDetailsError) => {
    if (formError.body.errors && formError.body.errors.length > 0) {
      return formError.body.errors[0];
    }
    return formError.body.detail;
  };

  return (
    <Formik
      initialValues={microdepositVerifyFormFormInitialValues}
      validationSchema={microdepositVerifyFormSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isValid,
        dirty,
      }: FormikProps<MicrodepositVerifyFormValues>) => (
        <>
          {formError && (
            <div className={errorNotice}>
              <p className={errorNoticeDetails}>{getErrorMessage(formError)}</p>
            </div>
          )}
          <div className={form}>
            <div className={formFieldWrapper}>
              <FormikFormField label="Deposit 1" name="deposit1" required>
                <DecoratedTextInput
                  disabled={hasBlockingError}
                  prefix="$0."
                  type="text"
                  name="deposit1"
                  placeholder={"03"}
                />
              </FormikFormField>
            </div>
            <div className={formFieldWrapper}>
              <FormikFormField label="Deposit 2" name="deposit2" required>
                <DecoratedTextInput
                  disabled={hasBlockingError}
                  prefix="$0."
                  type="text"
                  name="deposit2"
                  placeholder={"89"}
                />
              </FormikFormField>
            </div>
          </div>

          <ActionFooter
            className={footer}
            actions={[
              <Button
                data-testid="submit-button"
                type="submit"
                disabled={!isValid || !dirty || hasBlockingError}
                onClick={handleSubmit}
                loading={isPending}
                key="save-button"
              >
                Verify
              </Button>,
            ]}
          />
        </>
      )}
    </Formik>
  );
}
