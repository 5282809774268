import {
  useFirmsPageQuery,
  useGetCompanyDisplayQuery,
} from "types/generated/operations";

export function useFirmsPageQueries() {
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useGetCompanyDisplayQuery();

  const companyUuid = companyData?.authenticatedMember?.company?.uuid ?? "";

  const {
    data: firmData,
    loading: firmLoading,
    error: firmError,
  } = useFirmsPageQuery({
    skip: companyLoading || !!companyError,
    variables: {
      companyUuid: companyUuid,
    },
  });

  return {
    data: { ...firmData, companyUuid },
    loading: companyLoading || firmLoading,
    error: companyError || firmError,
  };
}
