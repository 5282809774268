import React, { ChangeEvent, useMemo } from "react";
import { Select, SelectOption } from "@justworkshr/milo-form";
import styles from "./card-category-select.module.css";
import { IntegrationCardProps } from "../IntegrationCard/integration-card";

const { cardCategorySelect } = styles;
export const AllIntegrationCategories = "All categories";

type CardCategorySelectProps = {
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  cards: IntegrationCardProps[];
};

const CardCategorySelect: React.FC<CardCategorySelectProps> = ({
  cards,
  onChange,
}): JSX.Element | null => {
  // array of unique alphabetical categories from cards
  const options = useMemo(() => {
    const categories = new Set<string>();
    cards.forEach((card) => {
      categories.add(card.integrationCategory);
    });
    return [AllIntegrationCategories, ...Array.from(categories).sort()];
  }, [cards]);

  return (
    <div className={cardCategorySelect}>
      <Select
        defaultValue={options[0]}
        onChange={onChange}
        disabled={options.length === 1}
      >
        {options.map((option) => (
          <SelectOption key={option} value={option}>
            {option}
          </SelectOption>
        ))}
      </Select>
    </div>
  );
};

export default CardCategorySelect;
