import {
  createContext,
  FC,
  ReactNode,
  useReducer,
  Dispatch,
  Reducer,
  useContext,
} from "react";
import { AdditionalPaySteps } from "../types";
import {
  FormPayloadType,
  PaymentDetailsType,
  SetupStepData,
} from "../components/Setup/types";
import { PAY_FORM_INITIAL_VALUES } from "../components/Setup/constants";
import { EmployeePayPayload } from "../components/Setup/components/EmployeePay/types";
import { ReimbursementPayloadType } from "../components/Setup/components/Reimbursement";
import { BenefitsAndAllowancesPayloadType } from "../components/Setup/components/TaxableBenefitsAndAllowances";

type FormData = {
  setup:
    | SetupStepData<EmployeePayPayload>
    | SetupStepData<ReimbursementPayloadType>
    | SetupStepData<BenefitsAndAllowancesPayloadType>;
  paymentDetails: Array<PaymentDetailsType>;
};

type Action = {
  type: AdditionalPaySteps;
  payload: SetupStepData<FormPayloadType> | Array<PaymentDetailsType>;
};

type StepperContextType = {
  formData: FormData;
  dispatch: Dispatch<Action>;
};

// Define reducer function
const formReducer: Reducer<FormData, Action> = (state, action) => {
  switch (action.type) {
    case "set-up":
      const setupPayload = action.payload as
        | SetupStepData<EmployeePayPayload>
        | SetupStepData<ReimbursementPayloadType>
        | SetupStepData<BenefitsAndAllowancesPayloadType>;
      return { ...state, setup: setupPayload };
    case "payment-details":
      const payload = action.payload as Array<PaymentDetailsType>;
      return {
        ...state,
        paymentDetails: payload,
      };
    default:
      return state;
  }
};

// Define context and provider

const StepperContext = createContext<StepperContextType | null>(null);

export const StepperProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [formData, dispatch] = useReducer(formReducer, {
    setup: PAY_FORM_INITIAL_VALUES.setUp,
    paymentDetails: PAY_FORM_INITIAL_VALUES.paymentDetails,
  });

  return (
    <StepperContext.Provider value={{ formData, dispatch }}>
      {children}
    </StepperContext.Provider>
  );
};

export const useStepperContext = () => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error("useStepperContext must be used within a StepperProvider");
  }
  return context;
};
