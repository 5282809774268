import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import heroImage from "../../images/hero-images/trainual-hero.svg";
import logo from "../../images/integration-partner-logos/trainual-logo.svg";
import horizontalLogo from "../../images/horizontal-logos/trainual-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  justworksPermissionsCopy,
  partnerPermissionsCopy,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import ExternalLinkButton from "../../partner-description-page/components/InstanceDetailsPanel/ExternalLinkButton/ExternalLinkButton";
import React from "react";

export const trainualConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: logo,
      integrationName: "Trainual",
      integrationDescription:
        "Automatically sync employee information from Justworks to Trainual",
      integrationCategory: "Learning",
      requiredPermissions: HR_PERMISSIONS,
      slug: "finch__trainual_v1",
      pdpUrl: "/cf/integrations/finch__trainual_v1",
    },
    pdp: {
      headerDescription:
        "Trainual is an onboarding and training platform that helps businesses streamline their processes by documenting every role, responsibility, and procedure in one easy-to-use system.",
      heroImageUrl: heroImage,
      horizontalLogoUrl: horizontalLogo,
      instanceDetailsPanelConfig: {
        hideLastActive: true,
        customDisconnectButton: (
          <ExternalLinkButton
            url="https://trainual.com/integrations/justworks"
            copy="Disconnect Integration"
            variant="outlined"
          />
        ),
        customSetupButton: (
          <ExternalLinkButton
            url="https://trainual.com/integrations/justworks"
            copy="Get Started"
          />
        ),
      },
      resourceCard: {
        links: [
          {
            title: "Trainual in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/29603940566811-Trainual-Integration",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about Trainual",
            href: "https://trainual.com/",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "Trainual + Justworks",
          subheader:
            "Streamline employee onboarding with the Trainual + Justworks integration. Justworks automatically adds employees to your Trainual account so all you have to do is assign the content your team needs to know, and they’ll get up to speed all on their own.",
          list: [
            {
              summary: "Faster new hire onboarding",
              detail:
                "Automatically assign your team the right training on day one.",
            },
            {
              summary: "Automated org chart updates",
              detail:
                "Your team is constantly changing. Spend less time managing user seats with data that syncs daily from Justworks to Trainual.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What member data will we share with Trainual?",
            subheader:
              "While the integration is connected, Trainual will automatically pull in the following data from Justworks for your US-based team members:",
            sharedDataGroups: [
              {
                sharedData: [
                  "First Name",
                  "Last Name",
                  "Email",
                  "Phone Number",
                  "Job Title",
                  "Manager",
                ],
              },
            ],
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In Trainual",
                detail: partnerPermissionsCopy("Trainual", [
                  "Admin or Billing Admin",
                ]),
              },
            ],
          },
        },
      },
      disconnectModalContent: {
        title: "Disconnect from Trainual",
        body: <span></span>,
        disconnectButtonText: "Disconnect",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/sections/360001292171-Account-Administration",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
