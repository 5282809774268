import ReviewBox from "../ReviewBox/ReviewBox";
import { useContext } from "react";
import { Form8973Context } from "../../contexts";
import { createAddress } from "../../utils";
import { CompanyValuesType, FormikFormValuesType } from "../../types";
import styles from "./ReviewComparison.module.css";

const { reviewComparison } = styles;

interface DataItem {
  label: string;
  value: string | undefined;
  reason?: string;
  document?: string;
}

const generateReviewData = (
  formValues: FormikFormValuesType,
  companyValues: CompanyValuesType
): { originalData: DataItem[]; changedData: DataItem[] } => {
  const originalData: DataItem[] = [];
  const changedData: DataItem[] = [];

  if (formValues.einValid === "false") {
    originalData.push({
      label: "EIN",
      value: companyValues.ein,
    });
    changedData.push({
      label: "EIN",
      reason: formValues.einChangeReason,
      value:
        formValues.einChangeReason === "einClericalError"
          ? formValues.correctedEin
          : undefined,
      document:
        formValues.einSupportingDocument instanceof File
          ? formValues.einSupportingDocument.name
          : undefined,
    });
  }

  if (formValues.legalEntityNameValid === "false") {
    originalData.push({
      label: "Legal Entity Name",
      value: companyValues.legalEntityName,
    });
    changedData.push({
      label: "Legal Entity Name",
      reason: formValues.legalEntityNameChangeReason,
      value:
        formValues.legalEntityNameChangeReason ===
        "legalEntityNameClericalError"
          ? formValues.correctedLegalName
          : undefined,
      document:
        formValues.legalEntityNameSupportingDocument instanceof File
          ? formValues.legalEntityNameSupportingDocument.name
          : undefined,
    });
  }

  if (formValues.tradeNameValid === "false") {
    originalData.push({
      label: "Trade Name",
      value: companyValues.tradeName,
    });
    changedData.push({
      label: "Trade Name",
      value: formValues.correctedTradeName,
    });
  }

  if (formValues.addressValid !== "true") {
    originalData.push({
      label: "Address",
      value: createAddress(companyValues),
    });
    changedData.push({
      label: "Address",
      value: createAddress({
        ...formValues,
      } as Record<string, string>),
    });
  }

  return { originalData, changedData };
};

const ReviewComparison = () => {
  const { formValues, companyValues } = useContext(Form8973Context);
  const { originalData, changedData } = generateReviewData(
    formValues,
    companyValues
  );

  return (
    <div className={reviewComparison}>
      <ReviewBox title="Original" data={originalData} />
      <ReviewBox title="Changed" data={changedData} />
    </div>
  );
};

export default ReviewComparison;
