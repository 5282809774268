import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { loadCountryConfigFiles } from "../countryConfigLoader";
import { addDisbursementDate } from "pages/employer-of-record/payments/utils";
import { AdditionalPayContextProps } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { FieldConfig } from "../../SetupFormGenerator";
import { CountryConfigKeyType } from "../types";
import { useFormikContext } from "formik";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../../../types";
import type { EorMembersType } from "pages/employer-of-record/payments/types";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { DecoratedMessageWithLinkType } from "pages/employer-of-record/components/MessageWithLink/types";

const useCountryConfigFetcher = (
  additionalPayContext: AdditionalPayContextProps | null,
  setPaymentSettingsFields: Dispatch<SetStateAction<FieldConfig[]>>,
  setExpenseDetailsFields: Dispatch<SetStateAction<FieldConfig[]>>,
  setExpenseTypes: Dispatch<SetStateAction<string[]>>,
  setMessage: Dispatch<SetStateAction<DecoratedMessageWithLinkType | null>>
) => {
  const { getDefaultCurrency } = useContext(SupportedCountriesContext);
  const selectedCountry = additionalPayContext?.selectedCountry;
  const membersData = additionalPayContext?.membersData;

  const { setFieldValue, values } =
    useFormikContext<AdditionalPayFormData<FormPayloadType>>();

  useEffect(() => {
    if (selectedCountry) {
      const fetchCountryConfigs = () => {
        const configs = loadCountryConfigFiles(
          selectedCountry as CountryConfigKeyType
        );
        let paymentSettingsFormFields = configs.PAYMENT_SETTINGS_FIELDS;

        if (additionalPayContext?.disbursementDateOptions) {
          paymentSettingsFormFields = addDisbursementDate(
            paymentSettingsFormFields,
            Object.values(additionalPayContext?.disbursementDateOptions)
          );
        }

        configs.EXPENSE_DETAILS_FIELDS.map((val) => {
          if (val.name === "distance") {
            val.renderCheck = () => {
              return (
                configs.ReimbursementType.MILEAGE === values.setUp.expenseType
              );
            };
          }
        });

        setPaymentSettingsFields(paymentSettingsFormFields);
        setExpenseDetailsFields(configs.EXPENSE_DETAILS_FIELDS);
        setExpenseTypes(configs.EXPENSETYPES);

        const defaultMessage = configs.ReimbursementTypeMessage?.[""] || null;
        const message =
          configs.ReimbursementTypeMessage?.[values.setUp.expenseType || ""] ||
          defaultMessage;
        setMessage(message);
      };
      fetchCountryConfigs();
    }
  }, [
    selectedCountry,
    additionalPayContext?.disbursementDateOptions,
    values.setUp.expenseType,
    setExpenseDetailsFields,
    setExpenseTypes,
    setPaymentSettingsFields,
    setMessage,
  ]);

  useEffect(() => {
    const formikMemberDetails: Array<PaymentDetailsType> = [];
    const validationMemberData: Array<
      Pick<EorMembersType, "id" | "workStartDate" | "workEndDate">
    > = [];
    membersData?.forEach((data: EorMembersType) => {
      const memberFormData = {
        memberId: data.id,
        grossAmount: "",
        currency: getDefaultCurrency(selectedCountry),
        workPeriodStart: "",
        workPeriodEnd: "",
      };
      formikMemberDetails.push(memberFormData);
      validationMemberData.push({
        id: data.id,
        workStartDate: data.workStartDate,
        workEndDate: data.workEndDate,
      });
    });

    setFieldValue("paymentDetails", formikMemberDetails);
    setFieldValue("validationData.members", validationMemberData);
  }, [
    membersData,
    setFieldValue,
    selectedCountry,
    values.paymentDetails.length,
    getDefaultCurrency,
  ]);
};

export default useCountryConfigFetcher;
