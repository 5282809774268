import { Alert, PageHeader, Spinner } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import styles from "./EditStartDatePage.module.css";
import { useProfileContextData } from "../contexts/ProfileInfoContext";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { useState } from "react";
import EditStartDate from "../components/EditStartDate/EditStartDate";
import { Schema } from "../components/EditStartDate/components/StartDateField";
import { EOR_PROFILE_PATH } from "../../routes";
import { SUBPATHS } from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSubmitCompensationChangeRequestMutation } from "types/generated/operations";
import { getEmployeeDisplayName } from "../utils";
import BackToProfile from "../components/EditableProfile/components/BackToProfile";

export default function EditStartDatePage() {
  const navigate = useNavigate();
  const { getFeatureFlag } = useGetFeatureFlag();
  const [submitChangeRequest] = useSubmitCompensationChangeRequestMutation();
  const { t } = useTranslation();
  const { profileInfo, loadingProfileInfo } = useProfileContextData();
  const isEditingEnabled = getFeatureFlag("release-change-eor-ee-start-date");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { id } = useParams();

  if (!isEditingEnabled) return <div>Forbidden</div>;
  if (profileInfo === undefined || loadingProfileInfo) {
    return (
      <div className={styles.center}>
        <Spinner />
      </div>
    );
  }

  const startDate = profileInfo?.eorEmployeeProfile.contract?.startDate;
  const countryCode = profileInfo?.eorEmployeeProfile.employment?.workCountry;
  const memberId = profileInfo.eorEmployeeProfile.employment?.memberId;
  const employmentId = profileInfo.eorEmployeeProfile.employment?.id;

  if (!startDate || !countryCode) return <div>Error</div>; //this should never happen

  const name = getEmployeeDisplayName(
    profileInfo.eorEmployeeProfile.personalInfo
  );
  const title = t("Change start date for ") + name;

  async function onSubmit(values: Schema) {
    if (!memberId || !employmentId) {
      throw new Error(`No memberId or employmentId`);
    }

    try {
      await submitChangeRequest({
        variables: {
          memberId,
          employmentId,
          changeRequest: {
            startDate: values.startDate,
          },
        },
      });

      navigateToProfile();
    } catch (e) {
      console.error(e);
      setErrorMessage(t("An unknown error occurred. Please try again later."));
    }
  }

  function navigateToProfile() {
    const successMessage = encodeURIComponent(
      t(
        `Request received! We’ll review ${name}’s contract details and get back to you soon.`
      )
    );

    navigate(
      `/employer-of-record${EOR_PROFILE_PATH}/${id}/${SUBPATHS.PERSONAL_INFO}?updated=${successMessage}`
    );
  }

  return (
    <div className={styles.container}>
      {errorMessage && <Alert color="destructive">{errorMessage}</Alert>}
      <PageHeader title={title} linkPrevious={<BackToProfile />} />
      <EditStartDate
        countryCode={countryCode}
        startDate={startDate}
        onSubmit={onSubmit}
      />
    </div>
  );
}
