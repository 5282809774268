import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps } from "formik";
import omit from "lodash/omit";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { stdnum } from "stdnum";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import { NATIONALITIES } from "../../constants";
import styles from "../../ProfileInfo.module.css";
import { compactTaxId } from "../../taxIdUtils";
import BaseFormFragment, {
  initialValues as BASE_INITIAL_VALUES,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as IDENTIFIER_NUMBER_INITIAL_VALUES,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
import {
  mxCivilStatusOptions,
  mxPersonalIdRegEx,
  mxSexOptions,
} from "./constants.mx";
const { inputField } = styles;

const state_key = "profile-info";

const ProfileInfoMX: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const countrySchema = Yup.object({
    nationality: Yup.string().required("This field is required."),
    mxSex: Yup.string().required("This field is required."),
    mxCivilStatus: Yup.string().required("This field is required."),
    mxPersonalId: Yup.string()
      .required("This field is required.")
      .matches(mxPersonalIdRegEx, t("Must be a valid Personal ID.")),
    mxNationalId: Yup.string()
      .required("This field is required.")
      .test(
        "match-curp-regex",
        t("Must be a valid National ID."),
        (value) => stdnum.MX.curp.validate(value).isValid
      ),
    mxWorkPermitId: Yup.string()
      .test("check-nationality", "This field is required.", (value, ctx) =>
        ctx.parent.nationality === "MX" ? true : Boolean(value?.length)
      )
      .length(13, t("Must be a valid Work Permit ID.")),
    mxSocialSecurityNumber: Yup.string()
      .required("This field is required. ")
      .length(11, t("Must be a valid Social Security Number.")),
    mxHasInfonavitLoan: Yup.boolean().required("This field is required."),
    mxHasFonacotLoan: Yup.boolean().required("This field is required. "),
  });

  const validationSchema = countrySchema
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "MX"));

  type CountrySchema = Yup.InferType<typeof countrySchema>;
  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const INITIAL_VALUES: CountrySchema = {
    nationality: "",
    mxSex: "",
    mxCivilStatus: "",
    mxPersonalId: "",
    mxNationalId: "",
    mxWorkPermitId: "",
    mxSocialSecurityNumber: "",
    mxHasInfonavitLoan: false,
    mxHasFonacotLoan: false,
  } as const;

  const PRIVATE_KEYS: readonly (keyof CountrySchema)[] = [
    "mxNationalId",
    "mxPersonalId",
    "mxSocialSecurityNumber",
    "mxWorkPermitId",
  ];

  const DEFAULT_VALUES = {
    ...BASE_INITIAL_VALUES,
    ...IDENTIFIER_NUMBER_INITIAL_VALUES,
    ...INITIAL_VALUES,
  } as const;

  const getInitialValues = (): ValidationSchema => {
    if (!profileInfo?.profileInfo) return DEFAULT_VALUES;

    const sanitizedValues = omit(profileInfo?.profileInfo, PRIVATE_KEYS);

    return {
      ...DEFAULT_VALUES,
      ...sanitizedValues,
    };
  };

  const onFormSubmit = (values: ValidationSchema) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        identifierNumber: profileInfoIdentifierNumber,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            values,
          }: FormikProps<ValidationSchema>) => {
            const handleBooleanChange = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              setFieldValue(e.target.name, e.target.value === "true");
            };

            const fieldErrorHandler = (name: keyof ValidationSchema) => {
              if (!errors || !touched) return;
              if (errors[name] && touched[name]) {
                return errors[name]?.toString();
              }
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* <!-- MX specific fields --> */}
                  <div className={inputField}>
                    <FormField
                      required
                      name="nationality"
                      label={t("Nationality")}
                      error={fieldErrorHandler("nationality")}
                    >
                      <Select
                        name="nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.nationality)}
                        placeholder={
                          values.nationality.length
                            ? undefined
                            : t("Select Nationality")
                        }
                      >
                        {NATIONALITIES.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="mxSex"
                      label={t("Sex")}
                      error={fieldErrorHandler("mxSex")}
                      message={t(
                        "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences."
                      )}
                    >
                      <Select
                        name="mxSex"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.mxSex)}
                        placeholder={
                          values.mxSex.length ? undefined : t("Select Sex")
                        }
                      >
                        {mxSexOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="mxCivilStatus"
                      label={t("Civil Status")}
                      error={fieldErrorHandler("mxCivilStatus")}
                    >
                      <Select
                        name="mxCivilStatus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.mxCivilStatus)}
                        placeholder={
                          values.mxCivilStatus.length
                            ? undefined
                            : t("Select Civil Status")
                        }
                      >
                        {mxCivilStatusOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="mxPersonalId"
                      label={t("ID Number (INE/Passport)")}
                      error={fieldErrorHandler("mxPersonalId")}
                    >
                      <TextInput
                        name="mxPersonalId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.mxPersonalId)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="mxNationalId"
                      label={t("National ID Number (CURP)")}
                      error={fieldErrorHandler("mxNationalId")}
                    >
                      <TextInput
                        name="mxNationalId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.mxNationalId)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required={values.nationality !== "MX"}
                      name="mxWorkPermitId"
                      label={t("Work Permit ID")}
                      error={fieldErrorHandler("mxWorkPermitId")}
                    >
                      <TextInput
                        name="mxWorkPermitId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.mxWorkPermitId)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="mxSocialSecurityNumber"
                      label={t("Social Security Number")}
                      error={fieldErrorHandler("mxSocialSecurityNumber")}
                    >
                      <TextInput
                        name="mxSocialSecurityNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.mxSocialSecurityNumber)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="mxHasInfonavitLoan"
                      label={t("Do you have an Infonavit loan?")}
                      error={fieldErrorHandler("mxHasInfonavitLoan")}
                    >
                      <RadioButtonGroup
                        name="mxHasInfonavitLoan"
                        onChange={handleBooleanChange}
                        onBlur={handleBlur}
                        value={String(values.mxHasInfonavitLoan)}
                      >
                        <RadioButton value="true" label={t("Yes")} />
                        <RadioButton value="false" label={t("No")} />
                      </RadioButtonGroup>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="mxHasFonacotLoan"
                      label="Do you have a Fonacot loan?"
                      error={fieldErrorHandler("mxHasFonacotLoan")}
                    >
                      <RadioButtonGroup
                        name="mxHasFonacotLoan"
                        onChange={handleBooleanChange}
                        onBlur={handleBlur}
                        value={String(values.mxHasFonacotLoan)}
                      >
                        <RadioButton value="true" label={t("Yes")} />
                        <RadioButton value="false" label={t("No")} />
                      </RadioButtonGroup>
                    </FormField>
                  </div>

                  {/* <!-- END MX specific fields --> */}

                  <IdentifierNumberFormFragment workCountry="MX" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoMX;
