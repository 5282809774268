import { ReactElement } from "react";
import styles from "./PersonalInfo.module.css";
import GeneralInformationDefault from "./components/GeneralInformation/GeneralInformationDefault";
import ContactInformation from "./components/ContactInformation/ContactInformation";
import AddressInformation from "./components/AddressInformation/AddressInformation";
import CartaoDeCidadaoCard from "./components/PTCards/CartaoDeCidadaoCard";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import BenefitsCard from "./components/INCards/BenefitsCard";
import NationalIdentifierCard from "./components/CountrySpecificCards/BR/NationalIdentifierCard";

type Props = {
  editable: boolean;
};

export default function PersonalInfo({ editable }: Props): ReactElement {
  const { profileInfo } = useProfileContextData();

  const workCountry = profileInfo?.eorEmployeeProfile?.employment?.workCountry;
  const countrySpecificCards = () => {
    switch (workCountry) {
      case "BR":
        return <NationalIdentifierCard />;
      case "PT":
        return <CartaoDeCidadaoCard />;
      case "IN":
        return <BenefitsCard />;
      default:
        return;
    }
  };
  return (
    <>
      <div className={styles.cardSection}>
        <GeneralInformationDefault editable={editable} />
        <AddressInformation editable={editable} />
        <ContactInformation editable={editable} />
        {countrySpecificCards()}
      </div>
    </>
  );
}
