import { useRef } from "react";
import { useLocation } from "react-router-dom";

export function useConsumeSuccessMessage() {
  const location = useLocation();
  const statusMessageRef = useRef("");
  if (location.state?.successMessage) {
    // Store the status message in a ref and then clear it from the location state
    // so that the message is not displayed again on page refresh
    statusMessageRef.current = location.state.successMessage;
    window.history.replaceState({}, "");
  }
  return statusMessageRef.current;
}
