const brPersonalIdRegEx = /^[0-9]{1,2}(\.[0-9]{3}){2}-[0-9A-Za-z]{1}$/;

const STATE_OPTIONS = [
  { value: "acre", description: "Acre (AC)" },
  { value: "alagoas", description: "Alagoas (AL)" },
  { value: "amapa", description: "Amapá (AP)" },
  { value: "amazonas", description: "Amazonas (AM)" },
  { value: "bahia", description: "Bahia (BA)" },
  { value: "ceara", description: "Ceará (CE)" },
  { value: "espirito_santo", description: "Espírito Santo (ES)" },
  { value: "goias", description: "Goiás (GO)" },
  { value: "maranhao", description: "Maranhão (MA)" },
  { value: "mato_grosso", description: "Mato Grosso (MT)" },
  { value: "mato_grosso_do_sul", description: "Mato Grosso do Sul (MS)" },
  { value: "minas_gerais", description: "Minas Gerais (MG)" },
  { value: "para", description: "Pará (PA)" },
  { value: "paraiba", description: "Paraíba (PB)" },
  { value: "parana", description: "Paraná (PR)" },
  { value: "pernambuco", description: "Pernambuco (PE)" },
  { value: "piaui", description: "Piauí (PI)" },
  { value: "rio_de_janeiro", description: "Rio de Janeiro (RJ)" },
  { value: "rio_grande_do_norte", description: "Rio Grande do Norte (RN)" },
  { value: "rio_grande_do_sul", description: "Rio Grande do Sul (RS)" },
  { value: "rondonia", description: "Rondônia (RO)" },
  { value: "roraima", description: "Roraima (RR)" },
  { value: "santa_catarina", description: "Santa Catarina (SC)" },
  { value: "sao_paulo", description: "São Paulo (SP)" },
  { value: "sergipe", description: "Sergipe (SE)" },
  { value: "tocantins", description: "Tocantins (TO)" },
];

const ETHNICITY_OPTIONS = [
  {
    value: "afro-brazilian",
    description: "Afro-Brazilian (preto)",
  },
  {
    value: "multiracial",
    description: "Multiracial (pardo)",
  },
  {
    value: "indigenous",
    description: "Indigenous (indígena)",
  },
  {
    value: "asian",
    description: "Asian (amarelo)",
  },
  {
    value: "white",
    description: "White (branco)",
  },
];

const WORK_PERMIT_OPTIONS = [
  {
    value: "pending",
    description: "Pending/Under Review",
  },
  {
    value: "approved",
    description: "Approved/Authorized",
  },
  {
    value: "temporary",
    description: "Temporary",
  },
  {
    value: "permanent",
    description: "Permanent",
  },
  {
    value: "expired",
    description: "Expired",
  },
  {
    value: "suspended",
    description: "Suspended",
  },
  {
    value: "renewal_in_progress",
    description: "Renewal in Progress",
  },
  {
    value: "cancelled",
    description: "Cancelled/Revoked",
  },
  {
    value: "provisional",
    description: "Provisional/Transitory",
  },
  {
    value: "terminated",
    description: "Terminated",
  },
];

const MARITAL_STATUS_OPTIONS = [
  {
    value: "single",
    description: "Solteiro(a) – Single",
  },
  {
    value: "married",
    description: "Casado(a) – Married",
  },
  {
    value: "divorced",
    description: "Divorciado(a) – Divorced",
  },
  {
    value: "separated",
    description: "Separado(a) – Separated",
  },
  {
    value: "widowed",
    description: "Viúvo(a) – Widowed",
  },
  {
    value: "stable_union",
    description: "União Estável – Stable Union",
  },
];

const GENDER_OPTIONS = [
  {
    value: "male",
    description: "Male",
  },
  {
    value: "female",
    description: "Female",
  },
];

export {
  brPersonalIdRegEx,
  MARITAL_STATUS_OPTIONS,
  WORK_PERMIT_OPTIONS,
  ETHNICITY_OPTIONS,
  STATE_OPTIONS,
  GENDER_OPTIONS,
};
