import { useGetFeatureFlag } from "lib/launch-darkly";
import { useSubmitCompensationChangeRequestMutation } from "types/generated/operations";
import { EOR_PROFILE_PATH } from "pages/employer-of-record/routes";
import { SUBPATHS } from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import EditCompensation, {
  CompensationChangeRequest,
} from "../components/EditCompensation/EditCompensation";
import { useProfileContextData } from "../contexts/ProfileInfoContext";
import { Spinner } from "@justworkshr/milo-core";
import { getEmployeeDisplayName } from "../utils";

export default function EditCompensationPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [submitCompensationChangeRequest] =
    useSubmitCompensationChangeRequestMutation();
  const { getFeatureFlag } = useGetFeatureFlag();
  const compensationChangesEnabled = getFeatureFlag(
    "release-change-eor-ee-compensation"
  );

  const { profileInfo } = useProfileContextData();
  const displayName = getEmployeeDisplayName(
    profileInfo?.eorEmployeeProfile.personalInfo
  );

  if (!profileInfo) return <Spinner />;
  if (!compensationChangesEnabled) return <div>Forbidden</div>;

  async function onSubmit(request: CompensationChangeRequest) {
    await submitCompensationChangeRequest({
      variables: request,
    });

    navigateToProfile();
  }

  function navigateToProfile() {
    const path = `/employer-of-record${EOR_PROFILE_PATH}/${id}/${
      SUBPATHS.PERSONAL_INFO
    }?updated=${encodeURIComponent(
      `Request received! We’ll review ${displayName}’s contract details and get back to you soon.`
    )}`;
    navigate(path);
  }

  return <EditCompensation onSubmit={onSubmit} />;
}
