import { Badge, Button } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import invitationCenterStyles from "../../invitation-center.module.css";
import styles from "./../Tools.module.css";
import { COST_CALCULATOR_LINK } from "./constants";

const { item } = invitationCenterStyles;
const { title, badge } = styles;

function CostCalculator() {
  const { t } = useTranslation();

  return (
    <div className={item}>
      <div>
        <div className={title}>
          <h3>{t("Cost calculator")}</h3>
          <Badge role="info" className={badge}>
            {t("Beta")}
          </Badge>
        </div>
        <p>
          {t(
            "Now you can see exactly how much it could cost to add an Employee of Record (EOR) employee based on their exact location."
          )}
        </p>
      </div>
      <Button
        as="a"
        href={COST_CALCULATOR_LINK}
        variant="outlined"
        target="_blank"
        id="costCalculatorGetStarted"
      >
        {t("Get started")}
      </Button>
    </div>
  );
}

export default CostCalculator;
