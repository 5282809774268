import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./BackToProfile.module.css";
import { useTranslation } from "react-i18next";
import { EOR_PROFILE_PATH } from "pages/employer-of-record/routes";
import { useParams } from "react-router-dom";
import { SUBPATHS } from "pages/employer-of-record/employee-profile/constants";

function useProfileLink() {
  const { id: memberUuid } = useParams();
  return `/cf/employer-of-record${EOR_PROFILE_PATH}/${memberUuid}/${SUBPATHS.PERSONAL_INFO}`;
}

export default function BackToProfile() {
  const { t } = useTranslation();
  const profileLink = useProfileLink();

  return (
    <a href={profileLink}>
      <div className={styles.backContainer}>
        <SystemIcon iconName="arrow-left" color="brand" size="small" />
        <span>{t("Back to Profile")}</span>
      </div>
    </a>
  );
}
