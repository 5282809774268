import { buildClockworkWebPath } from "lib/resource-finder";
import usePlaidModal from "./usePlaidModal";
import { useSubmitPlaidToken } from "./useSubmitPlaidToken";

type Mode = "update" | "link";

export const usePlaidBankAccountFlow = (mode: Mode) => {
  const { mutate, isPending, isSuccess, isError } = useSubmitPlaidToken();

  const { openPlaidModal, isPlaidModalLoading, plaidError } = usePlaidModal({
    onSubmit: (publicToken) => {
      if (mode === "update") {
        window.location.assign(
          buildClockworkWebPath("/company/plaid_verify_success")
        );
        return;
      } else {
        mutate({ publicToken });
      }
    },
    mode: mode,
  });

  return {
    openPlaidModal,
    isPending: isPending || isPlaidModalLoading,
    isSuccess,
    plaidError,
    plaidAccountCreateError: isError,
  };
};

export default usePlaidBankAccountFlow;
