import { ReactElement } from "react";
import AlertNotification from "../alerts-notification";
import NsfAlertNotification from "../nsf-alert-notification/nsf-alert-notification";
import PlaidReconnect from "../plaid-reconnect/plaid-reconnect";
import ContinueHiUpsellAppNotification from "../continue-hi-upsell-app-notification/continue-hi-upsell-app-notification";
import { Notifications } from "types";
import { NotificationMemberDetails } from "types";
import PTORequest from "../pto-request";
import {
  createPendingHireNotification,
  createPmtIntegrationAlertsNotification,
} from "pages/pmt/integrations/utils";
import styles from "./alerts-inbox.module.css";

const { zeroState } = styles;

const EMPTY_INBOX_MESSAGE = "You're all caught up!";

interface AlertInboxTypes {
  notificationsCount: number;
  notifications: Notifications | undefined | null;
}

export default function AlertInbox({
  notificationsCount,
  notifications,
}: AlertInboxTypes): ReactElement {
  if (
    notificationsCount === 0 &&
    notifications?.upcomingHolidays?.notifications?.length === 0 &&
    notifications?.continueHiUpsellApp?.notifications?.length === 0 &&
    notifications?.directReportsUpcomingHolidays?.notifications?.length === 0
  ) {
    return <div className={zeroState}>{EMPTY_INBOX_MESSAGE}</div>;
  }

  const alertNotificationList: {
    key: string;
    message: string;
    url?: string;
    priority: number;
    member?: NotificationMemberDetails;
    firstName?: string;
    lastName?: string;
    memberUuid?: string;
    requestUuid?: string;
    photoUrl?: string;
    type: string;
  }[] = [];

  notifications?.i9ToSign?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        member: item.member as NotificationMemberDetails,
        priority: item.priority,
      });
  });

  notifications?.nsfNotifications?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "nsf_notification",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });

  notifications?.reconnectPlaid?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "reconnect_plaid_notification",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });

  notifications?.irsRequired?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });
  notifications?.irsVerified?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });
  notifications?.missingBankAccount?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });
  notifications?.pennyTest?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });
  notifications?.processingSuspended?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });

  notifications?.ptoRequest?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "pto",
        key: item.timeOffUuid,
        message: item.message,
        firstName: item.member.preferredName,
        lastName: item.member.lastName,
        memberUuid: item.member.uuid,
        requestUuid: item.timeOffUuid,
        url: item.url,
        photoUrl: item.member.photoUrl ? item.member.photoUrl : undefined,
        priority: item.priority,
      });
  });

  notifications?.unpaidEmployees?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });

  notifications?.upcomingHolidays?.notifications?.forEach((item) => {
    alertNotificationList.push({
      type: "alert",
      key: item.message,
      message: item.message,
      priority: item.priority,
    });
  });

  notifications?.upgradePending?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.message,
        message: item.message,
        priority: item.priority,
      });
  });

  notifications?.atsPendingHire?.notifications?.forEach((item) => {
    const { alertNotificationProps } = createPendingHireNotification(item);
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: alertNotificationProps.url,
        message: alertNotificationProps.message,
        url: alertNotificationProps.url,
        member: alertNotificationProps.member,
        priority: alertNotificationProps.priority,
      });
  });

  notifications?.pmtIntegrationAlerts?.notifications?.forEach((item) => {
    const { alertNotificationProps } =
      createPmtIntegrationAlertsNotification(item);
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: alertNotificationProps.url,
        message: alertNotificationProps.message,
        url: alertNotificationProps.url,
        member: alertNotificationProps.member,
        priority: alertNotificationProps.priority,
      });
  });

  notifications?.reimbursementRequests?.notifications?.forEach(
    (item, index) => {
      item.addedToNotificationCount &&
        alertNotificationList.push({
          type: "alert",
          key: `${index}_${item.message}`,
          message: item.message,
          priority: item.priority,
        });
    }
  );

  notifications?.directReportsUpcomingHolidays?.notifications?.forEach(
    (item) => {
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        member: item.member,
        priority: item.priority,
      });
    }
  );

  notifications?.continueHiUpsellApp?.notifications?.forEach((item) => {
    alertNotificationList.push({
      type: "continue_hi_upsell_app",
      key: item.url,
      message: item.message,
      url: item.url,
      priority: item.priority,
    });
  });

  notifications?.pendingFirmInvitation?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: "pending-firm-invitation",
        message: item.message,
        url: item.url,
        member: item.member,
        priority: item.priority,
      });
  });

  notifications?.taxExemptionRenewals?.notifications?.forEach((item) => {
    item.addedToNotificationCount &&
      alertNotificationList.push({
        type: "alert",
        key: item.url,
        message: item.message,
        url: item.url,
        priority: item.priority,
      });
  });

  alertNotificationList.sort((a, b) => a.priority - b.priority);
  const allAlerts = alertNotificationList.map((item) => {
    switch (item.type) {
      case "pto":
        return (
          <PTORequest
            key={item.key}
            message={item.message}
            url={item.url ? item.url : ""}
            firstName={item.firstName ? item.firstName : ""}
            lastName={item.lastName ? item.lastName : ""}
            memberUuid={item.memberUuid ? item.memberUuid : ""}
            timeOffRequestUuid={item.requestUuid ? item.requestUuid : ""}
            photoUrl={item.photoUrl}
          />
        );
      case "nsf_notification":
        return (
          <NsfAlertNotification
            key={item.key}
            message={item.message}
            url={item.url}
            member={item.member}
          />
        );
      case "reconnect_plaid_notification":
        return (
          <PlaidReconnect
            key={item.key}
            message={item.message}
            url={item.url}
            member={item.member}
          />
        );
      case "continue_hi_upsell_app":
        return (
          <ContinueHiUpsellAppNotification
            key={item.key}
            message={item.message}
            url={item.url}
            member={item.member}
          />
        );
      default:
        return (
          <AlertNotification
            key={item.key}
            message={item.message}
            url={item.url}
            member={item.member}
          />
        );
    }
  });

  return <>{allAlerts}</>;
}
