import { permissionGroupDetails } from "pages/advisor/common/constants";
import { PERMISSION } from "types/RequiredPermissions";

interface alsoGrants {
  [key: string]: string[];
}

const alsoGrants: alsoGrants = {};
// Statically initializing `alsoGrants`:
Object.entries(permissionGroupDetails).forEach(([_group, val]) => {
  Object.values(val.permissions).forEach((permission) => {
    permission.grantedBy.forEach((grantedBy) => {
      // This permission is granted by another permission. First
      // make the `grantedBy` is in the map to push to.
      if (!alsoGrants[grantedBy]) {
        alsoGrants[grantedBy] = [];
      }

      // Push this permission to the map to signify that that permission
      // also grants the one we're iterating over (`permission`).
      alsoGrants[grantedBy].push(permission.id);
    });
  });
});

// This takes permission strings and expands them when they grant other permissions,
// e.g. "make_payments" also grants "timecards".
export function expandPermissions(permissions: string[]): string[] {
  // To not keep looping through a growing permissions list, permissions are added to a new
  // array that isn't looped through.
  const perms: string[] = [];

  // For each permission, it may grant other permissions, so each needs to be checked to see if
  // it expands into more permissions for the return.
  permissions.forEach((p: string) => {
    // Any permissions passed in are always added to the return array:
    perms.push(p);

    const additionalGrants = alsoGrants[p];
    if (additionalGrants) {
      perms.push(...additionalGrants);
    }
  });

  // Dedupe if permissions added the same ones:
  return [...new Set(perms)];
}

const manageFirmsPerms: Array<number> = [
  PERMISSION.CHANGE_EMPLOYEES,
  PERMISSION.GRANT_PERMISSIONS,
];

export function canManageFirms(
  permissions: number[] | undefined | null
): boolean {
  if (!permissions) {
    return false;
  }

  // Checks for each of the manageFirmPerms to be included in the given permissions array,
  // e.g. that the given member has all of the required permissions.
  for (const perm of manageFirmsPerms) {
    if (!permissions.includes(perm)) {
      return false;
    }
  }
  return true;
}
