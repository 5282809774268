import type { ProfileInfoFormType } from "./types";
import i18n from "../../../../app/internationalization/i18n";

const initialValues: ProfileInfoFormType = {
  profileInfo: {
    firstName: "",
    lastName: "",
    preferredFirstName: "",
    middleName: "",
    pronouns: "",
    dateOfBirth: "",
    settings: {
      showDateOfBirth: false,
    },
    identifierNumber: "",
    confirmIdentifierNumber: "",
  },
};

const months = () => {
  return [
    {
      value: "1",
      description: i18n.t("January"),
    },
    {
      value: "2",
      description: i18n.t("February"),
    },
    {
      value: "3",
      description: i18n.t("March"),
    },
    {
      value: "4",
      description: i18n.t("April"),
    },
    {
      value: "5",
      description: i18n.t("May"),
    },
    {
      value: "6",
      description: i18n.t("June"),
    },
    {
      value: "7",
      description: i18n.t("July"),
    },
    {
      value: "8",
      description: i18n.t("August"),
    },
    {
      value: "9",
      description: i18n.t("September"),
    },
    {
      value: "10",
      description: i18n.t("October"),
    },
    {
      value: "11",
      description: i18n.t("November"),
    },
    {
      value: "12",
      description: i18n.t("December"),
    },
  ];
};

const NATIONALITIES = [
  {
    value: "AF",
    description: "Afghanistan",
  },
  {
    value: "AX",
    description: "Åland Islands",
  },
  {
    value: "AL",
    description: "Albania",
  },
  {
    value: "DZ",
    description: "Algeria",
  },
  {
    value: "AD",
    description: "Andorra",
  },
  {
    value: "AO",
    description: "Angola",
  },
  {
    value: "AI",
    description: "Anguilla",
  },
  {
    value: "AG",
    description: "Antigua & Barbuda",
  },
  {
    value: "AR",
    description: "Argentina",
  },
  {
    value: "AM",
    description: "Armenia",
  },
  {
    value: "AW",
    description: "Aruba",
  },
  {
    value: "AC",
    description: "Ascension Island",
  },
  {
    value: "AU",
    description: "Australia",
  },
  {
    value: "AT",
    description: "Austria",
  },
  {
    value: "AZ",
    description: "Azerbaijan",
  },
  {
    value: "BS",
    description: "Bahamas",
  },
  {
    value: "BH",
    description: "Bahrain",
  },
  {
    value: "BD",
    description: "Bangladesh",
  },
  {
    value: "BB",
    description: "Barbados",
  },
  {
    value: "BY",
    description: "Belarus",
  },
  {
    value: "BE",
    description: "Belgium",
  },
  {
    value: "BZ",
    description: "Belize",
  },
  {
    value: "BJ",
    description: "Benin",
  },
  {
    value: "BM",
    description: "Bermuda",
  },
  {
    value: "BT",
    description: "Bhutan",
  },
  {
    value: "BO",
    description: "Bolivia",
  },
  {
    value: "BA",
    description: "Bosnia & Herzegovina",
  },
  {
    value: "BW",
    description: "Botswana",
  },
  {
    value: "BR",
    description: "Brazil",
  },
  {
    value: "IO",
    description: "British Indian Ocean Territory",
  },
  {
    value: "VG",
    description: "British Virgin Islands",
  },
  {
    value: "BN",
    description: "Brunei",
  },
  {
    value: "BG",
    description: "Bulgaria",
  },
  {
    value: "BF",
    description: "Burkina Faso",
  },
  {
    value: "BI",
    description: "Burundi",
  },
  {
    value: "KH",
    description: "Cambodia",
  },
  {
    value: "CM",
    description: "Cameroon",
  },
  {
    value: "CA",
    description: "Canada",
  },
  {
    value: "CV",
    description: "Cape Verde",
  },
  {
    value: "BQ",
    description: "Caribbean Netherlands",
  },
  {
    value: "KY",
    description: "Cayman Islands",
  },
  {
    value: "CF",
    description: "Central African Republic",
  },
  {
    value: "TD",
    description: "Chad",
  },
  {
    value: "CL",
    description: "Chile",
  },
  {
    value: "CN",
    description: "China",
  },
  {
    value: "CX",
    description: "Christmas Island",
  },
  {
    value: "CC",
    description: "Cocos (Keeling) Islands",
  },
  {
    value: "CO",
    description: "Colombia",
  },
  {
    value: "KM",
    description: "Comoros",
  },
  {
    value: "CG",
    description: "Congo - Brazzaville",
  },
  {
    value: "CD",
    description: "Congo - Kinshasa",
  },
  {
    value: "CK",
    description: "Cook Islands",
  },
  {
    value: "CR",
    description: "Costa Rica",
  },
  {
    value: "HR",
    description: "Croatia",
  },
  {
    value: "CW",
    description: "Curaçao",
  },
  {
    value: "CY",
    description: "Cyprus",
  },
  {
    value: "CZ",
    description: "Czechia",
  },
  {
    value: "CI",
    description: "Côte d’Ivoire",
  },
  {
    value: "DK",
    description: "Denmark",
  },
  {
    value: "DJ",
    description: "Djibouti",
  },
  {
    value: "DM",
    description: "Dominica",
  },
  {
    value: "DO",
    description: "Dominican Republic",
  },
  {
    value: "EC",
    description: "Ecuador",
  },
  {
    value: "EG",
    description: "Egypt",
  },
  {
    value: "SV",
    description: "El Salvador",
  },
  {
    value: "GQ",
    description: "Equatorial Guinea",
  },
  {
    value: "ER",
    description: "Eritrea",
  },
  {
    value: "EE",
    description: "Estonia",
  },
  {
    value: "SZ",
    description: "Eswatini",
  },
  {
    value: "ET",
    description: "Ethiopia",
  },
  {
    value: "FK",
    description: "Falkland Islands",
  },
  {
    value: "FO",
    description: "Faroe Islands",
  },
  {
    value: "FJ",
    description: "Fiji",
  },
  {
    value: "FI",
    description: "Finland",
  },
  {
    value: "FR",
    description: "France",
  },
  {
    value: "GF",
    description: "French Guiana",
  },
  {
    value: "PF",
    description: "French Polynesia",
  },
  {
    value: "TF",
    description: "French Southern Territories",
  },
  {
    value: "GA",
    description: "Gabon",
  },
  {
    value: "GM",
    description: "Gambia",
  },
  {
    value: "GE",
    description: "Georgia",
  },
  {
    value: "DE",
    description: "Germany",
  },
  {
    value: "GH",
    description: "Ghana",
  },
  {
    value: "GI",
    description: "Gibraltar",
  },
  {
    value: "GR",
    description: "Greece",
  },
  {
    value: "GL",
    description: "Greenland",
  },
  {
    value: "GD",
    description: "Grenada",
  },
  {
    value: "GP",
    description: "Guadeloupe",
  },
  {
    value: "GT",
    description: "Guatemala",
  },
  {
    value: "GG",
    description: "Guernsey",
  },
  {
    value: "GN",
    description: "Guinea",
  },
  {
    value: "GW",
    description: "Guinea-Bissau",
  },
  {
    value: "GY",
    description: "Guyana",
  },
  {
    value: "HT",
    description: "Haiti",
  },
  {
    value: "HN",
    description: "Honduras",
  },
  {
    value: "HK",
    description: "Hong Kong SAR",
  },
  {
    value: "HU",
    description: "Hungary",
  },
  {
    value: "IS",
    description: "Iceland",
  },
  {
    value: "IN",
    description: "India",
  },
  {
    value: "ID",
    description: "Indonesia",
  },
  {
    value: "IQ",
    description: "Iraq",
  },
  {
    value: "IE",
    description: "Ireland",
  },
  {
    value: "IM",
    description: "Isle of Man",
  },
  {
    value: "IL",
    description: "Israel",
  },
  {
    value: "IT",
    description: "Italy",
  },
  {
    value: "JM",
    description: "Jamaica",
  },
  {
    value: "JP",
    description: "Japan",
  },
  {
    value: "JE",
    description: "Jersey",
  },
  {
    value: "JO",
    description: "Jordan",
  },
  {
    value: "KZ",
    description: "Kazakhstan",
  },
  {
    value: "KE",
    description: "Kenya",
  },
  {
    value: "KI",
    description: "Kiribati",
  },
  {
    value: "XK",
    description: "Kosovo",
  },
  {
    value: "KW",
    description: "Kuwait",
  },
  {
    value: "KG",
    description: "Kyrgyzstan",
  },
  {
    value: "LA",
    description: "Laos",
  },
  {
    value: "LV",
    description: "Latvia",
  },
  {
    value: "LB",
    description: "Lebanon",
  },
  {
    value: "LS",
    description: "Lesotho",
  },
  {
    value: "LR",
    description: "Liberia",
  },
  {
    value: "LY",
    description: "Libya",
  },
  {
    value: "LI",
    description: "Liechtenstein",
  },
  {
    value: "LT",
    description: "Lithuania",
  },
  {
    value: "LU",
    description: "Luxembourg",
  },
  {
    value: "MO",
    description: "Macao SAR",
  },
  {
    value: "MG",
    description: "Madagascar",
  },
  {
    value: "MW",
    description: "Malawi",
  },
  {
    value: "MY",
    description: "Malaysia",
  },
  {
    value: "MV",
    description: "Maldives",
  },
  {
    value: "ML",
    description: "Mali",
  },
  {
    value: "MT",
    description: "Malta",
  },
  {
    value: "MQ",
    description: "Martinique",
  },
  {
    value: "MR",
    description: "Mauritania",
  },
  {
    value: "MU",
    description: "Mauritius",
  },
  {
    value: "YT",
    description: "Mayotte",
  },
  {
    value: "MX",
    description: "Mexico",
  },
  {
    value: "MD",
    description: "Moldova",
  },
  {
    value: "MC",
    description: "Monaco",
  },
  {
    value: "MN",
    description: "Mongolia",
  },
  {
    value: "ME",
    description: "Montenegro",
  },
  {
    value: "MS",
    description: "Montserrat",
  },
  {
    value: "MA",
    description: "Morocco",
  },
  {
    value: "MZ",
    description: "Mozambique",
  },
  {
    value: "MM",
    description: "Myanmar (Burma)",
  },
  {
    value: "NA",
    description: "Namibia",
  },
  {
    value: "NR",
    description: "Nauru",
  },
  {
    value: "NP",
    description: "Nepal",
  },
  {
    value: "NL",
    description: "Netherlands",
  },
  {
    value: "NC",
    description: "New Caledonia",
  },
  {
    value: "NZ",
    description: "New Zealand",
  },
  {
    value: "NI",
    description: "Nicaragua",
  },
  {
    value: "NE",
    description: "Niger",
  },
  {
    value: "NG",
    description: "Nigeria",
  },
  {
    value: "NU",
    description: "Niue",
  },
  {
    value: "NF",
    description: "Norfolk Island",
  },
  {
    value: "MK",
    description: "North Macedonia",
  },
  {
    value: "NO",
    description: "Norway",
  },
  {
    value: "OM",
    description: "Oman",
  },
  {
    value: "PK",
    description: "Pakistan",
  },
  {
    value: "PS",
    description: "Palestinian Territories",
  },
  {
    value: "PA",
    description: "Panama",
  },
  {
    value: "PG",
    description: "Papua New Guinea",
  },
  {
    value: "PY",
    description: "Paraguay",
  },
  {
    value: "PE",
    description: "Peru",
  },
  {
    value: "PH",
    description: "Philippines",
  },
  {
    value: "PN",
    description: "Pitcairn Islands",
  },
  {
    value: "PL",
    description: "Poland",
  },
  {
    value: "PT",
    description: "Portugal",
  },
  {
    value: "QA",
    description: "Qatar",
  },
  {
    value: "RE",
    description: "Réunion",
  },
  {
    value: "RO",
    description: "Romania",
  },
  {
    value: "RU",
    description: "Russia",
  },
  {
    value: "RW",
    description: "Rwanda",
  },
  {
    value: "WS",
    description: "Samoa",
  },
  {
    value: "SM",
    description: "San Marino",
  },
  {
    value: "ST",
    description: "São Tomé & Príncipe",
  },
  {
    value: "SA",
    description: "Saudi Arabia",
  },
  {
    value: "SN",
    description: "Senegal",
  },
  {
    value: "RS",
    description: "Serbia",
  },
  {
    value: "SC",
    description: "Seychelles",
  },
  {
    value: "SL",
    description: "Sierra Leone",
  },
  {
    value: "SG",
    description: "Singapore",
  },
  {
    value: "SX",
    description: "Sint Maarten",
  },
  {
    value: "SK",
    description: "Slovakia",
  },
  {
    value: "SI",
    description: "Slovenia",
  },
  {
    value: "SB",
    description: "Solomon Islands",
  },
  {
    value: "SO",
    description: "Somalia",
  },
  {
    value: "ZA",
    description: "South Africa",
  },
  {
    value: "GS",
    description: "South Georgia & South Sandwich Islands",
  },
  {
    value: "KR",
    description: "South Korea",
  },
  {
    value: "SS",
    description: "South Sudan",
  },
  {
    value: "ES",
    description: "Spain",
  },
  {
    value: "LK",
    description: "Sri Lanka",
  },
  {
    value: "BL",
    description: "St. Barthélemy",
  },
  {
    value: "SH",
    description: "St. Helena",
  },
  {
    value: "KN",
    description: "St. Kitts & Nevis",
  },
  {
    value: "LC",
    description: "St. Lucia",
  },
  {
    value: "MF",
    description: "St. Martin",
  },
  {
    value: "PM",
    description: "St. Pierre & Miquelon",
  },
  {
    value: "VC",
    description: "St. Vincent & Grenadines",
  },
  {
    value: "SD",
    description: "Sudan",
  },
  {
    value: "SR",
    description: "Suriname",
  },
  {
    value: "SJ",
    description: "Svalbard & Jan Mayen",
  },
  {
    value: "SE",
    description: "Sweden",
  },
  {
    value: "CH",
    description: "Switzerland",
  },
  {
    value: "TW",
    description: "Taiwan",
  },
  {
    value: "TJ",
    description: "Tajikistan",
  },
  {
    value: "TZ",
    description: "Tanzania",
  },
  {
    value: "TH",
    description: "Thailand",
  },
  {
    value: "TL",
    description: "Timor-Leste",
  },
  {
    value: "TG",
    description: "Togo",
  },
  {
    value: "TK",
    description: "Tokelau",
  },
  {
    value: "TO",
    description: "Tonga",
  },
  {
    value: "TT",
    description: "Trinidad & Tobago",
  },
  {
    value: "TA",
    description: "Tristan da Cunha",
  },
  {
    value: "TN",
    description: "Tunisia",
  },
  {
    value: "TR",
    description: "Türkiye",
  },
  {
    value: "TM",
    description: "Turkmenistan",
  },
  {
    value: "TC",
    description: "Turks & Caicos Islands",
  },
  {
    value: "TV",
    description: "Tuvalu",
  },
  {
    value: "UM",
    description: "U.S. Outlying Islands",
  },
  {
    value: "UG",
    description: "Uganda",
  },
  {
    value: "UA",
    description: "Ukraine",
  },
  {
    value: "AE",
    description: "United Arab Emirates",
  },
  {
    value: "GB",
    description: "United Kingdom",
  },
  {
    value: "US",
    description: "United States",
  },
  {
    value: "UY",
    description: "Uruguay",
  },
  {
    value: "UZ",
    description: "Uzbekistan",
  },
  {
    value: "VU",
    description: "Vanuatu",
  },
  {
    value: "VA",
    description: "Vatican City",
  },
  {
    value: "VE",
    description: "Venezuela",
  },
  {
    value: "VN",
    description: "Vietnam",
  },
  {
    value: "WF",
    description: "Wallis & Futuna",
  },
  {
    value: "EH",
    description: "Western Sahara",
  },
  {
    value: "YE",
    description: "Yemen",
  },
  {
    value: "ZM",
    description: "Zambia",
  },
  {
    value: "ZW",
    description: "Zimbabwe",
  },
] as const;

export { initialValues, months, NATIONALITIES };
