import { Card } from "@justworkshr/milo-core";
import { FormField, TextInput } from "@justworkshr/milo-form";
import { ContactInfoFormType } from "./types";
import { useFormikContext } from "formik";
import IntlPhoneNumberInput from "../../IntlPhoneNumberInput";
import { useTranslation } from "react-i18next";
import styles from "./ContactInfo.module.css";
import { useContext } from "react";
import { EoRMemberContext } from "../../contexts/eorMemberContext";

type Props = {
  handleBlur: (event: React.FocusEvent) => void;
  handleChange: (event: React.ChangeEvent) => void;
};

const EmergencyContactCard = ({ handleBlur, handleChange }: Props) => {
  const { t } = useTranslation();
  const eorMemberContext = useContext(EoRMemberContext);
  const { errors, touched, values } = useFormikContext<ContactInfoFormType>();

  return (
    <Card title="Emergency contact">
      <div className={styles.formFieldWrapper}>
        <FormField
          name="contactInfo.emergencyContact.firstName"
          label={t("First Name")}
          required
          error={
            touched?.contactInfo?.emergencyContact?.firstName
              ? errors?.contactInfo?.emergencyContact?.firstName
              : ""
          }
        >
          <div className={styles.formFieldWidth}>
            <TextInput
              name="contactInfo.emergencyContact.firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contactInfo.emergencyContact.firstName}
            />
          </div>
        </FormField>
      </div>
      <div className={styles.formFieldWrapper}>
        <FormField
          name="contactInfo.emergencyContact.lastName"
          label={t("Last Name")}
          required
          error={
            touched?.contactInfo?.emergencyContact?.lastName
              ? errors?.contactInfo?.emergencyContact?.lastName
              : ""
          }
        >
          <div className={styles.formFieldWidth}>
            <TextInput
              name="contactInfo.emergencyContact.lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contactInfo.emergencyContact.lastName}
            />{" "}
          </div>
        </FormField>
      </div>
      <div className={styles.formFieldWrapper}>
        <FormField
          name="contactInfo.emergencyContact.relationship"
          label={t("Relationship")}
          required
          error={
            touched?.contactInfo?.emergencyContact?.relationship
              ? errors?.contactInfo?.emergencyContact?.relationship
              : ""
          }
        >
          <div className={styles.formFieldWidth}>
            <TextInput
              name="contactInfo.emergencyContact.relationship"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contactInfo.emergencyContact.relationship}
            />
          </div>
        </FormField>
      </div>
      <div className={styles.formFieldWrapper}>
        <FormField
          label={t("Phone Number")}
          required
          error={
            touched?.contactInfo?.emergencyContact?.phoneNumber
              ? errors?.contactInfo?.emergencyContact?.phoneNumber
              : ""
          }
        >
          <div className={styles.formFieldWidth}>
            <IntlPhoneNumberInput
              id="emergencyContact.phoneNumber"
              name="contactInfo.emergencyContact.phoneNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contactInfo.emergencyContact.phoneNumber}
              countryCode={eorMemberContext?.workCountry}
            />
          </div>
        </FormField>
      </div>
    </Card>
  );
};

export default EmergencyContactCard;
