import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { EorEmployeeContactDetails } from "types/employer-of-record";
import { formatPhoneNumber } from "pages/employer-of-record/employee-profile/utils";
import Row from "pages/employer-of-record/employee-profile/components/Row/Row";

export default function ContactPhoneNumbers(): ReactElement {
  const { profileInfo } = useProfileContextData();

  const contactDetails: EorEmployeeContactDetails =
    profileInfo?.eorEmployeeProfile.contactDetails;

  const workCountry: string =
    profileInfo?.eorEmployeeProfile?.employment?.workCountry || "";

  const hasPhoneInfo =
    contactDetails?.mobilePhoneNumber ??
    contactDetails?.workPhoneNumber ??
    contactDetails?.homePhoneNumber != undefined;

  const renderContactInfoRow = (
    value: string,
    tag: string | undefined | null
  ) => {
    return <Row value={value} required tag={tag || ""} />;
  };

  const renderPhoneNumberSection = (
    contactDetails: EorEmployeeContactDetails
  ) => {
    const homePhoneNumber = contactDetails?.homePhoneNumber;
    const workPhoneNumber = contactDetails?.workPhoneNumber;
    const mobilePhoneNumber = contactDetails?.mobilePhoneNumber;
    return (
      <>
        <div>
          {mobilePhoneNumber &&
            renderContactInfoRow(
              formatPhoneNumber(mobilePhoneNumber?.value, workCountry),
              mobilePhoneNumber?.type
            )}
          {workPhoneNumber &&
            renderContactInfoRow(
              formatPhoneNumber(workPhoneNumber?.value, workCountry),
              workPhoneNumber?.type
            )}
          {homePhoneNumber &&
            renderContactInfoRow(
              formatPhoneNumber(homePhoneNumber?.value, workCountry),
              homePhoneNumber?.type
            )}
        </div>
      </>
    );
  };

  return (
    <Row label="Phone" required>
      {hasPhoneInfo ? (
        <>{renderPhoneNumberSection(contactDetails)}</>
      ) : undefined}
    </Row>
  );
}
