import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useCompensationChange } from "./CompensationChangeContext";
import { useTranslation } from "react-i18next";
import styles from "./CompensationChangeFooter.module.css";
import { useState } from "react";
import CancelEditModal from "../EditableProfile/components/CancelEditModal";
import { EOR_PROFILE_PATH } from "../../../routes";
import { SUBPATHS } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";

export default function CompensationChangeFooter({
  disabled,
  loading,
}: {
  disabled?: boolean;
  loading?: boolean;
}) {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentStep, onBack } = useCompensationChange();
  const [isOpen, setIsOpen] = useState(false);
  const isAdmin = !!id;
  const linkToProfile = isAdmin
    ? `/employer-of-record${EOR_PROFILE_PATH}/${id}/${SUBPATHS.PERSONAL_INFO}`
    : "/account-settings";

  function onCloseCancelEditModal() {
    setIsOpen(false);
  }
  return (
    <>
      {isOpen && (
        <CancelEditModal
          isOpen={isOpen}
          onClose={onCloseCancelEditModal}
          navigateBack={() => navigate(linkToProfile)}
        />
      )}
      <ActionFooter
        actions={
          <div className={styles.actions}>
            {currentStep !== 1 ? (
              <Button onClick={onBack} variant="outlined">
                {t("Go back")}
              </Button>
            ) : (
              <div />
            )}
            <div>
              <Button
                onClick={() => setIsOpen(true)}
                color="brand"
                variant="ghost"
              >
                {t("Cancel")}
              </Button>

              <Button loading={loading} disabled={disabled} type="submit">
                {currentStep === 2 ? t("Submit") : t("Continue")}
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
}
