import styles from "./TabsContentTemplate.module.css";
import Divider from "../Divider/Divider";
import { ReactNode } from "react";

const {
  headerText,
  subHeader,
  contentSection,
  infoTabsListItem,
  summaryText,
  detailText,
  dataSharedList,
} = styles;

export type InfoTabsContent = {
  overviewTab: {
    header: string;
    subheader: string;
    list: {
      summary: string;
      detail: string;
    }[];
  };
  dataAndPermissionsTab?: {
    dataSharedSection: {
      header: string;
      subheader?: string;
      sharedDataGroups: {
        header?: string;
        sharedData: string[];
      }[];
      footnote?: ReactNode;
      notice?: ReactNode;
    };
    permissionsSection: {
      header: string;
      list: {
        summary: string;
        detail: ReactNode;
      }[];
    };
  };
  postSetupTab?: {
    header: string;
    subheader?: ReactNode;
    list: {
      caption: ReactNode;
      image?: string;
    }[];
  };
};

export type TabsContentProps = {
  tabsContent: InfoTabsContent;
};

export const OverviewTab: React.FC<TabsContentProps> = ({ tabsContent }) => (
  <div>
    <div className={contentSection}>
      <div className={headerText}>{tabsContent.overviewTab.header}</div>
      <div className={subHeader}>{tabsContent.overviewTab.subheader}</div>
    </div>
    <Divider />
    <section>
      {
        <div className={contentSection}>
          {tabsContent.overviewTab.list.map((item) => (
            <div className={infoTabsListItem} key={item.detail}>
              <div className={summaryText}>{item.summary}</div>
              <div className={detailText}>{item.detail}</div>
            </div>
          ))}
        </div>
      }
    </section>
  </div>
);

export const DataAndPermissionsTab: React.FC<TabsContentProps> = ({
  tabsContent,
}) => {
  const dataSharedSection =
    tabsContent.dataAndPermissionsTab?.dataSharedSection;
  const sharedDataGroups = dataSharedSection?.sharedDataGroups;
  return (
    <div>
      <div className={contentSection}>
        <div className={headerText}>{dataSharedSection?.header}</div>
        {dataSharedSection?.subheader && (
          <div className={subHeader}>{dataSharedSection?.subheader}</div>
        )}
        {sharedDataGroups &&
          sharedDataGroups.map((group) => (
            <div>
              {group?.header && (
                <div className={subHeader}>{group?.header}</div>
              )}
              <ul className={dataSharedList}>
                {group.sharedData.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        {dataSharedSection?.footnote}
        {dataSharedSection?.notice}
      </div>
      <Divider />
      <div className={contentSection}>
        <div className={headerText}>
          {tabsContent.dataAndPermissionsTab?.permissionsSection.header}
        </div>
        {tabsContent.dataAndPermissionsTab?.permissionsSection.list.map(
          (item) => (
            <div className={infoTabsListItem} key={item.summary}>
              <div className={summaryText}>{item.summary}</div>
              <div className={detailText}>{item.detail}</div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export const PostSetupTab: React.FC<TabsContentProps> = ({ tabsContent }) => (
  <div className={contentSection}>
    <div className={headerText}>{tabsContent.postSetupTab?.header}</div>
    {tabsContent.postSetupTab?.subheader && (
      <div className={subHeader}>{tabsContent.postSetupTab?.subheader}</div>
    )}
    {tabsContent.postSetupTab?.list.map((item, i) => (
      <div className={contentSection} key={`${item.image || i}`}>
        <div>{item.caption}</div>
        {item.image && <img src={item.image} aria-hidden />}
      </div>
    ))}
  </div>
);
