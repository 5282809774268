import { standardAlerts, useAlert } from "../../hooks/useAlert";
import { useEffect, useMemo } from "react";
import { integrationMarketplaceDataHookResponse } from "./useIntegrationMarketplaceData";

const useIntegrationMarketplaceAlert = (
  integrationMarketplaceDataHookResponse: integrationMarketplaceDataHookResponse
): ReturnType<typeof useAlert> => {
  const alertHookResult = useAlert();
  const { setAlert } = alertHookResult;

  useEffect(() => {
    const {
      combinedError,
      combinedLoading,
      pmtIntegrationsEnabled,
      pmtIntegrationFrameworkDataResult,
    } = integrationMarketplaceDataHookResponse;

    if (!combinedLoading) {
      if (combinedError) {
        setAlert(standardAlerts.somethingWentWrong);
      } else if (pmtIntegrationsEnabled) {
        // Check if all parts of the graph query were successful
        const pmtIntegrationsSuccess =
          pmtIntegrationFrameworkDataResult?.data?.pmtIntegrationsFramework
            ?.solutions?.metadata &&
          pmtIntegrationFrameworkDataResult?.data?.pmtIntegrationsFramework
            ?.integrations?.metadata.success;
        if (!pmtIntegrationsSuccess) {
          setAlert(standardAlerts.failedToLoadIntegrations);
        }
      }
    }
  }, [integrationMarketplaceDataHookResponse, setAlert]);

  return useMemo(() => {
    return alertHookResult;
  }, [alertHookResult]);
};

export default useIntegrationMarketplaceAlert;
