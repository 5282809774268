import styles from "./ReviewBox.module.css";
import { Divider } from "@justworkshr/milo-core";
import { formValueLabels } from "../../constants";

const { reviewBox, reviewLineLabel, reviewLineValue, container } = styles;

interface ReviewBoxProps {
  title: string;
  data: {
    label: string;
    value: string | undefined;
    reason?: string | undefined;
    document?: string | undefined;
  }[];
}

const ReviewLine: React.FC<{ label: string; value: string | undefined }> = ({
  label,
  value,
}) => (
  <div className={container}>
    <div className={reviewLineLabel}>{label}</div>
    <div className={reviewLineValue}>{value}</div>
  </div>
);

const ReviewField: React.FC<{
  item: {
    label: string;
    value: string | undefined;
    reason?: string | undefined;
    document?: string | undefined;
  };
}> = ({ item }) => (
  <div>
    <ReviewLine label={item.label} value={item.value} />
    {item.reason && (
      <ReviewLine
        label="Reason"
        value={formValueLabels[item.reason as keyof typeof formValueLabels]}
      />
    )}
    {item.document && (
      <ReviewLine label="Document uploaded" value={item.document} />
    )}
  </div>
);

const ReviewBox: React.FC<ReviewBoxProps> = ({ title, data }) => {
  return (
    <div className={reviewBox}>
      <h3>{title}</h3>
      <div>
        {data.map((item, index) => (
          <div key={index}>
            <ReviewField item={item} />
            {index < data.length - 1 && <Divider spacing="sm" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewBox;
