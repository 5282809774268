import { FormField, TextInput } from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { fieldErrorHandler } from "./utils/fieldErrorHandler";
import * as Yup from "yup";

export const schema = Yup.object({
  jobTitle: Yup.string().trim(),
});

export type Schema = Yup.InferType<typeof schema>;

export default function JobTitleField() {
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<Schema>();

  return (
    <FormField
      required
      name="jobTitle"
      label={t("Job title")}
      error={fieldErrorHandler("jobTitle", errors, touched)}
    >
      <TextInput
        name="jobTitle"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.jobTitle}
      />
    </FormField>
  );
}
