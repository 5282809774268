import { Spinner } from "@justworkshr/milo-core";
import styles from "./CenteredLoader.module.css";
import { ComponentProps, HTMLAttributes } from "react";

const { container } = styles;

type CenteredLoaderProps = {
  loading: boolean;
  spinnerProps?: ComponentProps<typeof Spinner>;
} & HTMLAttributes<HTMLDivElement>;

function CenteredLoader({
  loading,
  spinnerProps,
  ...containerProps
}: CenteredLoaderProps) {
  return loading ? (
    <div className={container} {...containerProps}>
      <Spinner aria-label="loading" {...spinnerProps} />
    </div>
  ) : null;
}

export default CenteredLoader;
