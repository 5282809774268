import { ReactElement, useContext } from "react";
import styles from "./SettingsRows.module.css";
import { useStepperContext } from "pages/employer-of-record/payments/contexts/stepperFormHandler";
import {
  Base64File,
  capitalize,
  formatKeyForDisplay,
  getDistanceDisplayUnit,
  getTypeDisplayValue,
} from "pages/employer-of-record/payments/utils";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { SupportedCountriesContext } from "../../../../../contexts/supportedCountriesContext";
import { FormPayloadType, SetupStepData } from "../../../Setup/types";

type KeyValuePair = [string, string | Base64File[]];
type FlattenObjectFunction<T> = (obj: T, parentKey?: string) => KeyValuePair[];
type RenderRowsFunction = (data: KeyValuePair[]) => ReactElement[];

const SettingsRows = (): ReactElement => {
  const { formData } = useStepperContext();
  const additionalPayContext = useContext(AdditionalPayContext);
  const { getCountryNameByCode } = useContext(SupportedCountriesContext);

  const flattenObject: FlattenObjectFunction<
    FormPayloadType | SetupStepData<FormPayloadType>
  > = (obj, parentKey = ""): KeyValuePair[] => {
    return Object.entries(obj).flatMap(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        return flattenObject(value, currentKey);
      } else {
        return [[key, value]];
      }
    });
  };

  const renderRows: RenderRowsFunction = (data) => {
    return data.map(([key, value]) => {
      if (!value || key === "type") return <></>;
      if (isBase64FileArray(value)) {
        const [label, _] = getDisplayKeyValue(key, "");
        return (
          <div className={styles.summaryRow} key={key}>
            <div className={styles.rowLabel}>{label}</div>
            <div className={styles.imageReviewContainer}>
              <ImageDisplay files={value} />
            </div>
          </div>
        );
      }

      if (typeof value === "string") {
        const [displayKey, displayVal] = getDisplayKeyValue(key, value);
        return (
          <div className={styles.summaryRow} key={key}>
            <div className={styles.rowLabel}>{displayKey}</div>
            <div className={styles.rowValue}>{displayVal}</div>
          </div>
        );
      }
      return <></>;
    });
  };

  const getDisplayKeyValue = (key: string, value: string): [string, string] => {
    let displayKey = formatKeyForDisplay(key);
    let displayVal = value;
    if (key === "payPeriodId") {
      displayKey = "Pay Date";
      displayVal =
        additionalPayContext?.disbursementDateOptions[value]?.label || value;
    } else if (key === "workCountry") {
      displayVal = getCountryNameByCode(value);
    } else if (key === "receipts") {
      displayKey = "Receipts attached";
    } else if (key === "distance") {
      const distanceUnit = getDistanceDisplayUnit(
        additionalPayContext?.selectedCountry
      ).toLowerCase();
      displayVal = `${displayVal} ${distanceUnit}`;
    }
    return [capitalize(displayKey), capitalize(displayVal)];
  };

  const flattenData = flattenObject(formData.setup);

  return (
    <>
      <div className={styles.summaryRow}>
        <div className={styles.rowLabel}>Pay type</div>
        <div className={styles.rowValue}>
          {capitalize(getTypeDisplayValue(formData.setup.type))}
        </div>
      </div>
      {renderRows(flattenData)}
    </>
  );
};

const ImageDisplay = ({ files }: { files: Base64File[] }) => {
  if (!files) {
    return <div className={styles.rowValue}>No receipts attached</div>;
  }

  return (
    <>
      {files.map((receipt: Base64File, index: number) => (
        <div key={index} className={styles.rowValue}>
          {receipt.name}
        </div>
      ))}
    </>
  );
};

function isBase64FileArray(
  value: Base64File[] | string
): value is Base64File[] {
  if (typeof value === "string") {
    return false;
  }
  return value.every((file) => {
    return typeof file === "object";
  });
}

export default SettingsRows;
