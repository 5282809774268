import { FormField } from "@justworkshr/milo-form";
import clsx from "lib/styling-utils/clsx";
import { useTranslation } from "react-i18next";
import { fieldErrorHandler } from "./utils/fieldErrorHandler";
import { useFormikContext } from "formik";
import * as Yup from "yup";
import styles from "./RoleResponsibilitesField.module.css";

export const schema = Yup.object({
  roleResponsibilities: Yup.string()
    .trim()
    .test("Required", `Required`, function (roleResponsibilities) {
      const jobTitle = this.resolve(Yup.ref("jobTitle")) as string;

      if (jobTitle?.length) return !!roleResponsibilities?.length;

      return true;
    }),
});

export type Schema = Yup.InferType<typeof schema>;

export default function RoleResponsibilitiesField() {
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<Schema>();

  return (
    <FormField
      required
      name="roleResponsibilities"
      label={t("Role Responsibilities")}
      error={fieldErrorHandler("roleResponsibilities", errors, touched)}
    >
      <textarea
        className={clsx(
          styles.textArea,
          errors.roleResponsibilities &&
            touched.roleResponsibilities &&
            styles.error
        )}
        name="roleResponsibilities"
        value={values.roleResponsibilities}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </FormField>
  );
}
