import { ReactElement } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import {
  CF_ADD_CONTRACTOR_LINK,
  INTERNATIONAL_CONTRACTORS_PRODUCT_HELP_LINK,
} from "pages/international-solutions/constants";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import { useGetCompanyDisplayQuery } from "types/generated/operations";
import {
  CUSTOMER_IO_CTA_CLICK_EVENT,
  sendCustomerIOEvent,
} from "app/customer-io";

const ContractorsPriceCard = (): ReactElement => {
  const { divWrapper, mdGap, mdHead, mdPadding, smFont } = styles;

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  const triggerCustomerIOEvent = () => {
    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_CTA_CLICK_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: {
          current_url: window.location.href,
          cta_name: "eor_contractors_add_contractor_button",
          next_url: CF_ADD_CONTRACTOR_LINK,
        },
      },
      accessToken
    );
  };

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="md"
    >
      <div className={`${divWrapper} ${mdGap}`}>
        <div className={`${divWrapper} ${mdPadding}`}>
          <div className={mdHead}>Availability </div>
          <span>
            40+ countries including India, Italy, Germany, Portugal, and
            Argentina.{" "}
            <a
              href={INTERNATIONAL_CONTRACTORS_PRODUCT_HELP_LINK}
              target="_blank"
            >
              <span className={smFont}>See entire list</span>
            </a>
          </span>
        </div>
        <Button
          data-heap-id="add-contractor-button"
          color="brand"
          type="button"
          as="a"
          size="md"
          href={CF_ADD_CONTRACTOR_LINK}
          onClick={triggerCustomerIOEvent}
        >
          Add contractor
        </Button>
      </div>
    </Box>
  );
};

export default ContractorsPriceCard;
