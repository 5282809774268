import { PermissionGroups } from "pages/advisor/common/components";
import { Box, Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, useFormikContext } from "formik";
import { FORM_STEPS } from "../../constants";
import { FormikCheckboxInput } from "lib/formik/FormikFormField";
import styles from "./PermissionsForm.module.css";
import { MouseEvent, useCallback, useEffect } from "react";
import * as Yup from "yup";
import { FieldError } from "../FieldError";

const { section } = styles;

const scrollToTop = () => {
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: "smooth",
  });
};

const useEnsureValidSchema = (
  schema: Yup.Schema,
  onError: (err: Yup.ValidationError) => void
) => {
  const { values } = useFormikContext();
  useEffect(() => {
    try {
      schema.validateSync(values);
    } catch (err) {
      if (!(err instanceof Yup.ValidationError)) {
        throw err;
      }

      onError(err);
    }
  }, [values, onError, schema]);
};

const PermissionsForm = () => {
  const navigate = useNavigate();
  useEnsureValidSchema(
    FORM_STEPS.contact.formSchema,
    useCallback(() => {
      navigate("/firms/invitation/new");
    }, [navigate])
  );

  const { validateForm, isSubmitting } = useFormikContext<{
    permissions: string[];
  }>();
  return (
    <>
      <PermissionGroups />
      <div className={section}>
        <Box
          padding="xl"
          border={{
            borderColor: "neutralSubtle",
            borderWidth: "sm",
            borderRadius: "md",
          }}
        >
          <FormikCheckboxInput
            fieldConfig={{ type: "checkbox" }}
            name="terms"
            label="I recognize that by adding an accounting firm, multiple individuals at that firm may have access to my company’s information. If you don’t want to grant access to the firm, then Cancel and add your contact as a third-party admin."
            value="terms"
          />
          <ErrorMessage
            name="terms"
            render={(msg: string) => <FieldError message={msg} />}
          />
        </Box>
      </div>
      <ActionFooter
        actions={[
          <Button
            variant="outlined"
            as={Link}
            to={"/invitation-center"}
            key="cancel-btn"
          >
            Cancel
          </Button>,
          <Button
            data-testid="submit-button"
            key="submit-btn"
            type="submit"
            loading={isSubmitting}
            onClick={async (event: MouseEvent) => {
              const errors = await validateForm();
              if (errors.permissions?.length ?? 0 > 0) {
                event.preventDefault();
                scrollToTop();
              }
            }}
          >
            Send Invite
          </Button>,
        ]}
      />
    </>
  );
};

export default PermissionsForm;
