import { Dialog, DialogProps } from "@justworkshr/milo-core";
import { Trans, useTranslation } from "react-i18next";

export default function RequestTerminationDialog(
  props: Pick<DialogProps, "isOpen" | "onClose">
) {
  const { t } = useTranslation();

  const requestTerminationEmailLink = (
    <a href="mailto:international-support@justworks.com">
      international-support@justworks.com
    </a>
  );

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      showCloseButton
      title={t("Want to schedule a termination?")}
      size="xs"
    >
      <Trans>
        We're here to help. Email {requestTerminationEmailLink} stating that you
        want us to begin the termination process for this employee. Please
        include their full name in the email.
      </Trans>
    </Dialog>
  );
}
