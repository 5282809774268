import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectOption,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps, type FormikValues } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import { NATIONALITIES } from "../../constants";
import styles from "../../ProfileInfo.module.css";
import { compactTaxId } from "../../taxIdUtils";
import { ProfileInfoFormTypeNL as ProfileInfoFormType } from "../../types";
import BaseFormFragment, {
  initialValues as baseInitialValues,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as identifierNumberInitialValues,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
const { inputField } = styles;

const state_key = "profile-info";

const NATIONALITIES_OPTIONS = [
  {
    value: "",
    description: "",
  },
  ...NATIONALITIES,
] as const;

const ProfileInfoNL: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const onFormSubmit = (values: FormikValues) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        identifierNumber: profileInfoIdentifierNumber,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  /* NL specific fields */
  const schemaNL = Yup.object({
    nationality: Yup.string()
      .oneOf(NATIONALITIES_OPTIONS.map((nationality) => nationality.value))
      .required("Required."),
    nlArbeidsgehandicapte: Yup.boolean().required("This field is required."),
    nlWajongBenefitsEligible: Yup.boolean().required("This field is required."),
  });
  const formSchema = schemaNL
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "NL"));
  type SchemaType = Yup.InferType<typeof schemaNL>;
  type FormType = SchemaType;

  const initialValuesNL: FormType = {
    nationality: "",
    nlArbeidsgehandicapte: false,
    nlWajongBenefitsEligible: false,
  };

  /* END NL specific fields */

  const getInitialValues = (): ProfileInfoFormType["profileInfo"] => {
    const initialValues = {
      ...baseInitialValues,
      ...identifierNumberInitialValues,
    };

    const defaultInitialValues = profileInfo?.profileInfo || initialValues;

    return {
      ...initialValuesNL,
      ...defaultInitialValues,
    };
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={formSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            values,
          }: FormikProps<ProfileInfoFormType["profileInfo"]>) => {
            const handleBooleanChange = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              setFieldValue(e.target.name, e.target.value === "true");
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* <!-- NL specific fields --> */}
                  <div className={inputField}>
                    <FormField
                      name="nationality"
                      required
                      label={t("Nationality")}
                      error={touched?.nationality ? errors?.nationality : ""}
                    >
                      <Select
                        name="nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nationality}
                        placeholder={
                          values.nationality.length > 0
                            ? undefined
                            : t("Select Nationality")
                        }
                      >
                        {NATIONALITIES_OPTIONS.map((country) => (
                          <SelectOption
                            value={country?.value}
                            key={country?.value}
                          >
                            {country?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      name="nlWajongBenefitsEligible"
                      required
                      label={t("Are you eligible for Wajong benefits?")}
                    >
                      <RadioButtonGroup
                        name="nlWajongBenefitsEligible"
                        onChange={handleBooleanChange}
                        onBlur={handleBlur}
                        value={String(values.nlWajongBenefitsEligible)}
                      >
                        <RadioButton name="yes" value="true" label={t("Yes")} />
                        <RadioButton name="no" value="false" label={t("No")} />
                      </RadioButtonGroup>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      name="nlArbeidsgehandicapte"
                      required
                      label={t(
                        "Do you have a disability for which you may require reasonable accommodations (Arbeidsgehandicapte)?"
                      )}
                    >
                      <RadioButtonGroup
                        name="nlArbeidsgehandicapte"
                        onChange={handleBooleanChange}
                        onBlur={handleBlur}
                        value={String(values.nlArbeidsgehandicapte)}
                      >
                        <RadioButton name="yes" value="true" label={t("Yes")} />
                        <RadioButton name="no" value="false" label={t("No")} />
                      </RadioButtonGroup>
                    </FormField>
                  </div>

                  {/* <!-- END NL specific fields --> */}

                  <IdentifierNumberFormFragment workCountry="NL" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoNL;
