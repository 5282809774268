import {
  useGetIntegrationSolutionDataQuery,
  useGetPmtIntegrationFrameworkDataQuery,
} from "types/generated/operations";
import {
  checkPmtIntegrationsEnabled,
  setPmtIntegrationVariables,
} from "../utils";
import { useMemo } from "react";

export type integrationMarketplaceDataHookResponse = {
  integrationSolutionDataQueryResult: ReturnType<
    typeof useGetIntegrationSolutionDataQuery
  >;
  pmtIntegrationFrameworkDataResult: ReturnType<
    typeof useGetPmtIntegrationFrameworkDataQuery
  >;
  pmtIntegrationsEnabled: boolean;
  combinedLoading: boolean;
  combinedError: Error | undefined;
  hasIntegrationsData: boolean;
  authenticatedMember:
    | NonNullable<
        ReturnType<typeof useGetIntegrationSolutionDataQuery>["data"]
      >["authenticatedMember"]
    | undefined;
};

const useIntegrationMarketplaceData =
  (): integrationMarketplaceDataHookResponse => {
    const integrationSolutionDataQueryResult =
      useGetIntegrationSolutionDataQuery();
    const {
      data: legacyIntegrationsData,
      loading: legacyIntegrationsLoading,
      error: legacyIntegrationsError,
    } = integrationSolutionDataQueryResult;

    const pmtIntegrationFrameworkDataResult =
      useGetPmtIntegrationFrameworkDataQuery({
        variables: {
          ...setPmtIntegrationVariables(),
        },
      });
    const {
      data: pmtIntegrationsData,
      loading: pmtIntegrationsDataLoading,
      error: pmtIntegrationsDataError,
    } = pmtIntegrationFrameworkDataResult;
    const pmtIntegrationsEnabled = checkPmtIntegrationsEnabled(
      legacyIntegrationsData?.authenticatedMember.company.uuid
    );
    const combinedLoading =
      legacyIntegrationsLoading || pmtIntegrationsDataLoading;
    const combinedError =
      legacyIntegrationsError ||
      (pmtIntegrationsEnabled ? pmtIntegrationsDataError : undefined);
    const hasLegacyIntegrationData =
      !!legacyIntegrationsData && !legacyIntegrationsLoading;
    const hasPmtIntegrationData =
      !!pmtIntegrationsData && !pmtIntegrationsDataLoading;
    const hasIntegrationsData = pmtIntegrationsEnabled
      ? hasPmtIntegrationData && hasLegacyIntegrationData
      : hasLegacyIntegrationData;

    const authenticatedMember = legacyIntegrationsData?.authenticatedMember;
    return useMemo(() => {
      return {
        integrationSolutionDataQueryResult,
        pmtIntegrationFrameworkDataResult,
        pmtIntegrationsEnabled,
        combinedLoading,
        combinedError,
        hasIntegrationsData,
        authenticatedMember,
      };
    }, [
      integrationSolutionDataQueryResult,
      pmtIntegrationFrameworkDataResult,
      pmtIntegrationsEnabled,
      combinedLoading,
      combinedError,
      hasIntegrationsData,
      authenticatedMember,
    ]);
  };

export default useIntegrationMarketplaceData;
