import React from "react";
import FormConfirmation from "./FormConfirmation";

const FormConfirmationWrapper: React.FC = () => {
  let deadline: string | undefined;

  return <FormConfirmation deadline={deadline} />;
};

export default FormConfirmationWrapper;
