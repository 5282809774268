import invoiceStyles from "../../../../CombinedInvoice.module.css";
import { FC } from "react";
import { toCurrencyFormat } from "../../../../../utils";
import basicInvoiceTableStyles from "./SummaryEntry.module.css";
import { CategoryEntry } from "pages/employer-of-record/combined-invoice/types";

const { padlessGrid } = invoiceStyles;
const { tableRow, lineItem, variableData, grossAmount } =
  basicInvoiceTableStyles;

interface SummaryEntryProps {
  entry: CategoryEntry;
  currency: string;
  payPeriodRangeText: string;
}

export const SummaryEntry: FC<SummaryEntryProps> = ({
  entry,
  currency,
  payPeriodRangeText,
}) => {
  return (
    <div>
      <div className={`${padlessGrid} ${tableRow}`}>
        <div
          className={lineItem}
        >{`${entry.description} ${payPeriodRangeText}`}</div>
        <div className={variableData} />
        <div className={grossAmount}>
          {toCurrencyFormat(entry.amount, currency)}
        </div>
      </div>
    </div>
  );
};
