import { CombinedInvoiceData } from "pages/employer-of-record/combined-invoice/types";

function generateCombinedEoRInvoiceDataResponse(): CombinedInvoiceData[] {
  return [
    {
      baseCurrency: "USD",
      companyId: "company_01j06vy4dyen3v5xm7es1drv5t",
      pendingDisbursementOrders: [],
      processingDate: "2024-06-09",
      status: "pending",
      total: "1102798",
      invoices: [
        {
          id: "01hqa4ps7tavwhjd9s05xzgbb3",
          status: "pending",
          payPeriodRange: {
            startDate: "2024-06-01",
            endDate: "2024-06-09",
            payDate: "2024-06-08",
            paymentDueDate: "2024-06-07",
          },
          country: {
            code: "CA",
            description: "Canada",
          },
          currency: {
            code: "CAD",
            description: "Canadian Dollar",
          },
          usdFxRate: 0.74,
          localCurrencyTotal: "406416",
          categories: [
            {
              description: "Salaries \u0026 Wages",
              type: "SALARIES",
              total: "300000",
              entries: [
                {
                  description: "Anita Dayoff",
                  amount: 300000,
                },
              ],
            },
            {
              description: "Bonuses",
              type: "BONUSES",
              total: "70000",
              entries: [
                {
                  description: "Anita Dayoff",
                  amount: 70000,
                },
              ],
            },
            {
              description: "Employer tax contributions",
              type: "EMPLOYER_TAXES",
              total: "22000",
              entries: [
                {
                  description: "Anita Dayoff",
                  amount: 22000,
                },
              ],
            },
            {
              description: "Employer benefits contributions",
              type: "EMPLOYER_BENEFITS",
              total: "11416",
              entries: [
                {
                  description: "Anita Dayoff",
                  amount: 11416,
                },
              ],
            },
            {
              description: "Taxable benefits (Cash)",
              type: "TAXABLE_BENEFITS_CASH",
              entries: [
                {
                  description: "Canada Test Employee",
                  amount: 3000,
                },
              ],
              note: "Applicable taxes are included in your employer tax contributions.",
              total: "3000",
            },
            {
              description: "Taxable benefits (Non-cash)",
              type: "TAXABLE_BENEFITS_NON_CASH",
              entries: [
                {
                  description: "Canada Test Employee",
                  amount: 5000,
                },
              ],
              note:
                "The total taxable amount is the amount of taxable non-cash benefits you recorded for your employees " +
                "and is not added to your invoice amount. Applicable taxes are included in your employer tax contributions.",
              total: "5000",
            },
          ],
          fees: {
            justworksFee: 145100,
            serviceFee: 4900,
            securityDeposit: 1000,
            securityDepositRefund: -1000,
          },
        },
        {
          id: "0333a4ps7tavwhjd9s05xzgaa1",
          status: "pending",
          payPeriodRange: {
            startDate: "2024-06-01",
            endDate: "2024-06-10",
            payDate: "2024-06-09",
            paymentDueDate: "2024-06-07",
          },
          country: {
            code: "GB",
            description: "United Kingdom",
          },
          currency: {
            code: "GBP",
            description: "Great British Pound",
          },
          usdFxRate: 1.25,
          localCurrencyTotal: "403416",
          categories: [
            {
              description: "Salaries \u0026 Wages",
              type: "SALARIES",
              total: "300000",
              entries: [
                {
                  description: "UK TEST",
                  amount: 300000,
                },
              ],
            },
            {
              description: "Bonuses",
              type: "BONUSES",
              total: "70000",
              entries: [
                {
                  description: "UK TEST",
                  amount: 70000,
                },
              ],
            },
            {
              description: "Employer tax contributions",
              type: "EMPLOYER_TAXES",
              total: "22000",
              entries: [
                {
                  description: "UK TEST",
                  amount: 22000,
                },
              ],
            },
            {
              description: "Employer benefits contributions",
              type: "EMPLOYER_BENEFITS",
              total: "11416",
              entries: [
                {
                  description: "UK TEST",
                  amount: 11416,
                },
              ],
            },
            {
              description: "Employer benefits contributions",
              type: "EMPLOYER_BENEFITS",
              total: "11416",
              entries: [
                {
                  description: "UK TEST",
                  amount: 11416,
                },
              ],
            },
            {
              description: "Employer benefits contributions",
              type: "EMPLOYER_BENEFITS",
              total: "11416",
              entries: [
                {
                  description: "UK TEST",
                  amount: 11416,
                },
              ],
            },
          ],
          fees: {
            justworksFee: 150000,
            serviceFee: 0,
            securityDeposit: 1000,
            securityDepositRefund: -1000,
          },
        },
        {
          id: "a9b8c7d6e5f4g3h2i1j0k9l8m7n6",
          status: "pending",
          payPeriodRange: {
            startDate: "2024-06-01",
            endDate: "2024-06-09",
            payDate: "2024-06-08",
            paymentDueDate: "2024-06-07",
          },
          country: {
            code: "MX",
            description: "Mexico",
          },
          currency: {
            code: "MXN",
            description: "Mexican Peso",
          },
          usdFxRate: 0.0618,
          localCurrencyTotal: "12163000",
          categories: [
            {
              description: "Salaries & Wages",
              type: "SALARIES",
              total: "10400000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 5400000,
                },
                {
                  description: "MX Test",
                  amount: 5000000,
                },
              ],
              note: null,
            },
            {
              description: "Bonuses",
              type: "BONUSES",
              total: "800000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 300000,
                },
                {
                  description: "MX Test",
                  amount: 500000,
                },
              ],
              note: null,
            },
            {
              description: "Commissions",
              type: "COMMISSIONS",
              total: "400000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 200000,
                },
                {
                  description: "MX Test",
                  amount: 200000,
                },
              ],
              note: null,
            },
            {
              description: "Expenses",
              type: "REIMBURSEMENTS",
              total: "100000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 50000,
                },
                {
                  description: "MX Test",
                  amount: 50000,
                },
              ],
              note: null,
            },
            {
              description: "Employer tax contributions",
              type: "EMPLOYER_TAXES",
              total: "395000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 210000,
                },
                {
                  description: "MX Test",
                  amount: 185000,
                },
              ],
              note: null,
            },
            {
              description: "Employer benefits contributions",
              type: "EMPLOYER_BENEFITS",
              total: "60000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 30000,
                },
                {
                  description: "MX Test",
                  amount: 30000,
                },
              ],
              note: null,
            },
            {
              description: "Taxable benefits (Cash)",
              type: "TAXABLE_BENEFITS_CASH",
              total: "8000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 4000,
                },
                {
                  description: "MX Test",
                  amount: 4000,
                },
              ],
              note: "Applicable taxes are included in your employer tax contributions.",
            },
            {
              description: "Taxable benefits (Non-cash)",
              type: "TAXABLE_BENEFITS_NON_CASH",
              total: "10000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 5000,
                },
                {
                  description: "MX Test",
                  amount: 5000,
                },
              ],
              note: "The total taxable amount is the amount of taxable non-cash benefits you recorded for your employees and is not added to your invoice amount. Applicable taxes are included in your employer tax contributions.",
            },
            {
              description: "Christmas bonus accrual (aguinaldo)",
              type: "AGUINALDO_ACCRUAL",
              total: "10000",
              entries: [
                {
                  description: "Mexico Test",
                  amount: 5000,
                },
                {
                  description: "MX Test",
                  amount: 5000,
                },
              ],
              note: "In Mexico, employers must pay employees a bonus (aguinaldo) equal to at least 15 days of wages.\n\nBy law, employees must receive their aguinaldo by December 20.\n\nHere’s how it works:\n- We collect funds from you in equal increments each pay cycle.\n- The employee receives their aguinaldo in December.",
            },
          ],
          fees: {
            justworksFee: 119800,
            serviceFee: 0,
            securityDeposit: 0,
            securityDepositRefund: 0,
          },
        },
      ],
      fees: {
        serviceFees: {
          description: "Justworks Fees",
          entries: [
            {
              description: "Justworks Employer of Record Services Fees",
              amount: 295100,
            },
            {
              description: "Justworks Canada Employer of Record Services Fees",
              amount: 4900,
            },
          ],
          total: "300000",
        },
        otherFees: {
          description: "Other Fees",
          entries: [
            {
              description: "Security Deposit",
              amount: 2000,
            },
            {
              description: "Security Deposit Refund",
              amount: -2000,
            },
          ],
          total: "0",
        },
      },
    },
  ];
}

class DSLCombinedEoRInvoice {
  responseData: CombinedInvoiceData;

  constructor() {
    const data = generateCombinedEoRInvoiceDataResponse();

    this.responseData = data[0];
  }
}

export function aCombinedEoRInvoice(): DSLCombinedEoRInvoice {
  return new DSLCombinedEoRInvoice();
}
