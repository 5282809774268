import styles from "./RoleFragment.module.css";
import * as Yup from "yup";
import { PayBasis } from "pages/employer-of-record/invite/types";
import { SupportedCountriesData } from "pages/employer-of-record/contexts/supportedCountriesContext";
import PayRateField, {
  schema as payRateFieldSchema,
} from "pages/employer-of-record/forms/PayRateField";
import JobTitleField, {
  schema as jobTitleFieldSchema,
} from "pages/employer-of-record/forms/JobTitleField";
import RoleResponsibilitiesField, {
  schema as roleResponsibilitiesFieldSchema,
} from "pages/employer-of-record/forms/RoleResponsibilitiesField";

export const schema = (
  supportedCountriesData: SupportedCountriesData,
  previousPay?: number
) =>
  jobTitleFieldSchema
    .concat(roleResponsibilitiesFieldSchema)
    .concat(payRateFieldSchema(supportedCountriesData, previousPay));

export type Schema = Yup.InferType<ReturnType<typeof schema>>;

export default function RoleFragment() {
  return (
    <div className={styles.container}>
      <div className={styles.textField}>
        <PayRateField payBasis={PayBasis.Salary} />
      </div>

      <div className={styles.textField}>
        <JobTitleField />
      </div>

      <div className={styles.textArea}>
        <RoleResponsibilitiesField />
      </div>
    </div>
  );
}
