import styles from "./FormWrapper.module.css";
import { Header } from "../Header/Header";
import { Spinner, UnknownError } from "@justworkshr/milo-core";
import FormWorkflowStepper from "../FormWorkflowStepper/FormWorkflowStepper";
import { Form8973ContextProvider, Form8973Context } from "../../contexts";
import { useGetForm8973 } from "../../hooks/useGetForm8973";
import { STATUS_TO_WORKFLOW_MAP } from "../../constants";
import { useContext, useEffect } from "react";
import { mapCompanyDataToInitialValues } from "pages/form-8973/utils";
import { WORKFLOW_STEPS } from "pages/form-8973/constants";

const { fullscreenWrapper, spinner } = styles;

export const FormWrapper = () => {
  return (
    <Form8973ContextProvider>
      <FormWrapperContent />
    </Form8973ContextProvider>
  );
};

const FormWrapperContent = () => {
  const { data, error, loading } = useGetForm8973();
  const companyInitialData = data?.form8973;
  const { setCompanyValues, setStep } = useContext(Form8973Context);

  useEffect(() => {
    if (companyInitialData) {
      const mappedData = mapCompanyDataToInitialValues(companyInitialData);

      setCompanyValues((prevValues) => ({
        ...prevValues,
        ...mappedData,
      }));
    }

    const signatureStatus =
      companyInitialData?.unsignedForm8973Signature?.status;

    if (signatureStatus) {
      const newStep =
        STATUS_TO_WORKFLOW_MAP[signatureStatus] || WORKFLOW_STEPS.STEP_ONE;
      setStep(newStep);
    }
  }, [companyInitialData, setCompanyValues, setStep]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className={spinner}>
          <Spinner />
        </div>
      );
    }

    if (error) {
      return <UnknownError />;
    }

    return (
      <>
        <Header title="Form8973" />
        <FormWorkflowStepper />
      </>
    );
  };

  return <div className={fullscreenWrapper}>{renderContent()}</div>;
};
