import { Route, Routes } from "react-router-dom";
import { Page404 } from "pages/page-404";
import { FormWrapper } from "./components/FormWrapper/FormWrapper";
export const Form8973 = () => {
  return (
    <Routes>
      <Route index element={<FormWrapper />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Form8973;
