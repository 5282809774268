import { GetEoRCombinedInvoicesQuery } from "types/generated/operations";
import { CombinedInvoiceData } from "./types";
import { getInitialLanguage } from "../components/LanguagePicker/LanguagePicker";

// Parse and return an invoice response as an invoice object
export function parseInvoiceResponse(
  data: GetEoRCombinedInvoicesQuery
): CombinedInvoiceData | null {
  // Gracefully handle empty results or malformed json_data
  try {
    const invoiceData = data.combinedInvoices;
    if (invoiceData && invoiceData[0]) {
      // the BigInt type is incorrectly being typed as a number when it's really coming as a string from the backend.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return invoiceData[0];
    }
    return null;
  } catch {
    return null;
  }
}

// compares two dates and returns true if the "A Date" is after the "B Date"
export function compareDates({
  dateA,
  dateB,
  timeA = "00:00:00",
  timeB = "00:00:00",
  locale = "en-US",
  timeZone = "America/New_York",
}: {
  dateA: string;
  dateB: string;
  timeA?: string;
  timeB?: string;
  locale?: string;
  timeZone?: string;
}) {
  const a = new Date(
    new Date(`${dateA} ${timeA}`).toLocaleString(locale, { timeZone })
  );
  const b = new Date(
    new Date(`${dateB} ${timeB}`).toLocaleString(locale, { timeZone })
  );
  return a > b;
}

export function getGMTOffset(date: Date, tzname: string) {
  const longOffsetFormatter = new Intl.DateTimeFormat("en-US", {
    timeZone: tzname,
    timeZoneName: "longOffset",
  });
  const longOffsetString = longOffsetFormatter.format(date);
  const gmtOffset = longOffsetString.split("GMT")[1];
  return gmtOffset;
}

export function isAfter3pmETOfGivenDate(date: string) {
  const THREE_PM = "15:00:00";

  // construct the date at 3pm with the ET timezone offset.
  // The ET timezone offset will make this an objective point in time.
  // We can't hard code the offset because it changes with daylight savings time
  const gmtOffset = getGMTOffset(new Date(date), "America/New_York");
  const threePMETOnGivenDate = new Date(`${date}T${THREE_PM}${gmtOffset}`);

  // get the current date and time which has the local timezone built in.
  // This is an objective point in time.
  const now = new Date();

  // compare both date objects by converting both to ISO strings, so we're comparing
  // the times on the same offset (UTC).
  return now.toISOString() > threePMETOnGivenDate.toISOString();
}

// Converts a date to format: MM/DD/YY
export function toBannerProcessingDateFormat(date: Date) {
  return new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    timeZone: "UTC",
  }).format(date);
}

// Converts a date to format: Month D, Yr
export function toProcessingDateFormat(date: Date) {
  return new Intl.DateTimeFormat(getInitialLanguage(), {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  }).format(date);
}

// Converts a date to format: Mon D, Yr
export function toPaymentDateFormat(date: Date) {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  }).format(date);
}

// Returns a string formatted as (Mon D, Yr - Mon D, Yr) using a start and end date
export function toPayPeriodRangeFormat(
  startDate: string,
  endDate: string
): string {
  return `(${toPaymentDateFormat(new Date(startDate))} - ${toPaymentDateFormat(
    new Date(endDate)
  )})`;
}

// Builds a string representing the local currency payout
export const localCurrencyPayoutText = (
  localCurrencyLength: number,
  localCurrencyPayoutPrefix: string,
  localCurrencyPayoutSuffix: string
) => {
  return localCurrencyLength > 1
    ? `${localCurrencyPayoutPrefix} ${localCurrencyLength} ${localCurrencyPayoutSuffix}`
    : "";
};

// Starting with the payroll run on 9/15/2024, we will no longer be supporting wiring instructions for payments.
// Returns true if the invoice date is after 9/15/2024
const WIRING_CUTOFF_DATE = "2024-08-31";
export const isPastWiringInstructionsCutoff = (
  invoiceDate: string,
  invoiceTime: string = "00:00:00",
  locale: string = "en-us",
  timeZone: string = "America/New_York"
) => {
  return compareDates({
    dateA: invoiceDate,
    timeA: invoiceTime,
    dateB: WIRING_CUTOFF_DATE,
    locale,
    timeZone,
  });
};
