import React, { Fragment, ReactElement, cloneElement } from "react";
import styles from "./Stepper.module.css";
import { StepProps } from "./Step";

const {
  stepperDisplay,
  verticalStepperDisplay,
  horizontalLineDisplay,
  verticalLineDisplay,
} = styles;

export interface StepperProps {
  direction?: "vertical" | "horizontal";
  hideStepLabels?: boolean;
  children?: Array<ReactElement<StepProps>>;
}

const Stepper = ({
  direction = "horizontal",
  hideStepLabels = false,
  children,
}: StepperProps): ReactElement => {
  const stepperDisplayClasses = [stepperDisplay];
  if (direction === "vertical") {
    stepperDisplayClasses.push(verticalStepperDisplay);
  }

  const lineClasses: Array<string> = [];
  if (direction === "horizontal") {
    lineClasses.push(horizontalLineDisplay);
  } else if (direction === "vertical") {
    lineClasses.push(verticalLineDisplay);
  }

  const line = <li className={lineClasses.join(" ")} aria-hidden />;
  const processedSteps = children?.map((step, index) => (
    <Fragment key={step.key}>
      {cloneElement(step, { direction, hideLabel: hideStepLabels })}
      {index < children.length - 1 ? line : <></>}
    </Fragment>
  ));
  return <ol className={stepperDisplayClasses.join(" ")}>{processedSteps}</ol>;
};

export default Stepper;
