import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import { ChangeEventHandler, FocusEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useGetCurrentMemberDepartmentsQuery } from "types/generated/operations";

type DepartmentSelectFieldProps = {
  value?: string;
  error?: string;
  handleBlur?: FocusEventHandler<HTMLSelectElement>;
  handleChange?: ChangeEventHandler<HTMLSelectElement>;
  name: string;
};

export default function DepartmentSelectField({
  value,
  error,
  name,
  handleBlur,
  handleChange,
}: DepartmentSelectFieldProps) {
  const { t } = useTranslation();
  const { data, loading } = useGetCurrentMemberDepartmentsQuery();

  if (data) {
    const departments = data.currentMember.company.departments;

    return (
      <FormField name={name} label={t("Department")} error={error}>
        <Select
          name={name}
          placeholder={t("Select department")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
        >
          <SelectOption value="">{t("No Department")}</SelectOption>
          {departments?.map((department) => {
            const label = department.name;

            return (
              <SelectOption value={department.uuid} key={department.uuid}>
                {label}
              </SelectOption>
            );
          })}
        </Select>
      </FormField>
    );
  } else {
    return (
      <FormField name={name} label={t("Department")}>
        <Select
          name={name}
          placeholder={
            loading ? t("Loading departments...") : t("No departments found!")
          }
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          disabled
        />
      </FormField>
    );
  }
}
