import * as Yup from "yup";

const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024; // 10 MB
const EIN_REGEX = /^\d{9}$/;

export const stepOneValidationSchema = () => {
  return Yup.object({
    einValid: Yup.string().required("EIN verification is required"),
    einChangeReason: Yup.string().test(
      "einChangeReason",
      "Change reason is required",
      function (value) {
        if (this.parent.einValid === "false") {
          return value !== undefined;
        }
        return true;
      }
    ),
    correctedEin: Yup.string().test("correctedEin", function (value) {
      if (
        this.parent.einChangeReason === "einClericalError" &&
        this.parent.einValid === "false"
      ) {
        if (!value) {
          return this.createError({ message: "Corrected EIN is required" });
        }
        if (!EIN_REGEX.test(value)) {
          return this.createError({
            message: "Corrected EIN must be a 9-digit number",
          });
        }
      }
      return true;
    }),
    einSupportingDocument: Yup.mixed()
      .nullable()
      .test("einSupportingDocument", function (value) {
        if (
          this.parent.einChangeReason === "einClericalError" &&
          this.parent.einValid === "false"
        ) {
          if (!value) {
            return this.createError({
              message: "Supporting document is required",
            });
          }
          if (value instanceof File && value.size > MAX_FILE_UPLOAD_SIZE) {
            return this.createError({
              message: "File size must be 10 MB or less",
            });
          }
          const validTypes = ["application/pdf", "image/jpeg", "image/png"];
          if (value instanceof File && !validTypes.includes(value.type)) {
            return this.createError({ message: "Unsupported file type" });
          }
        }
        return true;
      }),
    legalEntityNameValid: Yup.string().required(
      "Legal name verification is required"
    ),
    legalEntityNameChangeReason: Yup.string().test(
      "legalEntityNameChangeReason",
      "Change reason is required",
      function (value) {
        if (this.parent.legalEntityNameValid === "false") {
          return value !== undefined;
        }
        return true;
      }
    ),
    correctedLegalName: Yup.string().test(
      "correctedLegalName",
      function (value) {
        if (
          this.parent.legalEntityNameChangeReason ===
            "legalEntityNameClericalError" &&
          this.parent.legalEntityNameValid === "false"
        ) {
          if (!value) {
            return this.createError({ message: "Corrected name is required" });
          }
        }
        return true;
      }
    ),
    legalEntityNameSupportingDocument: Yup.mixed()
      .nullable()
      .test("legalEntityNameSupportingDocument", function (value) {
        if (
          this.parent.legalEntityNameChangeReason ===
            "legalEntityNameClericalError" &&
          this.parent.legalEntityNameValid === "false"
        ) {
          if (!value) {
            return this.createError({
              message: "Supporting document is required",
            });
          }
          if (value instanceof File && value.size > MAX_FILE_UPLOAD_SIZE) {
            return this.createError({
              message: "File size must be 10 MB or less",
            });
          }
          const validTypes = ["application/pdf", "image/jpeg", "image/png"];
          if (value instanceof File && !validTypes.includes(value.type)) {
            return this.createError({ message: "Unsupported file type" });
          }
        }
        return true;
      }),
  });
};

export const stepTwoValidationSchema = () => {
  return Yup.object({
    tradeNameValid: Yup.string().required("Trade name is required"),
    correctedTradeName: Yup.string().test(
      "correctedTradeName",
      function (value) {
        if (this.parent.tradeNameValid === "false") {
          if (!value) {
            return this.createError({
              message: "Corrected trade name is required",
            });
          }
        }
        return true;
      }
    ),
    addressValid: Yup.string().required("Address verification is required"),
    correctedAddressLine1: Yup.string().test(
      "correctedAddressLine1",
      function (value) {
        if (this.parent.addressValid === "false") {
          if (!value) {
            return this.createError({ message: "Address line 1 is required" });
          }
        }
        return true;
      }
    ),
    correctedAddressCity: Yup.string().test(
      "correctedAddressCity",
      function (value) {
        if (this.parent.addressValid === "false") {
          if (!value) {
            return this.createError({ message: "City is required" });
          }
        }
        return true;
      }
    ),
    correctedAddressState: Yup.string().test(
      "correctedAddressState",
      function (value) {
        if (this.parent.addressValid === "false") {
          if (!value) {
            return this.createError({ message: "State is required" });
          }
        }
        return true;
      }
    ),
    correctedAddressZip: Yup.string().test(
      "correctedAddressZip",
      function (value) {
        if (this.parent.addressValid === "false") {
          if (!value) {
            return this.createError({ message: "Zip code is required" });
          }
        }
        return true;
      }
    ),
    correctedAddressCountry: Yup.string().test(
      "correctedAddressCountry",
      function (value) {
        if (this.parent.addressValid === "false") {
          if (!value) {
            return this.createError({ message: "Country is required" });
          }
        }
        return true;
      }
    ),
  });
};
