import EditableProfile from "../components/EditableProfile/EditProfile";
import { useGetEoRMemberByUuidQuery } from "types/generated/operations";
import { useProfileContextData } from "../contexts/ProfileInfoContext";
import { useParams } from "react-router-dom";
import { Alert, Box, Spinner } from "@justworkshr/milo-core";
import styles from "./EditProfilePage.module.css";
import EditableProfileHeader from "../components/EditableProfile/components/EditableProfileHeader";
import { useState } from "react";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { EOR_PROFILE_PATH } from "../../routes";
import { SUBPATHS } from "../constants";
import { useTranslation } from "react-i18next";

const EditProfilePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { loading: loadingMemberId, data: memberData } =
    useGetEoRMemberByUuidQuery({
      variables: { memberUuid: id },
    });
  const { profileInfo, loadingProfileInfo } = useProfileContextData(); //use this to set init values.
  const isAdmin = !!id;
  const linkToProfile = isAdmin
    ? `/employer-of-record${EOR_PROFILE_PATH}/${id}/${SUBPATHS.PERSONAL_INFO}`
    : "/account-settings";
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { getFeatureFlag } = useGetFeatureFlag();

  const isEditingEnabled = getFeatureFlag("release-admin-edit-eor-ee-profile");

  if (typeof isEditingEnabled !== "boolean" || !isEditingEnabled) {
    return <div>Forbidden</div>;
  }

  let pageContent;

  if (loadingProfileInfo || loadingMemberId) {
    pageContent = (
      <Box padding="2xl">
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      </Box>
    );
  } else if (memberData && profileInfo) {
    pageContent = (
      <EditableProfile
        memberId={memberData.eorMember.id}
        profile={profileInfo.eorEmployeeProfile}
        onError={setErrorMessage}
        clearError={() => setErrorMessage(undefined)}
        linkToProfile={linkToProfile}
      />
    );
  } else {
    // This should never happen
    pageContent = <div>Unknown Error</div>;
    console.error("The EoR member and/or profile data could not be retrieved.");
  }

  return (
    <>
      {errorMessage && <Alert color="destructive">{errorMessage}</Alert>}
      <div className={styles.pageContainer}>
        <EditableProfileHeader
          linkToProfile={linkToProfile}
          title={t("Edit Profile")}
        />
        {pageContent}
      </div>
    </>
  );
};

export default EditProfilePage;
