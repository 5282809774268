import {
  useGetForm8973Query,
  useGetCompanyDisplayQuery,
} from "types/generated/operations";

export function useGetForm8973() {
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useGetCompanyDisplayQuery();

  const companyUuid = companyData?.authenticatedMember?.company?.uuid ?? "";

  const {
    data: formData,
    loading: formLoading,
    error: formError,
  } = useGetForm8973Query({
    skip: companyLoading || !!companyError,
    variables: {
      companyUuid: companyUuid,
    },
  });

  return {
    data: { ...formData },
    loading: companyLoading || formLoading,
    error: companyError || formError,
  };
}
