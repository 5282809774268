import { Form, FormField, SingleDatePicker } from "@justworkshr/milo-form";
import { useCompensationChange } from "./CompensationChangeContext";
import CompensationChangeFooter from "./CompensationChangeFooter";
import styles from "./EditStep.module.css";
import { schema as roleFragmentSchema } from "./RoleFragment";
import { Formik } from "formik";
import { useContext } from "react";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import * as Yup from "yup";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import ContractDetails from "../JobInformation/components/ContractDetails";
import { convertFromCents } from "../../utils";
import { useTranslation } from "react-i18next";
import { Card } from "@justworkshr/milo-core";
import { fieldErrorHandler } from "pages/employer-of-record/forms/utils/fieldErrorHandler";

const ONE_YEAR_FROM_NOW = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
);

const ONE_YEAR_AGO = new Date(
  new Date().setFullYear(new Date().getFullYear() - 1)
);

export default function EditStep() {
  const { t } = useTranslation();
  const { values, onNext, setValues } = useCompensationChange();
  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const { profileInfo } = useProfileContextData();

  const minDate = profileInfo?.eorEmployeeProfile.contract?.startDate
    ? new Date(
        profileInfo?.eorEmployeeProfile.contract?.startDate + "T12:00:00"
      )
    : ONE_YEAR_AGO;

  const workCountry = profileInfo?.eorEmployeeProfile.employment?.workCountry;
  const previousPay = convertFromCents(
    profileInfo?.eorEmployeeProfile.role?.payRate
  );

  if (!workCountry) return <div>Error</div>;

  const schema = Yup.object({
    effectiveDate: Yup.string().required("Must provide an effective date"),
  }).concat(
    roleFragmentSchema(supportedCountriesData, previousPay ?? undefined)
  );

  type Schema = Yup.InferType<typeof schema>;

  function onSubmit(formValues: Schema) {
    setValues({
      ...values,
      ...formValues,
      effectiveDate: formValues.effectiveDate ?? undefined,
    });
    onNext();
  }

  return (
    <Formik
      initialValues={{
        ...values,
        workCountry,
        effectiveDate: values.effectiveDate ?? "",
      }}
      validateOnMount
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
        <Form className={styles.form} onSubmit={handleSubmit}>
          <ContractDetails editInPlace />

          <Card title={t("When will the change be effective?")}>
            <div className={styles.effectiveDateField}>
              <FormField
                label={t("Effective date")}
                required
                message={t(
                  "Selecting a date in the past may add retroactive pay to the next payroll. Changes in the middle of a pay period will be prorated accordingly."
                )}
                error={fieldErrorHandler("effectiveDate", errors, touched)}
              >
                <SingleDatePicker
                  name="effectiveDate"
                  value={values.effectiveDate}
                  onChange={(date) => {
                    if (!date) {
                      setFieldValue("effectiveDate", "");
                    } else {
                      date.setHours(12, 0, 0, 0);
                      setFieldValue(
                        "effectiveDate",
                        date.toISOString().split("T")[0]
                      );
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("effectiveDate");
                  }}
                  maxDate={ONE_YEAR_FROM_NOW}
                  minDate={minDate}
                />
              </FormField>
            </div>
          </Card>

          <CompensationChangeFooter />
        </Form>
      )}
    </Formik>
  );
}
