import { Alert } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./RenderAlert.module.css";

const { alert, headerRow, header, content } = styles;
interface RenderInputTypes {
  headline?: string;
  message?: React.ReactNode;
}

const RenderInput = ({ headline = "", message = "" }: RenderInputTypes) => {
  return (
    <div className={alert}>
      <Alert color="info" visible>
        <div className={headerRow}>
          <SystemIcon iconName="info-circle" />
          <div className={header}>{headline}</div>
        </div>
        <div className={content}>{message}</div>
      </Alert>
    </div>
  );
};

export default RenderInput;
