import { IconButton } from "@justworkshr/milo-core";
import invoiceStyles from "../../CombinedInvoice.module.css";
import styles from "./InvoiceDetailedSummary.module.css";
import { FC, useContext } from "react";
import { toCurrencyFormat } from "../../../utils";
import { useTranslation } from "react-i18next";
import { CombinedInvoiceContext } from "../../contexts/CombinedInvoiceContext";
import { CombinedInvoiceFees } from "../../types";
import { DetailedSummaryCategory } from "./components/DetailedSummaryCategory";

const { padlessGrid } = invoiceStyles;
const {
  invoiceSummaryContainer,
  invoiceSummaryHeaderRow,
  invoiceSummaryHeader,
  invoiceSummaryTotalContainer,
  invoiceSummaryTotal,
  breakdownSectionButtonContainer,
} = styles;

interface InvoiceDetailedSummaryProps {
  fees: CombinedInvoiceFees;
  baseCurrency: string;
  summaryIndex: number;
}

export const InvoiceDetailedSummary: FC<InvoiceDetailedSummaryProps> = ({
  fees,
  baseCurrency,
  summaryIndex,
}) => {
  const { t } = useTranslation();
  const { isBreakdownSectionExpanded, setIsBreakdownSectionExpanded } =
    useContext(CombinedInvoiceContext);
  const breakdownButtonHandler = () => {
    setIsBreakdownSectionExpanded((data) => ({
      ...data,
      [summaryIndex]: !data[summaryIndex],
    }));
  };

  const categories = [];
  if (fees.serviceFees.entries.length > 0) {
    categories.push(fees.serviceFees);
  }
  if (fees.otherFees.entries.length > 0) {
    categories.push(fees.otherFees);
  }

  // This is a temporary patch to fix the incorrect amount being shown in the invoice.
  // This is happening because when we made the total field type BigInt, Javascript
  // casts it to a string. To really fix this, we have to change the type
  // in the schema in clockwork_web so it continues to be sent as a number.
  // There is a Cents type that we might be able to use.
  const feeTotal =
    Number(fees.serviceFees.total) + Number(fees.otherFees.total);

  return (
    <div className={invoiceSummaryContainer}>
      <div className={padlessGrid} id={`invoiceSummary_${summaryIndex}`}>
        <div className={invoiceSummaryHeaderRow}>
          <div className={breakdownSectionButtonContainer}>
            <IconButton
              size="md"
              color="neutral"
              type="button"
              variant="ghost"
              onClick={breakdownButtonHandler}
              title={
                isBreakdownSectionExpanded[summaryIndex] ? "Collapse" : "Expand"
              }
              iconName={
                isBreakdownSectionExpanded[summaryIndex]
                  ? "chevron-down"
                  : "chevron-right"
              }
            />
          </div>
          <span className={invoiceSummaryHeader}>{t("Fees")}</span>
        </div>
        <div className={invoiceSummaryTotalContainer}>
          <span className={invoiceSummaryHeader}>
            {t("Total")}
            <span className={invoiceSummaryTotal}>
              {toCurrencyFormat(feeTotal, baseCurrency, true)}
            </span>
          </span>
        </div>
      </div>

      {isBreakdownSectionExpanded[summaryIndex] && (
        <DetailedSummaryCategory
          categories={categories}
          currency={baseCurrency}
          summaryIndex={summaryIndex}
          categoryTotal={feeTotal}
        />
      )}
    </div>
  );
};
