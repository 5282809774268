import { CountryCode, format, parsePhoneNumber } from "libphonenumber-js";
import moment from "moment";
import { PayBasis } from "pages/employer-of-record/invite/types";

import {
  EorEmployeePersonalInfo,
  EorManagerInfo,
} from "types/employer-of-record";
import { NATIONALITIES } from "../../onboarding/profile-info/constants";

export const getEmployeeName = (
  info?: EorEmployeePersonalInfo | EorManagerInfo
) => {
  if (!info) {
    return "";
  }

  const preferredName = info.preferredFirstName || "";
  const firstName = preferredName ? preferredName : info.firstName || "";
  const middleName = info.middleName ? `${info.middleName} ` : "";
  const lastName = info.lastName || "";

  return `${firstName} ${middleName}${lastName}`.trim();
};

export const getEmployeeDisplayName = (
  info?: EorEmployeePersonalInfo
): string => {
  return info?.preferredFirstName || info?.firstName || "the employee";
};

export const getEmployeeLegalName = (info: EorEmployeePersonalInfo) => {
  if (!info) return "";

  const firstName = info.firstName || "";
  const middleName = info.middleName ? `${info.middleName} ` : "";
  const lastName = info.lastName || "";

  return `${firstName} ${middleName}${lastName}`;
};

export const formatDate = (
  date: string | null | undefined,
  format = "MMMM D, YYYY"
) => {
  if (!date) {
    return "";
  }

  const formattedDate = moment(date).format(format);
  return formattedDate;
};

export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

type compareObjType = { [key: string]: string | null | undefined };
export const deepEqualWithSkip = (
  obj1: compareObjType,
  obj2: compareObjType,
  keysToSkip: string[]
) => {
  if (!obj1 || !obj2) {
    return false;
  }
  const keys1: string[] = Object.keys(obj1);
  const keys2: string[] = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (keysToSkip.includes(key)) {
      continue;
    }

    const value1 = obj1[key] ?? "";
    const value2 = obj2[key] ?? "";

    if (
      typeof value1 === "object" &&
      typeof value2 === "object" &&
      !deepEqualWithSkip(value1, value2, keysToSkip)
    ) {
      return false;
    }

    if (value1 !== value2) {
      return false;
    }
  }

  return true;
};

export const getCountryCode = (code: string): CountryCode => {
  switch (code) {
    case "ca":
      return "CA";
    default:
      return "US";
  }
};

export const formatPhoneNumber = (
  value: string | null | undefined,
  code: string
) => {
  if (!value) return "";

  const countryCode = getCountryCode(code);
  const phoneNumber = parsePhoneNumber(value);
  const numberAreaCode = phoneNumber.countryCallingCode;
  // Format the parsed phone number as international
  const formattedPhoneNumber = format(value, countryCode, "NATIONAL");
  return `+${numberAreaCode} ${formattedPhoneNumber}`;
};

export const convertFromCents = (
  payRate: number | null | undefined
): number | null => {
  return payRate ? payRate / 100 : null;
};

export const convertToCents = (pay: number): number => pay * 100;

export const formatPay = ({
  currencySymbol,
  payRate,
  payBasis,
  currency,
}: {
  currencySymbol: string;
  payRate: number | null | undefined;
  currency: string;
  payBasis: PayBasis;
}): string => {
  const basis = payBasis === "salary" ? "per year" : "per hour";
  const pay = convertFromCents(payRate)?.toLocaleString();

  return `${currencySymbol}${pay} ${currency?.toUpperCase()} ${basis}`;
};

export const getNameFromCountryCode = (code: string) => {
  const countryDetails = NATIONALITIES.find(
    (object) => object["value"] == code
  );
  return countryDetails ? countryDetails["description"] : "";
};
