import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { EorEmployeePersonalInfo } from "types/employer-of-record";
import Row from "../../../Row/Row";

export default function GeneralInformationNL(): ReactElement {
  const { profileInfo } = useProfileContextData();

  const personalInfo: EorEmployeePersonalInfo =
    profileInfo?.eorEmployeeProfile.personalInfo;

  const identifierNumber = personalInfo?.identifierNumber;

  return (
    <Row
      label="BSN (Burger Service Number)"
      value={identifierNumber}
      required
    />
  );
}
