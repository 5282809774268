import { ReactElement } from "react";
import styles from "./TimeOff.module.css";
import WorkingOnAHoliday from "./components/StatutoryHolidayHours/WorkingOnAHoliday";
import AdditionalLeave from "./components/UnpaidLeave/AdditionalLeave";

export default function TimeOff(): ReactElement {
  return (
    <>
      <div className={styles.cardSection}>
        <AdditionalLeave />
        <WorkingOnAHoliday />
      </div>
    </>
  );
}
