import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import heroImage from "../../images/hero-images/ramp-hero.svg";
import logo from "../../images/integration-partner-logos/ramp-logo.svg";
import horizontalLogo from "../../images/horizontal-logos/ramp-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  justworksPermissionsCopy,
  partnerPermissionsCopy,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import ExternalLinkButton from "../../partner-description-page/components/InstanceDetailsPanel/ExternalLinkButton/ExternalLinkButton";
import React from "react";

export const rampConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: logo,
      integrationName: "Ramp",
      integrationDescription:
        "Automatically sync employee information from Justworks to Ramp",
      integrationCategory: "Expense management",
      requiredPermissions: HR_PERMISSIONS,
      slug: "merge__ramp_v1",
      pdpUrl: "/cf/integrations/merge__ramp_v1",
    },

    pdp: {
      headerDescription:
        "Ramp is the ultimate platform for modern finance teams. Automate your finance operations with Ramp's all-in-one solution for corporate cards, expense management, travel and more.",
      heroImageUrl: heroImage,
      horizontalLogoUrl: horizontalLogo,
      instanceDetailsPanelConfig: {
        hideLastActive: true,
        customDisconnectButton: (
          <ExternalLinkButton
            url="https://app.ramp.com/settings/integrations/hris"
            copy="Disconnect Integration"
            variant="outlined"
          />
        ),
        customSetupButton: (
          <ExternalLinkButton
            url="https://app.ramp.com/settings/integrations/hris"
            copy="Get Started"
          />
        ),
      },
      resourceCard: {
        links: [
          {
            title: "Ramp in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/29604042417435-Ramp-Integration",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about Ramp",
            href: "https://ramp.com",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "Ramp + Justworks",
          subheader:
            "Ramp integrates with Justworks to help you simplify onboarding, issue employee benefits, and control spend at scale.",
          list: [
            {
              summary: "Automated account management",
              detail:
                "Manage employee information in Justworks, and let this integration seamlessly provision and deprovision team members to their Ramp accounts.",
            },
            {
              summary: "Efficient data-syncing",
              detail:
                "Eliminate manual work, saving time, reducing errors, and boosting overall efficiency. Data syncs from Justworks to Ramp every 24 hours, ensuring accurate, up-to-date information.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What member data will we share with Ramp?",
            subheader:
              "While the integration is connected, Ramp will automatically pull in the following data from Justworks for your US-based team members:",
            sharedDataGroups: [
              {
                sharedData: [
                  "Name",
                  "Email",
                  "Direct Manager",
                  "Department",
                  "Location",
                  "Employment Status",
                  "Start Date",
                  "Termination Date",
                  "Company Name",
                ],
              },
            ],
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In Ramp",
                detail: partnerPermissionsCopy("Ramp", ["Admins and Owners"]),
              },
            ],
          },
        },
      },
      disconnectModalContent: {
        title: "Disconnect from Ramp",
        body: <span></span>,
        disconnectButtonText: "Disconnect",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/sections/360001292171-Account-Administration",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
