export enum WORKFLOW_STEPS {
  STEP_ONE = "STEP_ONE",
  STEP_TWO = "STEP_TWO",
  REVIEW_PAGE = "REVIEW_PAGE",
  SIGN_DOCUMENT = "SIGN_DOCUMENT",
  COMPLETE = "COMPLETE",
  PENDING = "PENDING",
  REVIEW = "REVIEW",
  CONFIRMATION_PENDING = "CONFIRMATION_PENDING",
  CONFIRMATION_RECEIVED = "CONFIRMATION_RECEIVED",
  CONFIRMATION_SUCCESS = "CONFIRMATION_SUCCESS",
}

// Workflow Steps Number Labels
export const WORKFLOW_STEPS_NUMBER_LABEL: Record<string, number> = {
  [WORKFLOW_STEPS.STEP_ONE]: 1,
  [WORKFLOW_STEPS.STEP_TWO]: 2,
  [WORKFLOW_STEPS.REVIEW_PAGE]: 2,
  [WORKFLOW_STEPS.SIGN_DOCUMENT]: 3,
};

export const WORKFLOW_PREVIOUS_STEP: Record<string, string> = {
  [WORKFLOW_STEPS.STEP_TWO]: WORKFLOW_STEPS.STEP_ONE,
  [WORKFLOW_STEPS.REVIEW_PAGE]: WORKFLOW_STEPS.STEP_TWO,
  [WORKFLOW_STEPS.SIGN_DOCUMENT]: WORKFLOW_STEPS.REVIEW_PAGE,
};

export const STATUS_TO_WORKFLOW_MAP: Record<string, string> = {
  pending_review: WORKFLOW_STEPS.CONFIRMATION_RECEIVED,
  customer_completed: WORKFLOW_STEPS.CONFIRMATION_SUCCESS,
};

export const defaultCompanyValues = {
  uuid: "",
  ein: "",
  legalEntityName: "",
  tradeName: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  addressCountry: "",
};

export const defaultFormValues = {
  einValid: "",
  einChangeReason: "",
  correctedEin: "",
  einSupportingDocument: null,
  legalEntityNameValid: "",
  legalEntityNameChangeReason: "",
  correctedLegalName: "",
  legalEntityNameSupportingDocument: null,
  tradeNameValid: "",
  correctedTradeName: "",
  addressValid: "",
  correctedAddressLine1: "",
  correctedAddressLine2: "",
  correctedAddressCity: "",
  correctedAddressState: "",
  correctedAddressZip: "",
  correctedAddressCountry: "",
};

export const formValueLabels = {
  einClericalError: "Clerical error or mistake",
  einChanged: "The EIN changed",
  legalEntityNameClericalError: "Clerical error or mistake",
  legalEntityNameChanged: "The name changed",
};

export const GENERIC_RADIO_OPTIONS = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const EIN_CHANGE_REASON_OPTIONS = [
  { value: "einClericalError", label: "Clerical error or mistake" },
  { value: "einChanged", label: "The EIN changed" },
];

export const LEGAL_ENTITY_NAME_CHANGE_REASON_OPTIONS = [
  { value: "legalEntityNameClericalError", label: "Clerical error or mistake" },
  { value: "legalEntityNameChanged", label: "The name changed" },
];

export const ERM_14_FORM_LINK =
  "https://www.ncci.com/Articles/Documents/UW_ERM14Form.pdf";
