import { useMemo } from "react";
import { IntegrationCardProps } from "../components/IntegrationCard/integration-card";
import { buildCardSections } from "../utils";
import { AllIntegrationCategories } from "../components/CardCategorySelect/card-category-select";

const useIntegrationMarketplaceCardSections = (
  cards: IntegrationCardProps[],
  categories: string[]
): ReturnType<typeof buildCardSections> => {
  return useMemo(() => {
    const filteredCards = cards.filter((card) => {
      if (
        categories.length === 0 ||
        categories.includes(AllIntegrationCategories)
      ) {
        return true;
      }
      return categories.includes(card.integrationCategory);
    });

    return buildCardSections(filteredCards);
  }, [cards, categories]);
};

export default useIntegrationMarketplaceCardSections;
