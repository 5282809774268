import { PageHeader } from "@justworkshr/milo-core";
import { buildWebPath } from "lib/resource-finder";
import styles from "./EditableProfileHeader.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { useTranslation } from "react-i18next";

type Props = {
  linkToProfile: string;
  title: string;
};

export default function EditableProfileHeader({ linkToProfile, title }: Props) {
  const { t } = useTranslation();

  return (
    <PageHeader
      linkPrevious={
        <a href={buildWebPath(`/cf${linkToProfile}`)}>
          <div className={styles.backContainer}>
            <SystemIcon iconName="arrow-left" color="brand" size="small" />
            <span>{t("Back to Profile")}</span>
          </div>
        </a>
      }
      title={title}
    />
  );
}
