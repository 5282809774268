import { ReactElement } from "react";
import { FormField, TextInput } from "@justworkshr/milo-form";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import styles from "../../../../SetupFormGenerator.module.css";
import { GeneratedComponentProp } from "../../../../types";
import { FormPayloadType } from "pages/employer-of-record/payments/components/Setup/types";
import { getDistanceDisplayUnit } from "pages/employer-of-record/payments/utils";

export function DistanceInput<T extends FormPayloadType = FormPayloadType>({
  field,
  value,
  accessor,
  touchedField,
  errorField,
  handleBlur,
  handleChange,
  workCountry,
}: GeneratedComponentProp<T>): ReactElement {
  return (
    <div className={`${errorField && touchedField ? styles.error : ""}`}>
      <FormField
        label={field.label}
        required={field.required}
        error={errorField && touchedField ? errorField : ""}
      >
        <div className={styles.distanceInput}>
          {field.message && (
            <div className={styles.description}>
              <MessageWithLink {...field.message} />
            </div>
          )}
          <TextInput
            name={`setUp.${accessor}`}
            onBlur={handleBlur}
            value={value}
            onChange={handleChange}
          />
          <div
            className={`${styles.distanceWrapper} ${
              errorField && touchedField ? styles.error : ""
            }`}
          >
            {getDistanceDisplayUnit(workCountry)}
          </div>
        </div>
      </FormField>
    </div>
  );
}

export default DistanceInput;
