import "@justworkshr/milo-design-tokens/build/css/tokens.css";
import { Button } from "@justworkshr/milo-core";
import styles from "./Header.module.css";
import { buildClockworkWebPath } from "lib/resource-finder/resource-finder";

interface HeaderProps {
  title: string;
}

const { headerCenter } = styles;

export const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <Button
          leftIcon="arrow-left"
          variant="ghost"
          as="a"
          href={buildClockworkWebPath("/admin/documents")}
        >
          Exit
        </Button>
      </div>
      <div className={headerCenter}>
        <div>{title}</div>
      </div>
    </div>
  );
};
