import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  PageHeader,
  Spinner,
} from "@justworkshr/milo-core";
import {
  ActionFooter,
  CheckboxInput,
  InputMessage,
} from "@justworkshr/milo-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePermissionsContext } from "../../contexts/PermissionsProvider";
import {
  useAcceptFirmInviteMutation,
  useDeclineFirmInviteMutation,
  useGetFirmInviteQuery,
} from "types/generated/operations";
import styles from "./FirmsInvitationPage.module.css";
import { FirmInvitationTable } from "./components/FirmInvitationTable";

const {
  firmsPage,
  checkboxContainer,
  checkboxLabel,
  firmsPageHeader,
  confirmContainer,
  actionFooterContainer,
} = styles;

const FirmInvitationPage = (): JSX.Element => {
  const { firmInviteId } = useParams();
  const [confirmed, setConfirmed] = useState(false);
  const [confirmErrorShown, toggleConfirmErrorShown] = useState(false);
  const [denyModalShown, toggleDenyModalShown] = useState(false);
  const [alertShown, toggleAlertShown] = useState(false);
  const navigate = useNavigate();
  const { permissions, permissionsChanged, loading, error } =
    usePermissionsContext();

  const {
    data: firmInviteData,
    loading: firmInviteLoading,
    error: firmInviteError,
  } = useGetFirmInviteQuery({
    variables: { firmInviteId: firmInviteId ?? "" },
    skip: !firmInviteId,
  });

  const firmInvite = firmInviteData?.firmInvite;

  const [acceptFirmInvite, { loading: acceptFirmInviteLoading }] =
    useAcceptFirmInviteMutation();

  const [declineFirmInvite, { loading: declineFirmInviteLoading }] =
    useDeclineFirmInviteMutation();

  if (error || !firmInvite || firmInviteError) {
    return <></>;
  }

  const { uuid, firmName, invitingFirmUser } = firmInvite;

  const handleConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
    toggleConfirmErrorShown(false);
  };

  const handleDecline = async () => {
    const response = await declineFirmInvite({
      variables: {
        firmInviteUuid: uuid,
      },
    });
    if (response.data?.declineFirmInvite?.success) {
      navigate("/firms");
    } else {
      toggleAlertShown(true);
    }
  };

  const handleAccept = async () => {
    if (confirmed) {
      const response = await acceptFirmInvite({
        variables: {
          firmInviteUuid: uuid,
          permissions,
        },
      });

      if (response.data?.acceptFirmInvite?.success) {
        navigate("/firms");
      } else {
        toggleAlertShown(true);
      }
    } else {
      toggleConfirmErrorShown(true);
    }
  };

  return (
    <>
      <Alert
        onDismiss={() => toggleAlertShown(false)}
        color="destructive"
        visible={alertShown}
        dismissible
      >
        There was an error processing the firm invitation
      </Alert>
      {permissionsChanged && (
        <Alert color="warning">
          The permissions for this firm invitation have been updated. Please
          review them before accepting.
        </Alert>
      )}
      <div className={`${firmsPage} milo--grid`}>
        <PageHeader
          className={firmsPageHeader}
          title="Firm Invitation"
          linkPrevious={<Link to="/firms">Back to Third-Party</Link>}
        />
        {loading || firmInviteLoading ? (
          <Spinner />
        ) : error || !firmInviteData || firmInviteError ? (
          <></>
        ) : (
          <>
            <FirmInvitationTable
              firmUuid={uuid}
              firmName={firmName ?? ""}
              invitingFirmUser={invitingFirmUser}
              permissions={permissions}
            />
            <div className={confirmContainer}>
              <Box
                backgroundColor="neutral"
                border={{
                  borderColor: "disabled",
                  borderRadius: "md",
                  borderWidth: "sm",
                }}
                padding="3xl"
              >
                <div className={checkboxContainer}>
                  <CheckboxInput
                    checked={confirmed}
                    value="confirmation"
                    name="confirmation"
                    id="confirmation"
                    onChange={handleConfirmation}
                  />
                  <label htmlFor="confirmation" className={checkboxLabel}>
                    I recognize that by approving this accounting firm, multiple
                    individuals may have access to my company's information. If
                    you don't want to grant access to the firm, then <b>Deny</b>{" "}
                    and add your contact as a third-party admin.
                  </label>
                </div>
                {confirmErrorShown && (
                  <InputMessage type="error">
                    Be sure to accept the agreement to continue
                  </InputMessage>
                )}
              </Box>
            </div>
            <div className={actionFooterContainer}>
              <ActionFooter
                actions={
                  <>
                    <Button
                      color="brand"
                      variant="ghost"
                      onClick={() => toggleDenyModalShown(true)}
                    >
                      Deny
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleAccept}
                      loading={acceptFirmInviteLoading}
                    >
                      Accept
                    </Button>
                  </>
                }
              />
            </div>
            <Dialog
              onClose={() => toggleDenyModalShown(false)}
              isOpen={denyModalShown}
              primaryButton={
                <Button
                  onClick={handleDecline}
                  type="button"
                  color="destructive"
                  loading={declineFirmInviteLoading}
                >
                  Reject
                </Button>
              }
              secondaryButton={
                <Button
                  onClick={() => toggleDenyModalShown(false)}
                  type="button"
                  variant="ghost"
                >
                  Back
                </Button>
              }
              showCloseButton
              size="sm"
              title="Reject this request?"
            >
              <div>
                You'll need to repeat the invitation process if you want to add
                this accounting firm in the future.
              </div>
              <br />
              <div>
                <b>Note:</b> If you don't recognize this request and the email
                didn't come from advisor-support@justworks.com, please call us
                immediately at (888) 534-1711.
              </div>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default FirmInvitationPage;
