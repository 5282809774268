import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectOption,
  SingleDatePicker,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps, type FormikValues } from "formik";
import omit from "lodash/omit";
import moment from "moment";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import { NATIONALITIES } from "../../constants";
import styles from "../../ProfileInfo.module.css";
import { compactTaxId } from "../../taxIdUtils";
import BaseFormFragment, {
  initialValues as BASE_INITIAL_VALUES,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as IDENTIFIER_NUMBER_INITIAL_VALUES,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
import {
  esEducationalQualificationOptions,
  esMaritalStatusOptions,
  esSexOptions,
  esSsnRegex,
} from "./constants.es";
import DateArrayField from "../../components/DateArrayField/DateArrayField";

const { inputField } = styles;

const state_key = "profile-info";

const ProfileInfoES: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const countrySchema = Yup.object({
    nationality: Yup.string().required("This field is required."),
    esDateSinceResidentOfSpain: Yup.string().required(
      "This field is required."
    ),
    esSex: Yup.string().required("This field is required."),
    esMaritalStatus: Yup.string().required("This field is required."),
    esEducationalQualification: Yup.string().required(
      "This field is required."
    ),
    esSsn: Yup.string()
      .required("This field is required.")
      .matches(esSsnRegex, t("Must be a valid Social Security Number.")),
    esUnemploymentStatus: Yup.boolean().required("This field is required."),
    esChildrensBirthDates: Yup.array().of(Yup.string()),
    esNie: Yup.string()
      .test("check-nationality", "This field is required.", (value, ctx) =>
        ctx.parent.nationality === "ES" ? true : Boolean(value?.length)
      )
      .matches(/[A-Z]{1}[0-9]{7}[A-Z]{1}/, t("Must be a valid NIE.")),
  });

  const validationSchema = countrySchema
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "ES"));

  type CountrySchema = Yup.InferType<typeof countrySchema>;
  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const INITIAL_VALUES: CountrySchema = {
    nationality: "",
    esDateSinceResidentOfSpain: "",
    esSex: "",
    esMaritalStatus: "",
    esEducationalQualification: "",
    esSsn: "",
    esUnemploymentStatus: false,
    esChildrensBirthDates: [],
    esNie: "",
  };

  const PRIVATE_KEYS: readonly (keyof CountrySchema)[] = ["esNie", "esSsn"];

  const DEFAULT_VALUES = {
    ...BASE_INITIAL_VALUES,
    ...IDENTIFIER_NUMBER_INITIAL_VALUES,
    ...INITIAL_VALUES,
  } as const;

  const getInitialValues = (): ValidationSchema => {
    if (!profileInfo?.profileInfo) return DEFAULT_VALUES;

    const sanitizedValues = omit(profileInfo?.profileInfo, PRIVATE_KEYS);

    return {
      ...DEFAULT_VALUES,
      ...sanitizedValues,
    };
  };

  // FormikValues should be replaced with ValidationSchema
  // But conflicts with profileEsChildrensBirthDates type
  const onFormSubmit = (values: FormikValues) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    // Filter out empty values and send the correct date format
    const profileEsChildrensBirthDates = values.esChildrensBirthDates
      .filter(Boolean)
      .map((dateStr: string) => formatDate(dateStr, "YYYY-MM-DD"));

    onSubmit({
      profileInfo: {
        ...values,
        esChildrensBirthDates: profileEsChildrensBirthDates,
        esNumChildren: profileEsChildrensBirthDates.length,
        identifierNumber: profileInfoIdentifierNumber || null,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            values,
          }: FormikProps<ValidationSchema>) => {
            const handleBooleanChange = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              setFieldValue(e.target.name, e.target.value === "true");
            };

            const handleDateChange = (field: string, date: Date) => {
              setFieldValue(field, moment(date).format("YYYY-MM-DD"));
            };

            const fieldErrorHandler = (name: keyof ValidationSchema) => {
              if (!errors || !touched) return;
              if (errors[name] && touched[name]) {
                return errors[name]?.toString();
              }
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* <!-- ES specific fields --> */}
                  <div className={inputField}>
                    <FormField
                      required
                      name="nationality"
                      label={t("Nationality")}
                      error={fieldErrorHandler("nationality")}
                    >
                      <Select
                        name="nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.nationality)}
                      >
                        {NATIONALITIES.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      name="esDateSinceResidentOfSpain"
                      error={fieldErrorHandler("esDateSinceResidentOfSpain")}
                      label={t("Date since resident of Spain")}
                      required
                    >
                      <SingleDatePicker
                        name="esDateSinceResidentOfSpain"
                        onBlur={handleBlur}
                        onChange={(date) =>
                          handleDateChange("esDateSinceResidentOfSpain", date)
                        }
                        value={values.esDateSinceResidentOfSpain}
                        minDate={moment("1920-01-01").toDate()}
                        maxDate={moment().toDate()}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="esSex"
                      label={t("Sex")}
                      error={fieldErrorHandler("esSex")}
                      message={t(
                        "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences."
                      )}
                    >
                      <Select
                        name="esSex"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.esSex)}
                        placeholder={
                          values.esSex.length ? undefined : t("Select Sex")
                        }
                      >
                        {esSexOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="esMaritalStatus"
                      label={t("Marital Status")}
                      error={fieldErrorHandler("esMaritalStatus")}
                    >
                      <Select
                        name="esMaritalStatus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.esMaritalStatus)}
                        placeholder={
                          values.esMaritalStatus.length
                            ? undefined
                            : "Select Marital Status"
                        }
                      >
                        {esMaritalStatusOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="esEducationalQualification"
                      label={t("Educational Qualification")}
                      error={fieldErrorHandler("esEducationalQualification")}
                    >
                      <Select
                        name="esEducationalQualification"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.esEducationalQualification)}
                        placeholder={
                          values.esEducationalQualification.length
                            ? undefined
                            : t("Select Educational Qualification")
                        }
                      >
                        {esEducationalQualificationOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="esSsn"
                      label={t("Social Security ID (Número de afiliación)")}
                      error={fieldErrorHandler("esSsn")}
                    >
                      <TextInput
                        name="esSsn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.esSsn)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="esUnemploymentStatus"
                      label={t("Unemployment status (Desempleado)")}
                      error={fieldErrorHandler("esUnemploymentStatus")}
                    >
                      <RadioButtonGroup
                        name="esUnemploymentStatus"
                        onChange={handleBooleanChange}
                        onBlur={handleBlur}
                        value={String(values.esUnemploymentStatus)}
                      >
                        <RadioButton value="true" label={t("Yes")} />
                        <RadioButton value="false" label={t("No")} />
                      </RadioButtonGroup>
                    </FormField>
                  </div>

                  <DateArrayField
                    name="esChildrensBirthDates"
                    label={t("Children's birth dates")}
                    message={t(
                      "Add the dates for all the children. Leave blank if no children."
                    )}
                    addButtonLabel={t("Add another child")}
                  />

                  <div className={inputField}>
                    <FormField
                      required={values.nationality !== "ES"}
                      name="esNie"
                      label={t("NIE (Número de Identificación de Extranjero)")}
                      error={fieldErrorHandler("esNie")}
                    >
                      <TextInput
                        name="esNie"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.esNie)}
                      />
                    </FormField>
                  </div>

                  {/* <!-- END ES specific fields --> */}

                  <IdentifierNumberFormFragment
                    workCountry="ES"
                    required={values.nationality === "ES"}
                  />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoES;
