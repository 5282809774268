export const OVERVIEW_CONTENT = {
  header: {
    title: "What is long term disability insurance?",
    headerInfo:
      "Long term disability insurance covers a portion of an employee’s income during an extended period of a disabling illness or accident. This benefit can help to ease the employee’s concerns of financial hardship by providing income while they are unable to work.",
    headerSubInfo:
      "Together, short term disability and long term disability provides a comprehensive safety net for life's unforeseen events. Short term disability can act as bridge into long term disability—with long term disability providing ongoing support for employees that need the added benefit. Providing employees with financial security and coverage for uncertainty.",
  },
  body: {
    simpleSetup: {
      id: 0,
      title: "Simple set up",
      info: "You can opt to offer this benefit directly in Justworks, and we will automatically extend it to your employees.",
    },
    automatedBilling: {
      id: 1,
      title: "Automated billing",
      info: "Depending on the option you choose, your company or enrolled employees will be billed automatically for coverage. New eligible employees will be added to the policy when they’re hired or become eligible.",
    },
    easyFileClaims: {
      id: 2,
      title: "Easy file claims",
      info: "Employees work directly with Justworks to file any claims, and they receive benefit payments directly from MetLife.",
    },
  },
};

export const OPTIONS_AND_COSTS_CONTENT = {
  header: {
    title: "What can I offer my employees?",
    headerInfo:
      "Through our PEO, you can offer two types of long term disability (LTD) policies: Employer- paid (noncontributory LTD) and employee-paid (voluntary LTD). Employees can only have one type of policy. If you choose to offer noncontributory LTD insurance, we will not extend the voluntary LTD insurance offering to your employees.",
  },
  bodyTitle: "What does it cost?",
  body: {
    noncontributory_ltd_content: {
      id: 0,
      subtitle: "Noncontributory long term disability (employer paid)",
      info: "There are two options for employer-paid policies. If you select one of these, it will be automatically extended to all employees working 30 or more hours per week who satisfy all other eligibility requirements in the insurance policy.",
    },
    voluntary_ltd_content: {
      id: 1,
      subtitle: "Voluntary long term disability (employee paid)",
      info: "Employees can opt into this coverage once, at the time of hire or upon eligibility. You can offer this to your employees at no additional cost. Only employees working 30 or more hours per week are eligible to sign up for this benefit.",
    },
  },
  costTableInfo: {
    employerPaidOptionOne: {
      optionId: "option_1",
      optionLabel: "Option 1: Noncontributory (employer paid)",
      coverageAmount: "60% of pre-disability earnings, up to $5,000 per month",
      costs:
        "$0.12 per $100 of covered benefit/payroll (max monthly cost of $10.00 per employee)",
      amount: "500000",
      planType: "ltd",
    },
    employerPaidOptionTwo: {
      optionId: "option_2",
      optionLabel: "Option 2: Noncontributory (employer paid)",
      coverageAmount: "60% of pre-disability earnings, up to $10,000 per month",
      costs:
        "$0.168 per $100 of covered benefit/payroll (max monthly cost of $28.00 per employee)",
      amount: "1000000",
      planType: "ltd",
    },
    employeePaidOption: {
      optionId: "option_3",
      optionLabel: "Option 3: Voluntary (employee paid)",
      coverageAmount: "60% of pre-disability earnings, up to $5,000 per month",
      costs:
        "$0.141 per $100 of covered benefit/payroll (max monthly cost of $11.75 per employee)",
      amount: null,
      planType: "vol_ltd",
    },
  },
};

export const FAQ_CONTENT = {
  employeeUse: {
    id: 0,
    title: "What can my employees use long term disability for?",
    info: "Long term disability (LTD) is a wage replacement program that an employer may provide to its employees. This benefit is to be used when an employee has a disabling event that reduces the employee’s ability to work and earn wages from the employer.",
    subInfo:
      "The disabling event cannot be an on-the-job injury, which would fall under the Workers' Compensation program.",
  },
  eligibleEmployees: {
    id: 1,
    title: "What kind of employees are eligible for these benefits?",
    info: "Full time employees, part-time employees who work more than 30 hours, and paid owners are all eligible for both voluntary and non-contributory LTD. Unpaid owners are only eligible for non-contributory LTD.",
  },
  eliminationPeriod: {
    id: 2,
    title: "What is an elimination period?",
    info: "The elimination period is how long a policyholder must wait after they are initially unable to work before they can receive benefits. For LTD, it is the greater of the short term disability maximum benefit period or 180 calendar days. For example, if your employee is on short term disability and it reaches 26 weeks, they could transition to long term disability.",
  },
  waitingPeriod: {
    id: 3,
    title: "Is there a waiting period before an employee can take leave?",
    info: "There is no waiting period, once an eligible employee has coverage, they are able to use the benefit.",
  },
  employeeClaims: {
    id: 4,
    title: "How do my employees file claims?",
    info: "Employees are able to file online, right from their benefits center.",
  },
};
