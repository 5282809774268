import { ReactElement } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, PageHeader, Spinner } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import {
  FormPermissionsAlert,
  FormStatusAlert,
  PermissionGroups,
} from "pages/advisor/common/components";
import { usePermissionsContext } from "../../contexts/PermissionsProvider";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useFormError } from "pages/advisor/common/hooks";
import { expandPermissions } from "pages/advisor/common/utils";
import styles from "./EditPermissionsPage.module.css";
import { useGetFirmInviteQuery } from "types/generated/operations";

const { editPermissionsPage, notifications } = styles;

const EditPermissionsPage = () => {
  const { firmInviteId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { scrollToError, formErrorRef } = useFormError();

  const { permissions, setPermissions, loading, error } =
    usePermissionsContext();

  const {
    data: firmInviteData,
    loading: firmInviteLoading,
    error: firmInviteError,
  } = useGetFirmInviteQuery({
    variables: { firmInviteId: firmInviteId ?? "" },
    skip: !firmInviteId,
  });

  const firmInvite = firmInviteData?.firmInvite;

  if (loading || firmInviteLoading) {
    return <Spinner />;
  }

  if (error || !firmInvite || firmInviteError) {
    return <></>;
  }

  async function submit(values: { permissions: string[] }) {
    setPermissions(expandPermissions(values.permissions));
    navigate(`/firm-invitation/${firmInviteId}`);
  }

  return (
    <EditPermissions firmName={firmInvite.firmName ?? ""}>
      <Formik
        enableReinitialize
        validationSchema={Yup.object().shape({
          permissions: Yup.array()
            .of(Yup.string())
            .min(1, "You need to select at least one permission"),
        })}
        initialValues={{
          permissions,
        }}
        onSubmit={submit}
      >
        {({ validateForm, isSubmitting, status, errors }) => {
          return (
            <Form>
              <div
                ref={formErrorRef}
                className={
                  status || errors.permissions?.length ? notifications : ""
                }
              >
                <FormStatusAlert />
                <FormPermissionsAlert />
              </div>
              <PermissionGroups />
              <ActionFooter
                actions={[
                  <Button
                    key="cancel-permissions"
                    variant="ghost"
                    as={Link}
                    to={`/firm-invitation/${firmInviteId}`}
                  >
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="save-permissions"
                    type="submit"
                    loading={isSubmitting}
                    onClick={async (event: MouseEvent) => {
                      const errors = await validateForm();
                      if (errors.permissions?.length ?? 0 > 0) {
                        event.preventDefault();
                        scrollToError();
                      }
                    }}
                  >
                    {t("Save")}
                  </Button>,
                ]}
              />
            </Form>
          );
        }}
      </Formik>
    </EditPermissions>
  );
};

interface EditPermissionsProps {
  children: ReactElement;
  firmName: string;
}

const EditPermissions = ({ children, firmName }: EditPermissionsProps) => {
  const { t } = useTranslation();

  return (
    <div className={editPermissionsPage}>
      <PageHeader title={t("Edit firm invitation permissions")}>
        {firmName}
      </PageHeader>
      {children}
    </div>
  );
};

export default EditPermissionsPage;
