import styles from "./FormHeader.module.css";

const { formHeader, formTitle, formDescription } = styles;
const FormHeader: React.FC = () => {
  return (
    <div className={formHeader}>
      <h2 className={formTitle}>Company and Justworks agreement</h2>
      <p className={formDescription}>
        This form notifies the IRS that your company has entered into a service
        contract with Justworks.
      </p>
    </div>
  );
};

export default FormHeader;
