import {
  RadioButtonGroup,
  RadioButton,
  TextInput,
  Select,
  SelectOption,
  FileInput,
} from "@justworkshr/milo-form";
import { FormikFormField } from "lib/formik/FormikFormField";
import { useFormikContext } from "formik";
import { FormikFormValuesType } from "../../types";
import styles from "./RenderInput.module.css";

const { questions, subLabel, questionLabel, textAboveStyle, textBelowStyle } =
  styles;
interface RenderInputTypes {
  name: string;
  label: string;
  type: string;
  options?: Array<{ label: string; value: string }>;
  required?: boolean;
  placeholder?: string;
  textAbove?: string;
  textBelow?: string;
}

const RenderInput = ({
  name,
  label,
  type,
  options = [],
  required = false,
  placeholder = "",
  textAbove = undefined,
  textBelow = undefined,
}: RenderInputTypes) => {
  const { values, setFieldValue } = useFormikContext<FormikFormValuesType>();
  const acceptFileTypes = ["image/png", "image/jpeg", "application/pdf"];

  const handleFileChange = (file?: File | File[]) => {
    const selectedFile = Array.isArray(file) ? file[0] : file;
    const currentValue = values[name as keyof FormikFormValuesType];

    setFieldValue(name, selectedFile || currentValue);
  };

  const handleFileRemove = () => {
    setFieldValue(name, "");
  };

  const renderInputField = () => {
    switch (type) {
      case "radio":
        return (
          <RadioButtonGroup name={name} orientation="horizontal">
            {options.map((option) => (
              <RadioButton
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioButtonGroup>
        );

      case "text":
        return (
          <TextInput
            name={name}
            required={required}
            placeholder={placeholder}
          />
        );

      case "select":
        return (
          <Select name={name} required={required} placeholder={placeholder}>
            {options.map((option) => (
              <SelectOption key={option.value} value={option.value}>
                {option.label}
              </SelectOption>
            ))}
          </Select>
        );

      case "file":
        return (
          <FileInput
            accept={acceptFileTypes.join(",")}
            multiple={false}
            onFileChange={handleFileChange}
            onFileRemove={handleFileRemove}
            previouslyUploadedFiles={
              values[name as keyof FormikFormValuesType]
                ? [
                    {
                      id: (values[name as keyof FormikFormValuesType] as File)
                        .name,
                      name: (values[name as keyof FormikFormValuesType] as File)
                        .name,
                    },
                  ]
                : []
            }
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={questions}>
      <div className={textAbove ? subLabel : questionLabel}>
        {textAbove && <div className={textAboveStyle}>{textAbove}</div>}
        <FormikFormField name={name} label={label} required={required}>
          {renderInputField()}
        </FormikFormField>
        {textBelow && <div className={textBelowStyle}>{textBelow}</div>}
      </div>
    </div>
  );
};

export default RenderInput;
