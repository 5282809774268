import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useGetFirmInvitePermissionsQuery } from "types/generated/operations";

interface PermissionsContextValue {
  permissions: string[];
  setPermissions: Dispatch<SetStateAction<string[]>>;
  permissionsChanged: boolean;
  loading: boolean;
  error: Error | undefined;
}

interface PermissionsProviderProps {
  children: ReactNode;
}

const PermissionsContext = createContext<PermissionsContextValue>({
  permissions: [],
  setPermissions: () => {
    // do nothing
  },
  permissionsChanged: false,
  loading: false,
  error: undefined,
});

export function usePermissionsContext() {
  const context = useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(
      "usePermissionsContext must be used within a PermissionsProvider"
    );
  }
  return context;
}

export default function PermissionsProvider({
  children,
}: PermissionsProviderProps) {
  const { firmInviteId } = useParams();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [permissionsChanged, setPermissionsChanged] = useState(false);

  const { data, loading, error } = useGetFirmInvitePermissionsQuery({
    variables: { firmInviteId: firmInviteId ?? "" },
    skip: !firmInviteId,
  });

  useEffect(() => {
    if (data) {
      setPermissions(data.firmInvite?.permissions ?? []);
    }
  }, [data]);

  useEffect(() => {
    if (!data?.firmInvite?.permissions) {
      return;
    }

    if (!arraysEqualUnordered(data.firmInvite.permissions, permissions)) {
      setPermissionsChanged(true);
    } else {
      setPermissionsChanged(false);
    }
  }, [data, permissions]);

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        setPermissions,
        permissionsChanged,
        loading,
        error,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
}

const arraysEqualUnordered = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((item, index) => item === sortedArr2[index]);
};
