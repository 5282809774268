import { FormField, SingleDatePicker } from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const FIELD_NAME = "startDate" as const;

export function leadTimeDays(countryCode: string) {
  return countryCode === "PT" ? 15 : 5;
}

export const schema = Yup.object({ [FIELD_NAME]: Yup.string().required() });
export type Schema = Yup.InferType<typeof schema>;

type Props = {
  countryCode: string;
};

function acceptableStartDateRange(countryCode: string) {
  // TODO: Account for business days
  const startOfTomorrow = new Date();
  startOfTomorrow.setDate(startOfTomorrow.getDate() + 1);
  startOfTomorrow.setHours(0, 0, 0, 0);

  // Add leadTimeDays to get minDate
  const minDate = new Date(startOfTomorrow);
  minDate.setDate(minDate.getDate() + leadTimeDays(countryCode));

  // Add 1 year to get maxDate
  const maxDate = new Date(startOfTomorrow);
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  return { start: minDate, end: maxDate };
}

export default function StartDateField({ countryCode }: Props) {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } = useFormikContext<Schema>();

  function datePickerHandlePick(date: Date) {
    const dateString = date.toISOString().split("T")[0];
    setFieldValue(FIELD_NAME, dateString);
  }

  const startDateRange = acceptableStartDateRange(countryCode);

  return (
    <FormField
      required
      name={FIELD_NAME}
      label={t("Start date")}
      error={errors.startDate}
    >
      <SingleDatePicker
        placeholder={t("Select date")}
        id={FIELD_NAME}
        name={FIELD_NAME}
        value={values.startDate}
        onChange={datePickerHandlePick}
        minDate={startDateRange.start}
        maxDate={startDateRange.end}
      />
    </FormField>
  );
}
