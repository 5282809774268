export const OVERVIEW_CONTENT = {
  header: {
    title: "What is short term disability insurance?",
    headerInfo:
      "Short term disability insurance covers a portion of an employee’s income during an extended period of a disabling illness or accident. This can help to ease financial strain when an employee can’t work because of an accident, sickness, or childbirth.",
    headerSubInfo:
      "To continue coverage after the 26 week maximum of short term disability, check out the long term disability offerings.",
  },
  body: {
    simpleSetUp: {
      id: 0,
      title: "Simple set up",
      info: "You can opt to offer this benefit in your Benefits dashboard, and we will automatically extend it to your employees.",
    },
    automatedBilling: {
      id: 1,
      title: "Automated billing",
      info: "Depending on the option you choose, your company or enrolled employees will be billed automatically for coverage. New eligible employees will be added to the policy when they’re hired or  become eligible.",
    },
    easyFileClaims: {
      id: 2,
      title: "Easily file claims",
      info: "Employees work directly with Justworks to file any claims, and they receive benefit payments directly from MetLife.",
    },
  },
};

export const OPTIONS_AND_COSTS_CONTENT = {
  header: {
    title: "What can I offer my employees?",
    headerInfo:
      "You can decide between two types of STD policies: Employer-paid (noncontributory STD) and employee-paid (voluntary STD). Employees can only have one type of policy. If you choose to offer noncontributory STD insurance, we will not extend the voluntary STD insurance offering to your employees.",
  },
  bodyTitle: "What does it cost?",
  body: {
    noncontributory_std_content: {
      id: 0,
      subtitle: "Noncontributory short term disability (employer paid)",
      info: "There are two options for employer paid policies. If you offer one of these benefits, it will be automatically extended to all employees working 30 or more hours per week.",
    },
    voluntary_std_content: {
      id: 1,
      subtitle: "Voluntary short term disability (employee paid)",
      info: "Employees can opt into this coverage once, at the time of hire or upon eligibility. Only employees working 30 or more hours per week are eligible to sign up for this benefit.",
    },
  },
  costTableInfo: {
    employerPaidOptionOne: {
      optionId: "option_1",
      optionLabel: "Option 1: Noncontributory (employer paid)",
      coverageAmount: "60% of pre-disability earnings, up to $1,000 per week",
      costs:
        "$0.14 per $10 of covered benefit (max monthly cost of $14.00 per employee)",
      amount: "100000",
      planType: "std",
    },
    employerPaidOptionTwo: {
      optionId: "option_2",
      optionLabel: "Option 2: Noncontributory (employer paid)",
      coverageAmount: "60% of pre-disability earnings, up to $1,500 per week",
      costs:
        "$0.141 per $10 of covered benefit (max monthly cost of $21.25 per employee)",
      amount: "150000",
      planType: "std",
    },
    employeePaidOption: {
      optionId: "option_3",
      optionLabel: "Option 3: Voluntary (employee paid)",
      coverageAmount: "60% of pre-disability earnings, up to $1,000 per week ",
      costs: "$0.22 per $10 of covered benefit (max monthly cost of $22.00)",
      amount: null,
      planType: "vol_std",
    },
  },
  noticeContent: {
    title: "Keep in mind",
    info: "Before enrolling in Short Term Disability, it is essential to recognize that employees in certain states have statutory disability leave programs that may offer a richer benefit than our short term disability offering. ",
    learnMore: "Read our full disclaimer",
  },
};

export const FAQ_CONTENT = {
  employeeUse: {
    id: 0,
    title: "What can my employees use short term disability for?",
    info: "Employees may use short term disability for planned leave like childbirth or surgery that required recovery time or unplanned leave like a disabling accident or illness.",
  },
  eligibleEmployees: {
    id: 1,
    title: "What kind of employees are eligible for these benefits?",
    info: "Full-time employees, part-time employees who work 30+ hours each week, and paid owners are all eligible. Unpaid owners are only eligible for non-contributory STD.",
  },
  waitingPeriod: {
    id: 2,
    title: "Is there a waiting period before an employee can take leave?",
    info: "No. Eligible employees can use the benefit as soon as they have coverage.",
  },
  employeeClaims: {
    id: 3,
    title: "How do my employees file claims?",
    info: "Employees can file claims here at Justworks, right from their benefits center.",
  },
  eliminationPeriod: {
    id: 4,
    title: "What is an elimination period?",
    info: "The elimination period is how long a policyholder must wait after they are initially unable to work before they can receive benefits. The elimination for short term disability claims is 7 calendar days.",
  },
  stdAndStateBenefits: {
    id: 5,
    title:
      "Can my employee file short-term disability and statutory state benefits?",
    info: "Yes. Generally, when an employee decides to utilize both statutory leave and short term disability, the state program pays first, and then short term disability will pay the difference up to the employee’s short term disability maximum weekly benefit.",
  },
};
