import { Button } from "@justworkshr/milo-core";
import { FormField, SingleDatePicker } from "@justworkshr/milo-form";
import { FieldArray, getIn, useFormikContext } from "formik";
import styles from "./DateArrayField.module.css";
import moment from "moment";

const { dateRow, addMoreButton } = styles;
const today = new Date();

type Props = {
  name: string;
  label: string;
  message?: string;
  maxDate?: Date;
  addButtonLabel?: string;
  limit?: number;
  required?: boolean;
};

const DateArrayField = ({
  name,
  label,
  message = "",
  maxDate = today,
  addButtonLabel = "Add more",
  limit = 10,
  required = false,
}: Props) => {
  const getIndexedName = (name: string, index: number) => `${name}.${index}`;

  const { values, setFieldValue } = useFormikContext();
  const valueArray: string[] = getIn(values, name);
  const dateValueArray = valueArray?.length ? valueArray : [""]; // 1 empty field by defult

  // Disable Add more
  const isMoreThanLimit = dateValueArray.length >= limit;
  const isAllDatesFilled = dateValueArray.every((value) => value.length > 0);
  const isAddMoreDisabled = !isAllDatesFilled || isMoreThanLimit;

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => {
        const handleChange = (date: Date, index: number) => {
          const indexedName = getIndexedName(name, index);
          setFieldValue(
            indexedName,
            date ? moment(date).format("YYYY-MM-DD") : ""
          );
        };

        const handleRemove = () => {
          arrayHelpers.pop();
        };

        const handleAddMore = () => {
          arrayHelpers.push("");
        };

        const showRemoveButton = (index: number) => {
          // Show only for the last date, when more than 1 date is present
          return (
            dateValueArray.length > 1 && index === dateValueArray.length - 1
          );
        };

        return (
          <FormField
            label={label}
            message={message}
            required={required}
            name={name}
          >
            <>
              {dateValueArray.map((value, index) => {
                const indexedName = getIndexedName(name, index);
                return (
                  <div key={indexedName} className={dateRow}>
                    <SingleDatePicker
                      key={indexedName}
                      name={indexedName}
                      onChange={(date) => handleChange(date, index)}
                      maxDate={maxDate}
                      value={value}
                      data-testid={name}
                    />
                    {showRemoveButton(index) && (
                      <Button
                        variant="ghost"
                        type="button"
                        onClick={handleRemove}
                        leftIcon="trash"
                        children={null}
                        data-testid="datearray-remove-button"
                      />
                    )}
                  </div>
                );
              })}
              <div className={addMoreButton}>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={handleAddMore}
                  disabled={isAddMoreDisabled}
                >
                  {addButtonLabel}
                </Button>
              </div>
            </>
          </FormField>
        );
      }}
    />
  );
};

export default DateArrayField;
