import { FormField } from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IdentifierInput } from "../../IdentifierInput/IdentifierInput";
import styles from "../../ProfileInfo.module.css";
import { taxIdFullName, taxIdShortName, validateTaxId } from "../../taxIdUtils";

const { inputField } = styles;

type TIdentifierNumberFormFragmentProps = {
  workCountry: string;
  required?: boolean;
};

export const initialValues = {
  identifierNumber: "",
  confirmIdentifierNumber: "",
};

export const schema = (t: TFunction, workCountry: string) =>
  Yup.object({
    identifierNumber: Yup.string()
      .required(
        t("This field is required. Please enter your {{taxIdShortName}}.", {
          taxIdShortName: t(taxIdShortName(workCountry)),
        })
      )
      .test(
        "valid",
        t("Invalid {{taxIdShortName}}. Please try again.", {
          taxIdShortName: t(taxIdShortName(workCountry)),
        }),
        (id) => {
          if (id?.length) {
            return validateTaxId(workCountry, id);
          }
          return true;
        }
      )
      // TODO We eventually need to be able to do this at the integration level @ ProfileInfoES
      .when("nationality", {
        is: (value: string) => value !== "ES" && workCountry === "ES",
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema,
      }),
    confirmIdentifierNumber: Yup.string()
      .required(
        t("This field is required. Please enter your {{taxIdShortName}}.", {
          taxIdShortName: t(taxIdShortName(workCountry)),
        })
      )
      .test(
        "match",
        t("{{taxIdShortName}} does not match. Please try again.", {
          taxIdShortName: t(taxIdShortName(workCountry)),
        }),
        (id, context) => {
          return id === context.parent.identifierNumber;
        }
      )
      // TODO We eventually need to be able to do this at the integration level @ ProfileInfoES
      .when("nationality", {
        is: (value: string) => value !== "ES" && workCountry === "ES",
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema,
      }),
  });

export type TIdentifierNumberFormFragmentSchema = Yup.InferType<
  ReturnType<typeof schema>
>;

export default function IdentifierNumberFormFragment({
  workCountry,
  required = true,
}: TIdentifierNumberFormFragmentProps) {
  const { t } = useTranslation();
  const { touched, errors, handleBlur, handleChange, values } =
    useFormikContext<TIdentifierNumberFormFragmentSchema>();
  return (
    <>
      <div className={inputField}>
        <FormField
          error={
            touched?.identifierNumber && errors?.identifierNumber
              ? (errors?.identifierNumber as string)
              : ""
          }
          label={t(taxIdFullName(workCountry))}
          required={required}
        >
          <IdentifierInput
            countryCode={workCountry}
            hidden
            name="identifierNumber"
            value={values.identifierNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormField>
      </div>
      <div className={inputField}>
        <FormField
          error={
            touched?.confirmIdentifierNumber && errors?.confirmIdentifierNumber
              ? (errors?.confirmIdentifierNumber as string)
              : ""
          }
          label={t("Confirm {{taxIdShortName}}", {
            taxIdShortName: t(taxIdShortName(workCountry)),
          })}
          required={required}
        >
          <IdentifierInput
            countryCode={workCountry}
            name="confirmIdentifierNumber"
            value={values.confirmIdentifierNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormField>
      </div>
    </>
  );
}
