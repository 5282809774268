import { useRef } from "react";

export function useFormError() {
  const formErrorRef = useRef<HTMLDivElement>(null);

  function scrollToError() {
    formErrorRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  }

  return { formErrorRef, scrollToError };
}
