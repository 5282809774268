import { Alert } from "@justworkshr/milo-core";
import {
  DecoratedMessageWithLinkDisplayType,
  DecoratedMessageWithLinkType,
} from "./types";
import MessageWithLink from "./MessageWithLink";

export const DecoratedMessageWithLink = ({
  text,
  linkText,
  link,
  displayType,
}: DecoratedMessageWithLinkType): JSX.Element => {
  switch (displayType) {
    case DecoratedMessageWithLinkDisplayType.INFOALERT:
      return (
        <Alert color="info">
          <MessageWithLink text={text} linkText={linkText} link={link} />
        </Alert>
      );
    default:
      return <MessageWithLink text={text} linkText={linkText} link={link} />;
  }
};
