import { Button, Card } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Link } from "react-router-dom";
import { permissionGroupDetails } from "pages/advisor/common/constants";
import { ThirdPartyTable } from "pages/advisor/common/components/ThirdPartyTable";
import { useTranslation } from "react-i18next";
import { InvitingFirmUser } from "types/firms/InvitingFirmUser";
import styles from "./FirmInvitationTable.module.css";

const { columns, rowSM, stackMD, stackSM, stackLG, activeFirmCard, tableRow } =
  styles;

interface FirmInvitationTableProps {
  firmUuid: string;
  firmName: string;
  invitingFirmUser: InvitingFirmUser;
  permissions: string[];
}

function FirmInvitationTable({
  firmUuid,
  firmName,
  invitingFirmUser,
  permissions,
}: FirmInvitationTableProps) {
  const { t } = useTranslation();

  function iconName(id: string) {
    return permissions.includes(id) ? "check-circle" : "error";
  }

  function iconColor(id: string) {
    return permissions.includes(id) ? "success" : "disabled";
  }

  return (
    <ThirdPartyTable
      tableKey={firmUuid}
      tableTitle={firmName}
      dataCountLabel={""}
      afterTable={
        <div className={stackMD}>
          <Card
            className={activeFirmCard}
            title={t("Firm permissions")}
            actions={[
              <Button
                variant="ghost"
                size="sm"
                as={Link}
                to={`/firm-invitation/${firmUuid}/edit-permissions`}
              >
                {t("Edit")}
              </Button>,
            ]}
          >
            <div className={columns}>
              {[
                ["settings", "payments"],
                ["company_hr", "benefits"],
              ].map((column) => (
                <div className={stackLG} key={String(column)}>
                  {column.map((key) => (
                    <div className={stackSM} key={key}>
                      <b>{permissionGroupDetails[key].name}</b>
                      <div>
                        {Object.values(
                          permissionGroupDetails[key].permissions
                        ).map((permission) => (
                          <div className={rowSM} key={permission.id}>
                            <SystemIcon
                              iconName={iconName(permission.id)}
                              color={iconColor(permission.id)}
                              size="small"
                            />
                            {permission.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Card>
        </div>
      }
    >
      <>
        <thead>
          <tr className={tableRow}>
            <td>{t("Name")}</td>
            <td>{t("Title")}</td>
            <td>{t("Phone")}</td>
            <td>{t("Email")}</td>
          </tr>
        </thead>
        <tbody>
          <tr className={tableRow} key={invitingFirmUser.firmUserId}>
            <td
              data-heap-redact-text={true}
            >{`${invitingFirmUser.firstName} ${invitingFirmUser.lastName}`}</td>
            <td>{invitingFirmUser.role}</td>
            <td>{invitingFirmUser.phone}</td>
            <td>{invitingFirmUser.email}</td>
          </tr>
        </tbody>
      </>
    </ThirdPartyTable>
  );
}

export default FirmInvitationTable;
