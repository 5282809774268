import { Button, Card } from "@justworkshr/milo-core";
import {
  capitalize,
  getTotalGrossAmount,
  getTotalNumberOfPayees,
  getTypeDisplayValue,
  getExchangeRate,
  getActiveCurrency,
} from "pages/employer-of-record/payments/utils";
import { ReactElement, useContext } from "react";
import styles from "../../Review.module.css";
import { useFormikContext } from "formik";
import { AdditionalPayFormData, FormPayloadType } from "../../../Setup/types";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { useStepperContext } from "pages/employer-of-record/payments/contexts/stepperFormHandler";
import { buildClockworkWebPath } from "lib/resource-finder";
import { CLOCKWORK_WEB_PATH } from "pages/employer-of-record/payments/constants";
import { toCurrencyFormat } from "pages/employer-of-record/utils";
import { EstimatedRateRow } from "../../../EstimatedRateRow/EstimatedRateRow";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";

const SummaryCard = (): ReactElement => {
  const { values, isSubmitting } =
    useFormikContext<AdditionalPayFormData<FormPayloadType>>();

  const { formData } = useStepperContext();
  const additionalPayContext = useContext(AdditionalPayContext);
  const { supportedCountriesData } = useContext(SupportedCountriesContext);

  const countryInfo =
    supportedCountriesData[additionalPayContext?.selectedCountry || ""];
  const currencyData = getActiveCurrency(countryInfo.currencies);
  const currency = currencyData?.currencyCode || "";
  const type = getTypeDisplayValue(formData.setup.type);

  const totalGrossAmount = toCurrencyFormat(
    Number(getTotalGrossAmount(values.paymentDetails)),
    currency || "",
    true,
    1,
    currencyData?.symbol
  );

  const exchangeRate = getExchangeRate(
    currency,
    additionalPayContext?.exchangeRates
  );

  return (
    <Card title="Summary">
      <div className={styles.summaryWrapper}>
        <div className={styles.summaryRow}>
          <div className={styles.rowLabel}>Payees</div>
          <div className={styles.rowValue}>
            {getTotalNumberOfPayees(formData.paymentDetails)}
          </div>
        </div>
        <div className={styles.summaryRow}>
          <div className={styles.rowLabel}>Pay type</div>
          <div className={styles.rowValue}>{capitalize(type)}</div>
        </div>
        {exchangeRate && <EstimatedRateRow exchangeRate={exchangeRate} />}
        <div className={styles.summaryRow}>
          <div className={styles.rowLabel}>Gross amount</div>
          <div className={`${styles.rowValue} ${styles.grossAmount}`}>
            {totalGrossAmount}
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button color="brand" type="submit" loading={isSubmitting}>
          Submit Payment
        </Button>
        <Button
          color="brand"
          variant="outlined"
          type="button"
          onClick={() => {
            additionalPayContext?.setCurrentStep("payment-details");
          }}
        >
          Go back
        </Button>

        <Button
          variant="ghost"
          type="button"
          onClick={() => {
            window.location.assign(
              buildClockworkWebPath(CLOCKWORK_WEB_PATH.PAYMENTCENTER)
            );
          }}
        >
          Cancel
        </Button>
      </div>
    </Card>
  );
};

export default SummaryCard;
