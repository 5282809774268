export const LTD_COST_CALCULATION_EXAMPLE = {
  // TODO: these will be dynamic values in v2
  salary: `$120,000`,
  EmployerOptionOne: {
    optionFormatted: "Option 1: Employer paid",
    monthlyCost: `$${((100000 / 12 / 100) * 0.12).toFixed(2)}`,
    monthlyPay: `$5,000`,
    weeklyPay: `$${Math.round(5000 / 4)}`,
  },
  EmployerOptionTwo: {
    optionFormatted: "Option 2: Employer paid",
    monthlyCost: `$${((120000 / 12 / 100) * 0.168).toFixed(2)}`,
    monthlyPay: `$5,538`,
    weeklyPay: `$${Math.round(5538 / 4)}`,
  },
  EmployeeOption: {
    optionFormatted: "Option 3: Employee paid",
    monthlyCost: `$${((100000 / 12 / 100) * 0.141).toFixed(2)}`,
    monthlyPay: `$5,000`,
    weeklyPay: `$${Math.round(5000 / 4)}`,
  },
};

export const STD_COST_CALCULATION_EXAMPLE = {
  // TODO: these will be dynamic values in v2
  salary: `$120,000`,
  EmployerOptionOne: {
    optionFormatted: "Option 1: Employer paid",
    monthlyCost: `$${((1000 / 10) * 0.14).toFixed(2)}`,
    monthlyPay: `$4,000`,
    weeklyPay: `$${Math.round(4000 / 4)}`,
  },
  EmployerOptionTwo: {
    optionFormatted: "Option 2: Employer paid",
    monthlyCost: `$${((((120000 / 52) * 0.6) / 10) * 0.141).toFixed(2)}`,
    monthlyPay: `$5,538`,
    weeklyPay: `$${Math.round(5538 / 4)}`,
  },
  EmployeeOption: {
    optionFormatted: "Option 3: Employee paid",
    monthlyCost: `$${((120000 / 12 / 100) * 0.22).toFixed(2)}`,
    monthlyPay: `$4,000`,
    weeklyPay: `$${Math.round(4000 / 4)}`,
  },
};

export const BASIC_AND_ADD_CALCULATION_PLAN_DETAILS = {
  basicLifeEffectiveDate:
    "Set an effective date for basic life and AD&D insurance",
  basicLifeEffectiveDateEligiblity:
    "All eligible employees will have coverage starting on the effective date.",
  supplementaryLifeOfferQuestion:
    "Do you want to offer supplemental life insurance?",
  supplementaryLifeOfferInfo:
    "Supplemental life insurance is a common addition to basic life Insurance and AD&D. It does not cost you anything extra to offer it to your employees and gives them the option to enroll themselves.",
  supplementaryLifeEffectiveDate:
    "Set an effective date for supplemental life insurance",
  supplementaryLifeEffectiveDateEligiblity:
    "All eligible employees will have coverage starting on the effective date.",
  voluntaryEffectivenessNote:
    "Policies will be in effect the 1st of the month that you select. If you choose the next calendar month as an effective date, your employees will be eligible to enroll the same day. If you choose a calendar month after that, your employees will be able to enroll starting on the first of the calendar month before the one selected. Your employees will have a 30 day enrollment window.",
  lifeInsuranceDeclinedInfo: {
    title:
      "Looks like you don't want to offer any of the life insurance policies.",
    info: "If you change your mind, just come back to your benefits center at any point. Life is full of unexpected challenges. When our employees' loved ones and families need it the most, our affordable plans can provide financial support.",
  },
};

export const PLAN_EFFECTIVENESS_DETAILS = {
  effectiveDateQuestion: "When do you want this policy to go into effect?",
  efectivenessEmployeeEligibility:
    "All eligible employees will have coverage starting on the effective date.",
  voluntaryEffectivenessNote:
    "Policies will be in effect the 1st of the month that you select. If you choose the next calendar month as an effective date, your employees will be eligible to enroll the same day. If you choose a calendar month after that, your employees will be able to enroll starting on the first of the calendar month before the one selected. Your employees will have a 30 day enrollment window.",
};
