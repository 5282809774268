import { ChangeEvent, ComponentProps, ReactElement, useState } from "react";
import { Alert, Button, PageHeader } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import IntegrationCard from "./components/IntegrationCard/integration-card";
import CardSection from "./components/CardSection/card-section";
import { REQUEST_INTEGRATION_URL } from "./constants";
import styles from "./integration-marketplace.module.css";
import { useResetScrollOnRouteChange } from "../hooks/useResetScrollOnRouteChange";
import CenteredLoader from "pages/pmt/common/CenteredLoader/CenteredLoader";
import CardCategorySelect from "./components/CardCategorySelect/card-category-select";
import useIntegrationMarketplaceData from "./hooks/useIntegrationMarketplaceData";
import useIntegrationMarketplaceAlert from "./hooks/useIntegrationMarketplaceAlert";
import useIntegrationMarketplaceCards from "./hooks/useIntegrationMarketplaceCards";
import useIntegrationMarketplaceCardSections from "./hooks/useIntegrationMarketplaceCardGroups";

const {
  integrationMarketplace,
  headerAndButton,
  buttonContent,
  cardSectionWrapper,
  requestButton,
  header,
} = styles;

export type CardData = ComponentProps<typeof IntegrationCard>;

export default function IntegrationMarketplace(): ReactElement {
  useResetScrollOnRouteChange();
  const [categories, setCategories] = useState<string[]>([]);

  const useIntegrationMarketplaceDataResult = useIntegrationMarketplaceData();
  const { combinedLoading, hasIntegrationsData, authenticatedMember } =
    useIntegrationMarketplaceDataResult;
  const { alertProps } = useIntegrationMarketplaceAlert(
    useIntegrationMarketplaceDataResult
  );
  const cards = useIntegrationMarketplaceCards(
    useIntegrationMarketplaceDataResult
  );
  const cardSections = useIntegrationMarketplaceCardSections(cards, categories);
  const handleCategoryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCategories([event.target.value]);
  };
  return (
    <div id={"IntegrationMarketplaceApp"}>
      <Alert {...alertProps} />

      <div className={`${integrationMarketplace} milo--grid`}>
        <div className={headerAndButton}>
          <PageHeader className={header} title="Integrations" border={false}>
            Connect your favorite tools to Justworks to make running and growing
            your business even easier.
          </PageHeader>
          {hasIntegrationsData && (
            <Button
              className={requestButton}
              variant="outlined"
              as="a"
              href={REQUEST_INTEGRATION_URL}
              target="_blank"
            >
              <div className={buttonContent}>
                <SystemIcon iconName="external-link" color="brand" />
                Request an Integration
              </div>
            </Button>
          )}
        </div>

        <CardCategorySelect cards={cards} onChange={handleCategoryChange} />
      </div>
      <CenteredLoader loading={combinedLoading} />
      {hasIntegrationsData && (
        <div>
          <div className={`milo--grid ${cardSectionWrapper}`}>
            {cardSections.map((section) => (
              <CardSection
                {...section}
                key={section.header}
                userPermissions={authenticatedMember?.permissions}
                companyAdmins={authenticatedMember?.company.admins}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
