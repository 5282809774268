import { ReactElement, useContext, useEffect } from "react";
import { Button, Card } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import styles from "./PaymentDetails.module.css";
import { AdditionalPayContext } from "../../contexts/additionalPayContext";
import {
  Table,
  TableBody,
  TableHeader,
} from "pages/employer-of-record/components";
import { PaymentDetailsRow } from "./components/PaymentDetailsRow/PaymentDetailsRow";
import { useStepperContext } from "../../contexts/stepperFormHandler";
import type { AdditionalPayFormData, FormPayloadType } from "../Setup/types";
import { useFormikContext } from "formik";
import {
  getActiveCurrency,
  getTotalNumberOfPayees,
  getTypeDisplayValue,
  hasWorkPeriod,
} from "../../utils";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  BenefitsAndAllowancesType,
  CLOCKWORK_WEB_PATH,
  EmployeePayType,
} from "../../constants";
import { getTotalGrossAmount, getExchangeRate } from "../../utils";
import { toCurrencyFormat } from "pages/employer-of-record/utils";
import type { EorMembersType } from "../../types";
import { EstimatedRateRow } from "../EstimatedRateRow/EstimatedRateRow";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";

export const PaymentDetails = (): ReactElement => {
  const additionalPayContext = useContext(AdditionalPayContext);
  const { values, validateForm, setTouched, setErrors, handleSubmit } =
    useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { formData, dispatch } = useStepperContext();

  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const countryInfo = supportedCountriesData[formData.setup?.workCountry || ""];
  const currencyData = getActiveCurrency(countryInfo.currencies);

  const type = getTypeDisplayValue(formData.setup.type);
  const includeWorkPeriod = hasWorkPeriod(formData.setup);

  const workPeriodHeaders = includeWorkPeriod
    ? formData.setup.type === EmployeePayType.OFF_CYCLE
      ? [
          {
            key: "WORKPERIOD",
            label: "Work period",
            isSortable: false,
          },
        ]
      : [
          {
            key: "STARTDATE",
            label: "Work period start",
            isSortable: false,
          },
          {
            key: "ENDDATE",
            label: "Work period end",
            isSortable: false,
          },
        ]
    : [];

  const tableHeaders = [
    {
      key: "NAME",
      label: "Name",
      isSortable: false,
    },
    {
      key: "GROSSAMOUNT",
      label: "Gross amount",
      isSortable: false,
    },
    ...workPeriodHeaders,
  ];

  const membersData = additionalPayContext?.membersData;

  useEffect(() => {
    setTouched({});
  }, [membersData, setTouched]);

  const totalGrossAmount = toCurrencyFormat(
    Number(getTotalGrossAmount(values.paymentDetails)),
    currencyData?.currencyCode || "",
    true,
    1,
    currencyData?.symbol
  );

  const exchangeRate = getExchangeRate(
    currencyData?.currencyCode || "",
    additionalPayContext?.exchangeRates
  );

  const payees = getTotalNumberOfPayees(values.paymentDetails);

  const submitStepData = () => {
    dispatch({
      type: "payment-details",
      payload: values.paymentDetails,
    });
    additionalPayContext?.setCurrentStep("review");
  };

  const handleStepSubmit = () => {
    handleSubmit();
    validateForm().then((errors) => {
      if (Object.keys(errors.paymentDetails || []).length === 0) {
        submitStepData();
        if (additionalPayContext?.errorMessage) {
          additionalPayContext?.setErrorMessage("");
        }
        setTouched({});
        setErrors({});
      } else {
        let error = "Please fix the errors below, and try again.";
        if (typeof errors.paymentDetails === "string") {
          error = errors.paymentDetails;
        }
        additionalPayContext?.setErrorMessage(error);
        window.scrollTo(0, 0);
      }
    });
  };

  const paymentDetailsDescription = () => {
    const isTaxableBenefit =
      formData.setup.type === BenefitsAndAllowancesType.BENEFITSANDALLOWANCES;

    return !isTaxableBenefit
      ? "Enter the amount earned as well as the date range during which this payment was earned."
      : "";
  };

  return (
    <>
      {values.paymentDetails.length > 0 && (
        <div
          className={`${styles.cardWrapper} ${
            includeWorkPeriod ? styles.bigTable : ""
          }`}
        >
          <div className={`${styles.card}  ${styles.noHeaderBorder}`}>
            <Card
              title={`${type} pay`}
              description={paymentDetailsDescription()}
            >
              <Table>
                <TableHeader headers={tableHeaders} />
                <TableBody>
                  {membersData?.map((member: EorMembersType, index: number) => (
                    <PaymentDetailsRow
                      countryInfo={countryInfo}
                      member={member}
                      key={member.id}
                      index={index}
                      hasDatePeriod={includeWorkPeriod}
                      exchangeRate={exchangeRate}
                    />
                  ))}
                </TableBody>
              </Table>
            </Card>
          </div>
          <div className={styles.card}>
            <Card title="Summary">
              <div
                className={`${!includeWorkPeriod ? styles.summaryWrapper : ""}`}
              >
                <div className={styles.summaryRow}>
                  <div className={styles.rowLabel}>Payees</div>
                  <div className={styles.rowValue}>{payees}</div>
                </div>
                <div className={styles.summaryRow}>
                  <div className={styles.rowLabel}>Pay type</div>
                  <div className={styles.rowValue}>{type}</div>
                </div>
                {exchangeRate && (
                  <EstimatedRateRow exchangeRate={exchangeRate} />
                )}
                <div className={styles.summaryRow}>
                  <div className={styles.rowLabel}>Gross amount</div>
                  <div className={`${styles.rowValue} ${styles.grossAmount}`}>
                    {totalGrossAmount}
                  </div>
                </div>
              </div>
              {!includeWorkPeriod && (
                <div className={styles.buttonWrapper}>
                  <Button
                    variant="filled"
                    color="brand"
                    type="submit"
                    onClick={handleStepSubmit}
                  >
                    Continue
                  </Button>
                  <Button
                    color="brand"
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      additionalPayContext?.setCurrentStep("set-up");
                    }}
                  >
                    Go back
                  </Button>

                  <Button
                    variant="ghost"
                    type="button"
                    onClick={() => {
                      window.location.assign(
                        buildClockworkWebPath(CLOCKWORK_WEB_PATH.PAYMENTCENTER)
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Card>
          </div>
        </div>
      )}

      {includeWorkPeriod && (
        <ActionFooter
          className={styles.actionFooter}
          actions={[
            <Button
              variant="ghost"
              role="link"
              onClick={() =>
                window.location.assign(
                  buildClockworkWebPath(CLOCKWORK_WEB_PATH.PAYMENTCENTER)
                )
              }
              key="cancel-payment"
            >
              Cancel
            </Button>,
            <Button
              variant="filled"
              color="brand"
              onClick={handleStepSubmit}
              key="continue-payment"
            >
              Continue
            </Button>,
          ]}
          secondary={[
            <Button
              variant="outlined"
              color="brand"
              key="back-payment"
              onClick={() => {
                additionalPayContext?.setCurrentStep("set-up");
              }}
            >
              Go Back
            </Button>,
          ]}
        />
      )}
    </>
  );
};
