import Drawer from "app-frame/header/common/drawer";
import styles from "./alerts-drawer.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Alert } from "@justworkshr/milo-core";
import { useAlertContext } from "../context/alert-context-provider";
import AlertInbox from "./alerts-inbox/alerts-inbox";
import NestedDrawerHeader from "app-frame/header/common/nested-drawer-header";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";
import { useGetMemberAlertsQuery } from "types/generated/operations";
import {
  ViewType,
  useViewContext,
} from "app-frame/header/context/view-context-provider";

const {
  tabletAndDesktopDrawerHeader,
  mobileDrawerHeader,
  iconLabelContainer,
  alertsClose,
  alertsIcon,
} = styles;

function AlertsDrawer() {
  const { view } = useViewContext();
  const { state, dispatch } = usePanelsControlContext();
  const { alertsDrawerShown } = state;
  const { alertMessage, alertColor, alertShown, toggleAlert } =
    useAlertContext();
  const headerTitle = "Alerts";

  const { data, loading } = useGetMemberAlertsQuery();
  if (loading || !data) {
    return <></>;
  }
  const notificationsCount = data.currentMember.notificationsCount;
  const notifications = data.currentMember.notificationList;
  const drawerHeaderForTabletAndDesktop = (
    <div className={tabletAndDesktopDrawerHeader}>
      <div className={iconLabelContainer}>
        <span className={alertsIcon} />
        <h1>{headerTitle}</h1>
      </div>
      <button
        onClick={() =>
          dispatch({
            type: PanelsControlActionType.CLOSE_ALERTS_DRAWER_FROM_TABLET_OR_DESKTOP,
          })
        }
      >
        <span className={alertsClose} />
      </button>
    </div>
  );

  const drawerHeaderForMobile = (
    <div className={mobileDrawerHeader}>
      <NestedDrawerHeader
        icon={<SystemIcon iconName="bell" size="large" color="brand-subtle" />}
        onClick={() =>
          dispatch({
            type: PanelsControlActionType.CLOSE_ALERTS_DRAWER_FROM_MOBILE,
          })
        }
      >
        {headerTitle}
      </NestedDrawerHeader>
    </div>
  );

  const drawerContent = (
    <div data-testid="alerts-inbox">
      <Alert
        color={alertColor}
        visible={alertShown}
        dismissible={true}
        onDismiss={() => toggleAlert(false)}
      >
        {alertMessage}
      </Alert>
      <AlertInbox
        notificationsCount={notificationsCount}
        notifications={notifications}
      />
    </div>
  );

  return (
    <Drawer
      name="alerts-drawer"
      header={
        view === ViewType.MOBILE
          ? drawerHeaderForMobile
          : drawerHeaderForTabletAndDesktop
      }
      onClose={() => {
        dispatch({
          type: PanelsControlActionType.CLOSE_ALERTS_DRAWER_FROM_TABLET_OR_DESKTOP,
        });
      }}
      open={alertsDrawerShown}
      zIndex={10000000}
      showOverlay={view !== ViewType.MOBILE}
    >
      {drawerContent}
    </Drawer>
  );
}

export default AlertsDrawer;
