import { fetchClient } from "@justworkshr/fe-utility-kit";
import { useMutation } from "@tanstack/react-query";
import { buildClockworkWebPath } from "lib/resource-finder";

export interface GeneratePlaidTokenResponse {
  link_session_token: string;
  expiration?: string;
}

export type Mode = "update" | "link";

export const useGeneratePlaidToken = (mode: Mode) => {
  const { mutateAsync, isPending } = useMutation<
    GeneratePlaidTokenResponse,
    Error
  >({
    mutationFn: async () => {
      const url = `/company/bank_account/generate_${mode}_token`;
      const response = await fetchClient.post(buildClockworkWebPath(url));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    onError: () => {
      throw new Error(`Error fetching ${mode} token`);
    },
  });

  return { mutateAsync, isPending };
};
