import { Route, Routes } from "react-router-dom";
import { EditPermissionsPage, FirmInvitationPage } from "./pages";
import { Page404 } from "../../page-404";
import { PermissionsProvider } from "./contexts/PermissionsProvider";

const FirmInvitationSubRoutes = () => {
  return (
    <PermissionsProvider>
      <Routes>
        <Route index element={<FirmInvitationPage />} />
        <Route path="/edit-permissions" element={<EditPermissionsPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </PermissionsProvider>
  );
};

const FirmInvitationApp = () => {
  return (
    <Routes>
      <Route path="/:firmInviteId/*" element={<FirmInvitationSubRoutes />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default FirmInvitationApp;
