import { ReactElement, useContext, useState } from "react";
import { useFormikContext } from "formik";
import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import {
  ADDITIONAL_PAY_FORM_ROUTES,
  CLOCKWORK_WEB_PATH,
} from "../../constants";
import { useStepperContext } from "../../contexts/stepperFormHandler";
import { AdditionalPayContext } from "../../contexts/additionalPayContext";
import { AdditionalPayFormData, FormPayloadType } from "./types";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useParams } from "react-router-dom";
import { EmployeePay } from "./components/EmployeePay/EmployeePay";
import { Reimbursement } from "./components/Reimbursement/Reimbursement";
import Acknowledgement from "./components/Acknowledgment/Acknowledgment";
import { BenefitsAndAllowances } from "./components/TaxableBenefitsAndAllowances/BenefitsAndAllowances";

const Content = ({
  formType,
  handleDisclaimersChecked,
}: {
  formType: string | undefined;
  handleDisclaimersChecked: (value: boolean) => void;
}) => {
  switch (formType) {
    case ADDITIONAL_PAY_FORM_ROUTES.EMPLOYEE_PAY:
      return <EmployeePay />;
    case ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT:
      return (
        <>
          <Reimbursement />
          <Acknowledgement
            formType={formType}
            disclaimersChecked={handleDisclaimersChecked}
          />
        </>
      );
    case ADDITIONAL_PAY_FORM_ROUTES.TAXABLEBENEFITSANDALLOWANCES:
      return <BenefitsAndAllowances />;
    default:
      return null;
  }
};

export const Setup = (): ReactElement => {
  const { dispatch } = useStepperContext();
  const additionalPayContext = useContext(AdditionalPayContext);
  const { formType } = useParams();

  const { values, validateForm, setTouched, setErrors, handleSubmit } =
    useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const setUpData = values.setUp;

  const submitStepData = () => {
    dispatch({
      type: "set-up",
      payload: setUpData,
    });
    additionalPayContext?.setCurrentStep("payment-details");
  };

  const handleStepSubmit = () => {
    handleSubmit();
    validateForm().then((errors) => {
      if (
        Object.keys(errors.setUp || []).length === 0 &&
        !isDisclaimersChecked
      ) {
        submitStepData();
        if (additionalPayContext?.errorMessage) {
          additionalPayContext?.setErrorMessage("");
        }
        setTouched({});
        setErrors({});
      } else {
        additionalPayContext?.setErrorMessage(
          "Please fix the errors below, and try again."
        );
        window.scrollTo(0, 0);
      }
    });
  };

  const [isDisclaimersChecked, setIsDisclaimersChecked] = useState(false);

  const handleDisclaimersChecked = (value: boolean) => {
    setIsDisclaimersChecked(value);
  };

  return (
    <>
      <Content {...{ formType, handleDisclaimersChecked }} />

      <ActionFooter
        actions={[
          <Button
            variant="ghost"
            role="link"
            onClick={() => {
              window.location.assign(
                buildClockworkWebPath(CLOCKWORK_WEB_PATH.PAYMENTCENTER)
              );
            }}
            key="setup-cancel"
          >
            Cancel
          </Button>,
          <Button
            onClick={handleStepSubmit}
            variant="filled"
            type="submit"
            color="brand"
            key="setup-continue"
          >
            Continue
          </Button>,
        ]}
        secondary={[
          <Button variant="outlined" color="brand" disabled key="setup-back">
            Go Back
          </Button>,
        ]}
      />
    </>
  );
};
