import CollapsibleCard from "./CollapsibleCard";
import { FormikProps } from "formik";
import { EditableProfileInfoFormType } from "../types";
import { FormField, SingleDatePicker, TextInput } from "@justworkshr/milo-form";
import { useTranslation } from "react-i18next";
import { Divider } from "@justworkshr/milo-core";
import NamesFragment from "./NamesFragment";
import moment from "moment";
import styles from "./EditIdentificationInformation.module.css";

const MINIMUM_WORKING_AGE = 18;
const MINIMUM_WORKING_AGE_CUTOFF = moment()
  .subtract(MINIMUM_WORKING_AGE, "years")
  .toDate();
const TOO_OLD_CUTOFF = moment("01/01/1921", "MM/DD/YYYY").toDate();

type Props = {
  open: boolean;
  toggleOpen: () => void;
} & FormikProps<EditableProfileInfoFormType>;

export default function EditIdentificationInformation(props: Props) {
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    open,
    toggleOpen,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;

  const fieldErrorHandler = (name: "dateOfBirth") =>
    errors[name] && touched[name] ? errors[name] : undefined;

  const datePickerHandlePick = (dateValue: Date) => {
    dateValue
      ? setFieldValue("dateOfBirth", dateValue)
      : setFieldValue("dateOfBirth", "");
  };

  return (
    <CollapsibleCard
      open={open}
      title={t("Identification")}
      onClick={toggleOpen}
    >
      <NamesFragment />

      <div className={styles.dateOfBirth}>
        <FormField
          required
          name="dateOfBirth"
          label={t("Date of birth")}
          error={fieldErrorHandler("dateOfBirth")}
        >
          <SingleDatePicker
            placeholder={t("Select date")}
            id="dateOfBirth"
            name="dateOfBirth"
            value={
              values.dateOfBirth
                ? moment(values.dateOfBirth).format("MM/DD/YYYY")
                : ""
            }
            onChange={datePickerHandlePick}
            minDate={TOO_OLD_CUTOFF}
            maxDate={MINIMUM_WORKING_AGE_CUTOFF}
          />
        </FormField>
      </div>

      <Divider />

      <FormField
        label={t("Preferred name")}
        message={t(
          "This is the name you'll see in your company directory and in emails from us."
        )}
      >
        <TextInput
          name="profileInfo.preferredFirstName"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.profileInfo.preferredFirstName}
        />
      </FormField>

      <FormField
        label={t("Pronouns")}
        message={t(
          "This will be displayed on your public profile page and in your company org chart."
        )}
      >
        <TextInput
          name="profileInfo.pronouns"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={t("e.g. He/him, She/her, They/them")}
          value={values.profileInfo.pronouns}
        />
      </FormField>
    </CollapsibleCard>
  );
}
