import { EnvironmentFinder } from "@justworkshr/fe-utility-kit";
import shouldInferEnvFromDomain from "./should-infer-env-from-domain";

export type Environment =
  | "production"
  | "sandbox"
  | "development"
  | "staging"
  | "test";

const environmentMap: Partial<Record<Environment, string[]>> = {
  production: [
    "secure.justworks.com",
    "read-only.justworks.com",
    "cstools.justworks.com",
  ],
  sandbox: ["sandbox.justworks.com", "sandbox-read-only.justworks.com"],
  staging: [
    "staging.justworks.com",
    "staging-read-only.justworks.com",
    "external-staging.justworks.com",
    "staging-next.justworks.com",
    "pst-earth.justworks.com",
    "pst-fire.justworks.com",
    "pst-water.justworks.com",
    "ptt1.justworks.com",
  ],
};

let hasInitialized = false;

const environmentFinder = new EnvironmentFinder<Environment>();

export function initializeEnvironment() {
  if (!hasInitialized) {
    environmentFinder.initializeEnvironmentFinder(environmentMap, {
      defaultEnvironmentName: "production",
      nodeEnv: import.meta.env.MODE as Environment,
      inferEnvFromDomain: shouldInferEnvFromDomain(),
    });
    hasInitialized = true;
  } else {
    throw new Error("Environment has already been initialized");
  }
}

export function getEnvironmentName(): Environment {
  return environmentFinder.getEnvironmentName();
}
