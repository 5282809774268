import { ReactElement } from "react";
import { FormField, FileInput as MiloFileInput } from "@justworkshr/milo-form";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import styles from "../../../../SetupFormGenerator.module.css";
import { GeneratedComponentPropWithSet } from "../../../../types";
import { FormPayloadType } from "../../../../../../types";

export function FileInput<T extends FormPayloadType = FormPayloadType>({
  field,
  accessor,
  touchedField,
  errorField,
  setFieldValue,
}: GeneratedComponentPropWithSet<T>): ReactElement {
  return (
    <FormField
      label={field.label}
      required={field.required}
      error={errorField && touchedField ? errorField : ""}
    >
      <>
        {field.message && (
          <div className={styles.description}>
            <MessageWithLink {...field.message} />
          </div>
        )}
        <MiloFileInput
          multiple
          className={`${
            errorField && touchedField ? styles.fileInputError : ""
          }`}
          aria-required
          data-testid="fileinput"
          accept=".pdf, .jpeg, .png"
          onFileChange={(file) => {
            setFieldValue(`setUp.${accessor}`, file || "");
          }}
        />
      </>
    </FormField>
  );
}
