import { buildClockworkWebPath } from "lib/resource-finder";

export const redirectToLogin = () => {
  const loginUrl = buildClockworkWebPath("/login");

  if (window.location.href.includes(loginUrl)) {
    return;
  }

  window.location.assign(
    `${loginUrl}?redirect=${encodeURIComponent(window.location.href)}`
  );
};
